.hero {
  position: relative;
  padding-bottom: rem(60px);

  &__slide {
    position: relative;
    background-color: $color-bg-circle;
    aspect-ratio: 1/1;
    min-height: rem(360px);

    @include mq($from: sm) {
      aspect-ratio: 1536/960;
    }

    @include mq($from: xl) {
      min-height: initial;
      height: 545px;
      aspect-ratio: 2000/545;
    }

    // @include mq($and: "(min-width:2000px)") {
    //   height: 545px;
    // }

    // min-height: rem(320px);
    // max-height: rem(720px);
    // @include mq($from: md) { height: rem(540px); }
    // @include mq($from: md) { height: rem(540px); }

    & picture {
      position: relative;
      z-index: 10;
    }

    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: opacity 0.8s ease;
      opacity: 0;

      @supports (object-fit: cover) {
        position: static;
        object-fit: cover;
        height: 100%;
        object-position: center;

        @include mq($from: sm) {
          object-fit: contain;
        }

        @include mq($from: xl) {
          object-fit: cover;
        }

        @include mq($and: "(min-width:2000px)") {
          object-fit: contain;
        }
      }

      &.js-is-image-loaded {
        opacity: 1;
      }
    }

    & .progress-bar {
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
  }

  &__bullets {
    bottom: -10px;
  }

  &__bullet {
    width: 20px;
    height: 5px;
    border-radius: 0;
    background-color: $color-border-light;
    box-shadow: none;

    &:hover,
    &:focus {
      border: none;
      background-color: $color-border-light;
    }

    &:hover {
      opacity: 0.5;
    }

    &[class*="--active"] {
      background-color: $color-main;
    }
  }

  &__typo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10% 5% 5%;
    pointer-events: none;

    @include mq($until: lg) {
      font-size: rem(24px);
    }

    @include mq($from: lg) {
      right: 0;
      max-width: 83.5%;
      margin: 0 auto;
      padding-top: rem(72px);
      padding-bottom: rem(30px);
    }

    &__line {
      position: relative;
      display: inline-block;
      overflow: hidden;
      margin-top: -0.1em;
      padding: 0.125em 0.5em;
      letter-spacing: 0.2em;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        transition: transform 1s $easeInOutCirc;
        transform: scaleX(0);
        transform-origin: center right;
        background-color: $color-base;

        .js-is-label-active & {
          transform: scaleX(1);
          transform-origin: center left;
        }
      }

      & > span {
        position: relative;
        display: inline-block;
        transform: translateX(-10px);
        opacity: 0;

        .js-glide-active & {
          transition: 1s ease;
        }

        .js-glide-active.js-is-label-active & {
          transition-delay: 0.25s;
          transform: translateX(0);
          transform-origin: center left;
          opacity: 1;
        }
      }

      // line delay
      @for $var from 2 to 10 {
        &:nth-of-type(#{$var}) {
          &::before {
            transition-delay: #{$var / 10}s;
          }

          & > span {
            .js-is-label-active & {
              transition-delay: #{0.4 + ($var / 10)}s;
            }
          }
        }
      }
    }
  }
}
