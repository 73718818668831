.badge {
  font-size: rem(11px);
  font-weight: $font-weight-bold;
  line-height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  color: $color-base;
  background-image: svg-load(
    "../images/_inline/badge.svg",
    fill=$color-text-warning
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @include mq($from: lg) {
    font-size: rem(14px);
    width: 60px;
    height: 60px;
  }
}

.label-chip {
  font-size: rem(12px);
  background-color: $color-main;
  color: $color-base;
  padding: 0.05em 0.5em;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.25em;
  margin-left: 0.25em;
}
