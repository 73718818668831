/**
 * Footer
 */

.footer {
  @include mq($from: lg) {
    background-color: $color-bg-circle;
  }

  .is-sticky-enable & {
    @include mq($until: lg) {
      padding-bottom: 80px;
    }
  }

  & a {
    color: $color-text;

    &:hover {
      text-decoration: none;
      opacity: $hover-opacity;
    }
  }

  &__gnav {
    display: block;

    @include mq($from: lg) {
      display: none;
    }

    & > a {
      font-family: $font-heading;
      font-size: rem(14px);
      font-weight: $font-weight-bold;
      display: block;
      padding: $spacer $spacer * 1.5;
      border-bottom: 1px solid $color-base;
      background-color: $color-bg-circle;
    }
  }

  &__container {
    max-width: 1440px;
    margin: 0 auto;
    padding: $spacer $spacer * 1.5 $spacer * 0.5;

    &__top,
    &__bottom {
      @include set-row;

      @include mq($from: lg) {
        justify-content: space-between;
      }

      & a {
        position: relative;

        &::before {
          margin-right: 0.5em;
          content: "";

          @include triangle(right, $color-main, 4px);
        }
      }
    }

    &__bottom {
      padding-top: 1em;

      & > small {
        font-size: rem(10px);
        display: block;
        width: 100%;
        margin-bottom: 0.5em;
        color: lighten($color-text, 20%);

        @include mq($from: lg) {
          width: auto;
        }
      }
    }
  }

  &__en-link {
    display: block;
    width: 100%;

    @include mq($from: lg) {
      width: auto;
    }

    & > a {
      font-size: rem(12px);
      font-weight: $font-weight-bold;
      display: block;
      padding: 0.25em 1em;
      text-align: center;
      background-color: rgba($color-text, 0.2);
    }
  }

  &__nav {
    display: block;
    margin-bottom: 1em;

    @include mq($from: lg) {
      margin-bottom: 0;
    }

    & > a {
      font-size: rem(12px);
      display: inline-block;
      padding: 0.5em 1em;
      margin-bottom: 0.25em;

      @include mq($from: lg) {
        padding: 0.25em 0.5em;
      }

      & > strong {
        color: $color-main;
      }
    }
  }
}
