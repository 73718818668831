.address-list {
  font-size: rem(14px);
  justify-content: space-between;
  margin-bottom: $spacer;

  @include set-row;

  dt,
  dd {
    display: block;
    width: 100%;
  }

  dt {
    @include mq($from: lg) {
      @include set-col(6rem);
    }
  }

  dd {
    @include mq($from: lg) {
      @include set-col(calc(100% - 6.5rem));
    }
  }
}

.address-attention {
  color: $color-border;
}

.contact-info {
  font-size: rem(14px);

  &__heading {
    font-size: rem(14px);
  }

  &__list {
    padding-left: 0;
    list-style: none;

    & > li {
      margin-bottom: 0.25em;
      padding-left: 1.5em;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: rem(16px);

      &.is-phone {
        background-image: svg-load("../images/_inline/phone.svg");
      }

      &.is-smartphone {
        background-image: svg-load("../images/_inline/smartphone.svg");
      }

      &.is-mail {
        background-image: svg-load("../images/_inline/mail.svg");
      }
    }
  }
}
