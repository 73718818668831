.media {
  $this: &;

  justify-content: center;
  margin-bottom: $spacer * 2;

  @include set-row;

  @include mq($from: lg) {
    justify-content: space-between;
  }

  &.is-sm {
    margin-bottom: 1em;
  }

  &__thumbnail {
    @include mq($from: lg) {
      @include set-col(rem(165px));
    }

    #{$this}.is-sm & {
      @include set-col(rem(80px));
    }

    &__pict {
      display: block;
      margin: auto;

      @include thumbnail-obj-fit(100%);

      &.is-16by9 {
        @include thumbnail-obj-fit(percentage(9/16));
      }

      &.is-4by3 {
        @include thumbnail-obj-fit(percentage(3/4));
      }
    }
  }

  &__content {
    width: 100%;
    font-size: rem(14px);

    @include mq($from: lg) {
      @include set-col(calc(calc(100% - 1.5rem) - #{rem(165px)}));
    }

    #{$this}.is-sm & {
      padding-left: 1em;

      @include set-col(calc(calc(100% - 1rem) - #{rem(80px)}));
    }
  }
}
