.content-club-wrapper {
  padding-top: 0;
  padding-bottom: 80px;
}

.content-club {
  @include set-row;

  position: relative;
  justify-content: center;
  width: 100%;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 2;
  border-bottom: 1px solid rgba($color-border, 0.2);

  @include mq($from: lg) {
    justify-content: space-between;
  }

  &__thumbnail {
    width: 100%;
    max-width: 200px;
    padding: 0 $spacer;
    text-align: center;

    @include mq($from: lg) {
      @include set-col(rem(240px));

      padding-left: 0;
    }

    & .button {
      font-size: rem(14px);
      margin-bottom: $spacer;
    }
  }

  &__body {
    width: 100%;

    @include mq($from: lg) {
      @include set-col(calc(100% - #{rem(240px)}));

      padding-top: $spacer-lg;
    }

    &__row {
      font-size: rem(14px);

      @include mq($from: lg) {
        @include set-row;

        justify-content: space-between;
        padding-left: $spacer-lg;
      }
    }

    &__heading {
      order: 1;
      width: 100%;

      @include mq($from: lg) {
        width: auto;
      }
    }

    &__excerpt {
      width: 100%;
      // @include truncate($line-clamp: 8);

      @include mq($from: lg) {
        order: 3;
        padding-bottom: $spacer;
      }
    }

    &__link {
      position: relative;
      display: block;
      margin-bottom: 1em;
      padding: 0.5em;
      text-align: right;

      @include mq($from: lg) {
        order: 2;
        margin-bottom: 0.25em;
      }

      &::before {
        @include triangle($direction: right, $color: $color-main, $size: 5px);

        display: inline-block;
        margin-right: 0.25em;
      }
    }
  }
}
