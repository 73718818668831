/**
 * External
 */

// Bootstrap Reboot
// https://getbootstrap.com/docs/4.0/content/reboot/
@import "~bootstrap/scss/bootstrap-reboot";

// glide
// https://github.com/glidejs/glide
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~@glidejs/glide/src/assets/sass/glide.theme";
