.progress-bar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 50px;
  height: 5px;
  animation: progress 2s linear infinite;
  vertical-align: middle;
  border: 1px solid $color-border;
  border-radius: 5px;
  background-image: linear-gradient(70deg, $color-border 3px, transparent 0),
    linear-gradient(250deg, $color-border 3px, transparent 0);
  background-repeat: repeat-x;
  background-size: 10px 100%;
  opacity: 0.4;
}

@keyframes progress {
  0% {
    background-position: 0, 0;
  }

  100% {
    background-position: 100%, 100%;
  }
}
