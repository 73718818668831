.price-box {
  $this: &;

  @include set-row;

  align-items: center;
  padding-left: 0;
  list-style: none;

  @include mq($from: lg) {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }

  &.is-col-3 {
    & #{$this}__item {
      @include mq($from: lg) {
        @include set-col(calc(#{percentage(1/3)} - 1rem));
      }
    }
  }

  &__item {
    @include set-row;

    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto $spacer;
    padding: $spacer 0.25em;
    border: 2px dashed $color-main;

    &__label {
      font-size: rem(14px);
      display: inline-block;
      margin-right: 1em;
      padding: 0.125em 1em;
      color: $color-base;
      background-color: $color-main;
    }

    &__price {
      font-size: rem(28px);
      font-weight: bold;

      & small {
        font-size: rem(14px);
        margin-left: 0.5em;
      }
    }
  }
}

.price-cardRow {
  margin-bottom: $spacer * 1.5;

  @include mq($from: lg) {
    @include set-row;
  }

  > .price-card {
    @include mq($from: lg) {
      @include set-col(calc(#{percentage(1/4)} - 0.3333em));
    }

    &.is-corporation {
      margin-top: $spacer * 2;

      @include mq($from: lg) {
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
}

.price-card {
  display: flex;
  flex-direction: column;
  border: 3px solid $color-main;
  border-radius: 4px;

  &__heading {
    font-size: rem(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(88px);
    color: $color-base;
    background-color: $color-main;

    &__text {
      width: 100%;
      text-align: center;
    }
  }

  &__body {
    padding: $spacer;
    padding-top: $spacer / 2;
  }

  &__price {
    font-weight: $font-weight-bold;
    text-align: center;

    &__num {
      font-size: rem(28px);
      color: $color-main;
    }

    & small {
      font-weight: $font-weight-bold;
    }
  }

  &__list {
    > li {
      font-size: rem(14px);
    }
  }

  &__footer {
    margin-top: auto;
    padding-bottom: $spacer * 1.5;
  }

  &__button {
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    line-height: 1.6;
    min-width: 80%;
    max-width: calc(100% - 1rem);
    height: 4em;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;

    @include mq($from: lg) {
      min-width: auto !important;
    }

    @supports (object-fit: cover) {
      height: auto;
      min-height: 4em;
    }
  }
}
