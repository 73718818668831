.post-header {
  &__info {
    @include set-row;

    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;

    &__category,
    &__tag {
      @include set-row;

      & > a {
        font-size: rem(12px);
        font-weight: bold;
        display: inline-block;

        @include mq($from: lg) {
          font-size: rem(14px);
        }
      }
    }

    &__category {
      & > a {
        border-bottom: 2px solid $color-main;
      }
    }

    &__tag {
      & > a {
        padding: 0.05em 0.5em;
        color: $color-base;
        background-color: $color-main;
      }
    }

    &__datetime {
      font-family: $font-heading;
      font-size: rem(14px);
      font-weight: bold;

      @include mq($from: lg) {
        font-size: rem(16px);
      }
    }
  }

  &__thumbnail {
    @include thumbnail-obj-fit(percentage(9/16));

    margin-top: $spacer;
  }
}

.post-pager {
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 4rem;
  }

  & > a {
    top: $spacer-lg;
    font-size: rem(12px);
    font-weight: bold;
    position: absolute;
    display: block;
    align-items: center;
    justify-content: center;
    padding: 1em;
    transition: 0.4s ease;
    text-align: center;

    @include mq($from: lg) {
      font-size: rem(14px);
    }

    &:hover {
      opacity: 0.5;
    }
  }

  & .link_before,
  & .link_next,
  &__prev,
  &__next {
    @include set-col(calc(34% - 5px));

    background-color: $color-bg-circle;
  }

  & .link_before,
  &__prev {
    padding-left: 3em;
    left: 0;
    margin-left: 0;
    margin-right: auto;

    &::before {
      @include icon-arrow($color: $color-border, $direction: left);

      left: 1em;
      content: "";
    }
  }

  & .link_next,
  &__next {
    right: 0;
    margin-left: auto;
    margin-right: 0;

    &::before {
      @include icon-arrow($color: $color-border, $direction: right);

      right: 1em;
      content: "";
    }
  }

  &__index {
    @include set-col(30%);

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
    padding-left: 0;
    color: $color-base;
    background-color: $color-main;

    &:hover {
      color: $color-base;
    }

    &::after {
      display: inline-block;
      width: rem(10px);
      height: rem(10px);
      content: "";
      background: no-repeat center;
      background-image: svg-load(
        "../images/_inline/squares.svg",
        fill=$color-base
      );
      background-size: contain;

      @include mq($from: md) {
        margin-left: 0.25em;
      }
    }

    & span {
      display: none;

      @include mq($from: md) {
        display: inline-block;
      }
    }
  }
}
