/**
 * classes コンテンツカードコンポーネント
 */

// カード用グリッドラッパー
.content-classes-wrapper {
  @include set-row;

  justify-content: center;
  margin-right: -$spacer / 2;
  margin-left: -$spacer / 2;

  @include mq($from: md) {
    justify-content: space-between;
  }
}

/**
 * カード （小:トップページなど）
 */
.content-classes {
  @include card($transition: 0.4s ease);

  font-size: rem(14px);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  margin: 0 $spacer / 2 $spacer * 2;

  @include mq($from: sm) {
    @include set-col(calc(#{percentage(1/2)} - #{$spacer}));
  }

  @include mq($from: lg) {
    @include set-col(calc(#{percentage(1/3)} - #{$spacer}));
  }

  @include mq($from: xl) {
    @include set-col(320px);
  }

  &__thumbnail {
    @include thumbnail-obj-fit($percentage: percentage(160/320));
  }

  &__body {
    padding: 0 $spacer $spacer / 2;
  }

  &__time {
    @include set-row;

    font-size: rem(24px);
    font-weight: $font-weight-bold;
    align-items: center;
    margin-bottom: 0.5em;

    &__dow {
      @include dow;

      top: auto;
    }

    &__schedule {
      font-size: rem(14px);
      margin-left: auto;
    }
  }

  &__heading {
    font-size: rem(14px);
    font-weight: normal;
  }

  &__status {
    font-weight: $font-weight-bold;
    display: block;
    margin-top: auto;
    padding: 0.5em;
    text-align: center;
    background-color: $color-bg-circle;

    // 受付中
    &.is-status-active {
      color: $color-base;
      background-color: $color-main;
    }

    // 満席
    &.is-status-fully {
      color: lighten($color-text, 50%);
    }

    // 残りわずか
    &.is-status-few-left {
      color: $color-text-warning;
    }
    // レポートup
    &.is-status-report {
      color: lighten($color-text, 50%);
    }

    // 終了しました
    &.is-status-end {
      color: $color-border;
    }
  }
}

/**
 * カード （ワイド: classes一覧ページなど）
 */
.content-classes-wide {
  position: relative;
  width: 100%;
  margin-bottom: $spacer * 2;

  &__link {
    display: block;
    color: $color-text;

    &:hover {
      text-decoration: none;
      color: $color-text;

      & figure {
        opacity: $hover-opacity;
      }
    }
  }

  &__date {
    @include set-row;

    font-weight: $font-weight-bold;
    position: relative;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;

    &::after {
      @include mq($from: md) {
        display: block;
        flex: 1;
        height: 1px;
        margin-left: 1em;
        content: "";
        background-color: darken($color-bg-circle, 12.5%);
      }
    }

    &__time {
      font-size: rem(24px);
      margin-right: 0.25rem;
      margin-bottom: 0;
    }

    &__dow {
      @include dow;

      position: relative;
      margin-top: 5px;
      margin-right: 0.5rem;
    }
  }

  &__body {
    @include set-row;

    width: 100%;
  }

  &__thumbnail {
    position: relative;
    width: 100%;
    padding-left: 25px;

    @include mq($from: md) {
      @include set-col(percentage(1/2));
    }

    @include mq($from: lg) {
      @include set-col(percentage(1/3));
    }

    &__label {
      font-family: $font-hiragino-pro-w6;
      font-size: rem(13px);
      font-weight: $font-weight-bold;
      line-height: 26px;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      display: flex;
      overflow: hidden;
      justify-content: center;
      width: 26px;
      min-height: 140px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      -ms-writing-mode: tb-rl;
      background-color: $color-bg-circle;
      writing-mode: vertical-rl;

      // 受付中
      .is-status-active & {
        color: $color-base;
        background-color: $color-main;
      }

      // 満席
      .is-status-fully & {
        color: lighten($color-text, 50%);
      }

      // 残りわずか
      .is-status-few-left & {
        color: $color-text-warning;
      }

      // レポートUP
      .is-status-report & {
        color: $color-text-warning;

        &::before {
          position: absolute;
          top: -5px;
          left: -5px;
          width: 10px;
          height: 10px;
          content: "";
          transform: rotate(45deg);
          background-color: $color-text-warning;
        }
      }

      // 終了
      .is-status-end & {
        color: $color-border;
      }
    }

    &__pict {
      @include thumbnail-obj-fit(percentage(173.21/288.18));

      transition: opacity 0.4s;
    }
  }

  &__content {
    font-size: rem(12px);

    @include mq($from: md) {
      @include set-col(percentage(1/2));

      padding-left: $spacer;
    }

    @include mq($from: lg) {
      @include set-col(percentage(2/3));

      padding-left: $spacer * 2;
    }

    &__tags {
      margin-bottom: 0.5em;

      & > span {
        display: inline-block;
        padding-right: 0.25em;
        padding-left: 0.25em;
        border: 1px solid $color-text;
      }
    }

    &__location {
      font-weight: bold;
      padding-left: 1.5em;
      color: $color-text-light;
      background: no-repeat center left;
      background-size: 0.89em;
      background-image: svg-load(
        "../images/_inline/place-sm.svg",
        fill= $color-text-light
      );
    }
  }
}
