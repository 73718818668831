.login-modal {
  $button-size: 40px;

  padding-bottom: $spacer;

  &__dialog {
    @include set-row;

    position: absolute;
    top: $spacer;
    right: 0;
    bottom: auto;
    left: 0;
    width: calc(100% - #{$spacer * 2});
    max-width: 570px;
    max-height: calc(100% - #{$spacer * 2});
    margin-right: auto;
    margin-left: auto;
    background-color: $color-base;
    box-shadow: $box-shadow-card-lg;

    @include mq($from: lg) {
      top: 70px;
      right: $spacer;
      left: auto;
      transform: translate(0);
    }
  }

  &__col {
    font-size: rem(14px);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $spacer * 2 $spacer * 2 $spacer;

    &:first-child {
      @include mq($from: lg) {
        @include set-col(percentage(3/7));
      }
    }

    &:nth-of-type(2) {
      background-color: $color-bg-circle;

      @include mq($from: lg) {
        @include set-col(percentage(4/7));
      }
    }

    &__bottom {
      margin-top: auto;

      & .button {
        display: block;
        width: 10em;
        margin: 0 auto;
      }
    }
  }

  &__close {
    position: absolute;
    top: -8px;
    right: -8px;
    width: $button-size;
    height: $button-size;
    border: 0;
    border-radius: 50%;
    background: $color-main-grad;

    @include mq($from: lg) {
      display: none;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 2px;
      height: $button-size / 2;
      margin-top: -$button-size / 4;
      margin-left: -1px;
      content: "";
      background-color: $color-base;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:active {
      opacity: 0.8;
    }
  }
}

.login-form {
  & dl {
    margin-bottom: 0.25em;
  }

  &__input[type] {
    display: block;
    width: 100%;
    padding: $spacer / 2;
    border: 1px solid rgba($color-text, 0.1);
    border-radius: 4px;
    background-color: $color-base;
  }

  &__submit {
    margin-top: 2px;
  }
}
