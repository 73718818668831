/**
  * スクロールアニメーションフラグ
  * ex: https://scroll-out.github.io/guide.html#perform-a-fade-in-with-css
  */

.js-so-fade-bottom {
  &[data-scroll] {
    transition: opacity 1s, transform 1s $easeOutCubic, box-shadow 0.4s;
  }

  &[data-scroll="in"] {
    opacity: 1;
    transform: translateY(0);

    // @include mq($from: lg) {
    //   // stagger
    //   @for $i from 2 through 12 {
    //     &:nth-child(#{$i}) {
    //       transition-delay: #{0.1 * $i}s;
    //     }
    //   }
    // }
  }

  &[data-scroll="out"] {
    opacity: 0;
    transform: translateY(10%);
  }
}
