.timeline {
  $year-size: 120px;
  $line-size: 4px;

  position: relative;
  padding-bottom: 50px;

  &::before,
  &::after {
    position: absolute;
    right: 0;
    left: 0;
    width: $line-size;
    margin-right: auto;
    margin-left: auto;
    content: "";

    @include mq($from: lg) {
      right: auto;
      left: $year-size / 2 - $line-size / 2;
    }
  }

  &::before {
    z-index: 0;
    display: block;
    height: 100%;
    border-top-left-radius: $line-size * 2;
    border-top-right-radius: $line-size * 2;
    background-color: $color-main;
  }

  &::after {
    z-index: 1;
    bottom: 0;
    height: $line-size * 6;
    background-image: linear-gradient(
      to bottom,
      $color-base,
      $color-base $line-size,
      transparent $line-size,
      transparent $line-size
    );
    background-repeat: repeat-y;
    background-position: left top;
    background-size: $line-size $line-size * 2;
  }

  &__group {
    @include set-row;

    position: relative;
    z-index: 2;
    justify-content: center;

    @include mq($from: lg) {
      justify-content: flex-start;
    }
  }

  &__year {
    @include set-col($year-size);

    width: 100%;

    &__label {
      @include set-row;

      font-size: rem(22px);
      font-weight: bold;
      position: relative;
      align-items: center;
      justify-content: center;
      width: $year-size;
      height: $year-size;
      letter-spacing: 0.05em;
      color: $color-main;
      border: $line-size solid $color-main;
      border-radius: 50%;
      background: $color-base;

      @include mq($from: lg) {
        position: sticky;
        top: 90px;
      }

      & span {
        display: inline-block;

        @supports (-webkit-text-fill-color: transparent) {
          background: $color-main-grad;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      & small {
        font-size: rem(14px);
        font-weight: bold;
      }
    }
  }

  &__container {
    width: 100%;

    @include mq($from: lg) {
      width: calc(100% - #{$year-size});
      padding-top: $year-size / 2;
      padding-left: calc(4rem + 3%);
    }
  }
}
