/**
 * Buttons
 */

%button {
  font-weight: 500;
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 10em;
  padding: 0.25em 1.5em;
  transition: 0.4s ease;
  text-align: center;
  border-radius: rem($button-round-radius * 0.8);

  @include mq($from: lg) {
    min-width: auto;
  }

  @include mq($from: xl) {
    padding-right: 2em;
    padding-left: 2em;
  }

  & > span {
    pointer-events: none;
  }
}

.button {
  @extend %button;

  overflow: hidden;
  box-sizing: border-box;
  color: $color-base;
  border: 0 solid transparent;
  border-radius: $button-round-radius;
  background: $color-main-grad;

  &:focus {
    color: $color-base;
  }

  &::before {
    @include full-fit;

    content: "";
    transition: transform 0.6s $easeOutQuad, opacity 0.6s ease;
    transform: scaleY(0);
    transform-origin: center top;
    opacity: 1;
    background-color: rgba($color-base, 0.25);
    will-change: transform, opacity;
  }

  &:hover {
    text-decoration: none;
    color: $color-base;

    &::before {
      transform: scaleY(1);
      transform-origin: center bottom;
      opacity: 0;
    }
  }

  &.is-warning {
    background: $color-text-warning;

    &::after {
      position: relative;
      display: inline-block;
      width: 0.86em;
      height: 0.86em;
      margin-left: 0.5em;
      content: "";
      background-image: svg-load(
        "../images/_inline/external.svg",
        fill=$color-base
      );
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &.is-lg {
    // font-size: 110%;
    padding: 0.8em 1.75em;
    max-width: 100%;

    @include mq($from: lg) {
      min-width: 20em;
      padding: 1em 2em;
    }
  }

  &.is-adaptive {
    min-width: 50%;

    @include mq($from: lg) {
      padding-top: 1.2em;
      padding-bottom: 1.2em;
      min-width: rem(520px);
    }
  }

  &.is-adaptive-sm {
    min-width: 40%;

    @include mq($from: lg) {
      min-width: rem(380px);
    }
  }

  &.is-inline {
    min-width: auto;
  }

  &.is-long {
    min-width: 50%;
  }

  &.is-sm {
    font-size: 80%;
  }

  &.is-block {
    display: block;
  }

  &.is-outline {
    padding: calc(0.25em - 2px) 1.5em;
    color: $color-main;
    border: 2px solid $color-main;
    background-color: transparent;
    background-image: none;

    &:hover {
      color: $color-base;
      background-color: $color-main;
    }
  }

  &.is-no-round {
    border-radius: 0;
  }
}

.button-wrapper {
  @include set-row;

  justify-content: center;

  &.is-column {
    & .button {
      display: block;
      min-width: 60%;
      margin: 0.5em;

      @include mq($from: lg) {
        min-width: 30%;
      }
    }
  }

  &.is-column-1-3 {
    & .button {
      display: block;
      min-width: 60%;
      margin: 0.5em;

      @include mq($from: lg) {
        @include set-col(calc(#{percentage(1/3)} - 1rem));

        min-width: auto;
      }
    }
  }
}

.link {
  display: inline-block;
  padding: 0.25em 0.5em;
}

.link-arrow {
  position: relative;
  display: inline-block;
  padding: 0.25em 1em 0.25em 2em;
  color: $color-text;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    position: absolute;
    top: 0.85em;
    left: 0.75em;
    display: block;
    width: 0.5em;
    height: 0.5em;
    content: "";
    transform: rotate(45deg);
    border-top: 2px solid $color-main;
    border-right: 2px solid $color-main;
  }

  &.is-block {
    display: block;
  }

  &.text-color-main {
    font-weight: bold;
    color: $color-main;
  }
}
