.breadcrumbs {
  font-size: rem(10px);
  display: block;
  padding: $spacer 0;
  border-top: 1px solid $color-bg-circle;

  @include mq($from: md) {
    font-size: rem(12px);
  }

  @include mq($from: xl) {
    font-size: rem(12px);
    padding-right: $spacer-lg;
    padding-left: $spacer-lg;
  }

  & a,
  & span:not(.sr-only) {
    position: relative;
    display: inline-block;
    padding: 0.25em 1em;

    &:not(:last-child)::after {
      @include icon-arrow($color: $color-border);

      right: -0.25em;
      content: "";
    }
  }

  &__container {
    @extend .container;
  }

  &__home {
    &::before {
      position: relative;
      display: inline-block;
      content: "";
      background: no-repeat center;
      background-size: contain;
      background-image: svg-load(
        "../images/_inline/home.svg",
        fill=$color-main
      );
      width: 1em;
      height: 1em;
      line-height: 1;
      top: 0.05em;
      color: $color-main;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
