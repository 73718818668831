.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-30 {
  margin-bottom: rem(30px) !important;
}

.mb-60 {
  margin-bottom: rem(60px) !important;
}

.pl-0 {
  padding-left: 0;
}

.center-box-md {
  @include mq($from: md) {
    max-width: percentage(10/12);
    margin-right: auto;
    margin-left: auto;
  }

  @include mq($from: lg) {
    max-width: percentage(8/12);
    margin-right: auto;
    margin-left: auto;
  }
}

.center-box-lg {
  @include mq($from: lg) {
    max-width: percentage(8/12);
    margin-right: auto;
    margin-left: auto;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0;
  background-color: rgba(black, 0.05);

  &::before {
    display: block;
    content: "";
  }

  & > iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &--1by1 {
    &::before {
      padding-top: 100%;
    }
  }

  // 比率別クラス生成ループ （Bootstrap4 Mixinを拝借）
  @each $embed-responsive-aspect-ratio in $embed-responsive-aspect-ratios {
    $embed-responsive-aspect-ratio-x: nth($embed-responsive-aspect-ratio, 1);
    $embed-responsive-aspect-ratio-y: nth($embed-responsive-aspect-ratio, 2);

    &--#{$embed-responsive-aspect-ratio-x}by#{$embed-responsive-aspect-ratio-y} {
      &::before {
        padding-top: percentage(
          $embed-responsive-aspect-ratio-y / $embed-responsive-aspect-ratio-x
        );
      }
    }
  }

  @each $embed-responsive-aspect-ratio in $embed-responsive-aspect-ratios {
    $embed-responsive-aspect-ratio-x: nth($embed-responsive-aspect-ratio, 1);
    $embed-responsive-aspect-ratio-y: nth($embed-responsive-aspect-ratio, 2);

    &--lg-#{$embed-responsive-aspect-ratio-x}by#{$embed-responsive-aspect-ratio-y} {
      &::before {
        @include mq($from: lg) {
          padding-top: percentage(
            $embed-responsive-aspect-ratio-y / $embed-responsive-aspect-ratio-x
          );
        }
      }
    }
  }
}

.lozad {
  z-index: 0;
  opacity: 0;
  will-change: opacity;

  &[data-loaded="true"] {
    transition: opacity 0.4s;
    opacity: 1;
  }
}

.lazyloading {
  transition: opacity 1s;
  opacity: 1;
  background: darken($color-base, 10%) no-repeat center;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.d-none {
  display: none !important;
}

.d-md-none {
  @include mq($from: md) {
    display: none !important;
  }
}

.d-lg-none {
  @include mq($from: lg) {
    display: none !important;
  }
}

.d-block {
  display: block !important;
}

.d-md-block {
  display: none;

  @include mq($from: md) {
    display: block !important;
  }
}

.d-lg-block {
  display: none;

  @include mq($from: lg) {
    display: block !important;
  }
}

@for $i from 1 through 6 {
  .order-#{$i} {
    order: $i;
  }

  .order-md-#{$i} {
    @include mq($from: md) {
      order: $i;
    }
  }
}

.sr-only {
  @include sr-only;
  @include sr-only-focusable;
}

.overlay {
  @include center-method;

  width: 100%;
  height: 100%;
}

a.overlay {
  transition: 0.4s ease;
  opacity: 0;
  background-color: $color-base;

  &:hover {
    opacity: 0.2;
  }
}

.d-block-md {
  display: none;

  @include mq($from: md) {
    display: block;
  }
}

.d-block-lg {
  display: none;

  @include mq($from: lg) {
    display: block;
  }
}

.hidden-md {
  display: block;

  @include mq($from: md) {
    display: none;
  }
}

.hidden-lg {
  display: block;

  @include mq($from: lg) {
    display: none;
  }
}

.narrow-block {
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}

.thumbnail {
  @include thumbnail-obj-fit(100%);

  display: block;
  margin: 0 auto 1em;

  &.is-16by9 {
    @include thumbnail-obj-fit(percentage(9/16));
  }

  &.is-4by3 {
    @include thumbnail-obj-fit(percentage(3/4));
  }
}

.round-thumbnail-wrapper {
  width: 100%;
  margin: 0 auto 1em;

  &.is-md {
    max-width: rem(245px);
  }

  &.is-sm {
    max-width: rem(145px);
  }

  & figcaption {
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;

    & > small,
    & > span {
      font-weight: bold;
      display: block;
    }
  }
}

.round-thumbnail {
  @include thumbnail-obj-fit(100%);

  z-index: 1;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  background-color: lighten($color-text, 25%);
  background-size: cover;
  box-shadow: 0 0 0.5rem rgba($color-text, 0.05) inset;

  &--r-corner {
    @include thumbnail-obj-fit(percentage(3/4));

    border-radius: $border-radius * 2;
  }
}

.hr-chip {
  position: relative;
  overflow: visible;
  margin-bottom: rem(30px);

  &::before {
    display: block;
    width: 20%;
    height: 0.35rem;
    margin: 0 auto;
    content: "";
    background-color: $color-main;

    @include mq($from: lg) {
      width: 6rem;
    }
  }
}

.well {
  display: block;
  padding: 1em;
  color: $color-text;
  border-radius: $border-radius;
  background-color: $color-bg-circle;

  &__lead {
    display: block;

    & > strong {
      display: inline-block;
      padding: 0.25em 1em;
      border-radius: 50px;
      background-color: $color-base;
    }
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 0.25em;
    margin-bottom: 0.5em;
  }
}

// .arrow-link {
//   font-size: rem(14px);
//   display: block;
//   padding: 0.25em 0.5em;
//   text-decoration: none;
//   color: $color-text;

//   @include mq($from: lg) {
//     margin-top: auto;
//   }

//   &::before {
//     margin-right: 0.5em;
//     color: $color-main;

//     @include triangle(right, $color-main, 4px);
//   }
// }

/** parallax */
.parallax {
  @supports (--css: variables) {
    transform: translateY(calc(var(--viewport-y) * 16%));
    // transform: translateY(calc(var(--scroll-percent-y) * 110%));
  }
}

/** Lazy Load bg img */
[data-bg] {
  opacity: 0;

  &.is-bg-loaded {
    transition: opacity 0.8s ease;
    opacity: 1;
  }
}
