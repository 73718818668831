.organization-message {
  position: relative;
  overflow: visible;

  @include mq($from: lg) {
    padding-top: rem(60px);
  }

  &__thumbnail {
    position: relative;
    right: 0;
    left: 0;
    width: 100%;
    max-width: rem(195px);
    margin: 0 auto;
    padding-bottom: $spacer;

    @include mq($from: lg) {
      position: absolute;
      top: 0;
      right: auto;
    }

    & > img {
      box-shadow: 20px 10px 80px rgba($color-text, 0.1),
        0 5px 15px rgba($color-text, 0.05);
    }

    &__caption {
      display: block;
      padding-top: 0.5em;
      text-align: center;

      @include mq($from: lg) {
        text-align: left;
      }

      & > * {
        font-weight: bold;
        display: block;
      }
    }
  }

  &__content {
    $content-margin: 160px;

    margin-top: -150px;
    padding: 200px $spacer $spacer;
    box-shadow: 0 0 3px rgba($color-text, 0.05), $box-shadow-card-lg;

    @include mq($from: lg) {
      width: calc(100% - #{rem($content-margin)});
      margin-top: 0;
      margin-left: rem($content-margin);
      padding: $spacer-xl 12%;
    }

    & p {
      line-height: 2;
    }
  }
}

.organization-map {
  max-width: rem(670px);
}

.organization-school-list {
  $icon-size: 112px;

  @include set-row;

  padding-left: 0;
  list-style: none;

  &__item {
    @include set-row;

    width: 100%;
    padding-bottom: $spacer;

    @include mq($from: lg) {
      @include set-col(percentage(1/2));
    }

    & a {
      display: inline-block;
      overflow: hidden;
      padding: 0.25em 0;
      white-space: nowrap;
      text-decoration: underline;
      text-overflow: ellipsis;
    }
  }

  &__icon {
    position: relative;
    width: 100%;
    margin-bottom: 0;

    @include mq($from: md) {
      @include set-col(rem($icon-size));
    }

    & img {
      border: 1px solid $color-border-light;
    }
  }

  &__content {
    width: 100%;

    @include mq($from: md) {
      @include set-col(calc(100% - #{rem($icon-size)}));

      padding-right: $spacer;
      padding-left: $spacer;
    }
  }
}

.banner-row {
  @include mq($from: md) {
    @include set-row;

    align-items: center;

    & > p {
      margin-right: 1em;
      margin-left: 1em;
    }
  }
}

.banner {
  max-width: rem(200px);
  height: auto;

  &.is-border {
    border: 1px solid $color-border-light;
  }
}

.banner-jwave {
  max-width: rem(130px);
  height: auto;
}
