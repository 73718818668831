.section {
  position: relative;
  padding-top: rem(50px);
  padding-bottom: rem(50px);

  $this: &;

  & > .container {
    position: relative;
  }

  &--sm {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
  }

  &--lg {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
  }

  &--first {
    padding-top: rem(15px);
  }

  &--top-0 {
    padding-top: 0;
  }

  &--bottom-0 {
    padding-bottom: 0;
  }

  &--bottom-gutter {
    margin-bottom: 30px;
  }

  &--bottom-gutter-lg {
    margin-bottom: 50px;
  }
}

.bg-color-main {
  background-color: $color-main;
}

.bg-color-bg-circle {
  background-color: $color-bg-circle;
}

.bg-color-bg-circle-half {
  background-color: transparent;
  background-image: linear-gradient(
    0deg,
    transparent 50%,
    $color-bg-circle 50%
  );
}

.bg-picture {
  overflow: hidden;
  color: $color-base;
  background-color: $color-text;

  &__figure {
    width: 100%;
    height: 126%;
    opacity: 0.4;

    @include thumbnail-obj-fit;
    @include center-method;
  }
}
