.circle-slider {
  & .glide__slides {
    align-items: stretch;
  }

  & .glide__slide {
    padding-bottom: 30px;
    display: flex;
    height: auto;
    flex-direction: column;
  }
}

.content-circle {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-bottom: $spacer / 2;
  text-decoration: none;
  color: $color-text;
  background-color: $color-base;

  @include card;

  &:hover {
    text-decoration: none;
    color: $color-text;
  }

  & h3 {
    font-size: rem(14px);
    margin-bottom: 0;
    padding: 0.5em rem($spacer);

    @include truncate($line-clamp: 3);
  }

  & p {
    padding: 0 rem($spacer);
    line-height: 1.2;
    flex-grow: 1;

    > small {
      color: $color-border;
    }
  }

  &__thumbnail {
    margin-bottom: 0.5em;

    @include thumbnail-obj-fit($percentage: percentage(215/316));
  }
}
