.pagination {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  list-style: none;

  & li {
    display: block;
    height: 100%;
    margin-right: 1px;
    margin-bottom: 0;
    margin-left: 1px;

    & > a,
    & > span {
      font-weight: bold;
      line-height: 1;
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      min-width: rem(40px);
      min-height: rem(40px);
      padding: 0.2em;
      border: 2px solid $color-main;
      color: $color-main;
    }

    & .dots {
      border-color: transparent;
    }

    & .prev,
    & .next {
      position: relative;
      border-color: transparent;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &.is-disable {
        opacity: 0.5;
      }
    }

    & .prev {
      &::before {
        left: calc(50% - 3px);

        @include triangle($direction: left, $color: $color-main, $size: 12px);
      }
    }

    & .next {
      &::before {
        left: calc(50% + 3px);

        @include triangle($direction: right, $color: $color-main, $size: 12px);
      }
    }

    & > a {
      transition: all 0.2s ease;

      &:hover {
        text-decoration: none;
        color: $color-text;
        border-color: $color-text;
      }

      & > i {
        position: relative;
        display: block;
        width: rem(18px);
        height: rem(18px);

        @include svgRatio(100%);
      }
    }

    &.is-rwd {
      @include mq($until: md) {
        display: none;
      }
    }

    & > span {
      &.current {
        pointer-events: none;
        border-color: $color-border;
        color: $color-border;
      }
    }
  }
}
