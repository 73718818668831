.content-report-wrapper {
  display: block;
}

.content-report {
  position: relative;
  margin-bottom: rem($spacer);
  padding: rem($spacer);
  border: 1px solid $color-border-light;

  @include set-row;
  @include card;

  @include mq($from: lg) {
    max-width: percentage(8/10);
    padding: rem($spacer * 2);

    &:nth-of-type(2) {
      margin-left: percentage(1/10);
    }

    &:nth-of-type(3) {
      margin-left: percentage(2/10);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-width: 15px 15px 0 0;
    border-style: solid;
    border-color: $color-main transparent transparent transparent;
  }

  &__left {
    @include set-col(rem(245px / 2));

    @include mq($from: lg) {
      @include set-col(rem(184px));
    }
  }

  &__thumbnail {
    margin-bottom: 0;

    @include thumbnail-obj-fit($percentage: percentage(110/184));
  }

  &__body {
    position: relative;
    padding-left: $spacer;

    @include set-col(calc(100% - #{rem(245px / 2)}));

    @include mq($from: lg) {
      @include set-col(calc(100% - #{rem(184px)}));
    }
  }

  &__heading {
    margin-bottom: 0.25em;

    @extend .h5;
  }

  &__expert {
    font-size: rem(14px);

    @include truncate($line-clamp: 2);
  }
}
