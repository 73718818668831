.lead {
  font-size: rem(20px);
}

.text-size-xs {
  font-size: rem(12px);
}

.text-size-sm {
  font-size: rem(14px);
}

.text-size-md {
  font-size: rem(16px);
}

.text-size-lg {
  font-size: rem(18px);
}

.text-size-xl {
  font-size: rem(24px);
}

.text-danger {
  color: red;
}

.text-center {
  text-align: center;
}

.text-center-md {
  @include mq($from: md) {
    text-align: center;
  }
}

.text-center-lg {
  @include mq($from: lg) {
    text-align: center;
  }
}

.text-right {
  text-align: right;
}

.text-hide {
  font-size: 0;
  color: transparent;
}

.text-block {
  & > span {
    display: block;
  }
}

.text-block-md {
  & > span {
    display: inline-block;

    @include mq($from: md) {
      display: block;
    }
  }
}

.text-block-lg {
  & > span {
    display: inline-block;

    @include mq($from: lg) {
      display: block;
    }
  }
}

.text-autoline {
  & > span {
    display: inline-block;
  }
}

.text-color-main {
  color: $color-main;
}

.line-height-large {
  line-height: 2;

  @include mq($from: lg) {
    line-height: 2.2;
  }
}

.poetry {
  @include set-row;

  align-items: center;

  &__col {
    width: 100%;

    @include mq($from: lg) {
      @include set-col(percentage(1/2));
    }

    & p:last-child {
      margin-bottom: 0;
    }
  }

  &__lead {
    font-size: rem(24px);
    letter-spacing: 0.15em;
    font-feature-settings: normal;

    @include mq($from: lg) {
      font-size: rem(40px);
      padding-right: 0.5em;
    }
  }

  &__content {
    line-height: 1.8;

    @include mq($from: md) {
      line-height: 2.5;
    }
  }
}

.blockquote-util {
  $base-icon-size: 16px;

  @include set-row;

  position: relative;
  align-items: center;
  padding-top: $spacer;
  padding-bottom: $spacer;

  &__body {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding: $spacer / 2 rem($base-icon-size * 2);

    @include mq($from: md) {
      padding-right: rem($base-icon-size * 4);
      padding-left: rem($base-icon-size * 4);
    }

    &::before,
    &::after {
      position: absolute;
      display: block;
      width: rem($base-icon-size);
      height: rem($base-icon-size);
      content: "";
      pointer-events: none;
      background: no-repeat center;
      background-image: url("../images/_inline/dobule-quort.svg");
      background-size: contain;

      @include mq($from: md) {
        width: rem($base-icon-size * 2);
        height: rem($base-icon-size * 2);
      }
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      right: 0;
      bottom: 0;
      transform: rotate(180deg);
    }

    & span {
      display: block;
      text-align: center;

      @include mq($from: md) {
        line-height: 2;
      }
    }
  }
}
