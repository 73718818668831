.co-support-intro {
  &__heading {
    margin-bottom: 1.5em;
  }

  &__section {
    position: relative;

    &.is-bottom-img {
      padding-bottom: rem(400px);

      @include mq($from: md) {
        padding-bottom: 0;
      }
    }

    & p {
      line-height: 2.2;
      width: 100%;
      margin-bottom: 1.5em;
    }

    &__pict {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      max-width: rem(252px);
      margin: auto;
      padding-top: $spacer;
      padding-bottom: $spacer;

      @include mq($from: md) {
        position: static;
        bottom: auto;
        float: right;
      }
    }
  }
}

.price-cardRow.is-supporterPrice {
  @include mq($from: lg) {
    padding-top: rem(50px);
  }

  > .price-card {
    position: relative;

    @include mq($from: lg) {
      &:not(:first-child):not(:last-child) {
        border-left: 0;
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 4px;
      }

      &:nth-child(3) {
        border-top-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        > .price-card__heading {
          position: relative;

          &::before {
            position: absolute;
            top: -3px;
            bottom: 0;
            left: -3px;
            height: calc(100% + 3px);
            content: "";
            border-left: 3px $color-base solid;
          }
        }
      }
    }

    &:first-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:nth-child(2) {
      border-radius: 0;
    }

    &:nth-child(3) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.co-support-popup-01,
.co-support-popup-02 {
  display: block;
  margin-right: auto;
  margin-left: auto;

  @include mq($from: lg) {
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);
    left: 0;
  }
}
