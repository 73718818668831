.search-box {
  @include container-breakpoint;

  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: $spacer;

  @include mq($from: md) {
    margin-top: 30px;
  }

  &__trigger {
    font-family: $font-hiragino-pro-w6;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    line-height: 1;
    display: block;
    width: 100%;
    padding: 1em 3em;
    border: 1px solid $color-border-light;
    background-color: $color-base;
    background-image: svg-load("../images/_inline/search.svg"),
      svg-load("../images/_inline/plus.svg");
    background-repeat: no-repeat;
    background-position: 1em center, calc(100% - 1em) center;
    background-size: rem(16px), rem(14px);
    box-shadow: $box-shadow-card;

    @include mq($from: md) {
      display: block;
      width: auto;
      margin-right: 0;
      margin-left: auto;
      box-shadow: none;
    }

    &:hover {
      opacity: $hover-opacity;
    }
  }
}

.search-modal {
  &__close {
    font-family: $font-hiragino-pro-w6;
    font-weight: $font-weight-bold;
    position: relative;
    width: 100%;
    padding: $spacer;
    padding-right: 2.5em;
    text-align: left;
    border: none;
    border-bottom: 1px solid $color-border-light;
    background: $color-base no-repeat;
    background-image: svg-load("../images/_inline/minus.svg");
    background-position: calc(100% - 1em) center;
    background-size: 1em;

    &:hover,
    &:active {
      opacity: $hover-opacity;
      color: $color-text-light;
    }

    &:focus {
      outline: none;
    }
  }

  &__dialog {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: calc(100% - #{$spacer * 2});
    max-width: rem(1140px);
    margin: $spacer * 2 auto;
    background-color: $color-base;
    box-shadow: $box-shadow-card;
  }

  &__body {
    @include set-row;

    padding: $spacer;
  }

  &__heading {
    @extend .h6;

    position: relative;

    &::before {
      top: 5px;
      display: inline-block;
      width: 3px;
      height: 1em;
      margin-right: 0.5em;
      content: "";
      background-color: $color-main;
    }
  }

  &__item {
    width: 100%;
    padding-bottom: $spacer;

    &--word {
      @include mq($from: lg) {
        @include set-col(percentage(2/3));

        padding-right: $spacer * 2;
      }
    }

    &--year {
      @include mq($from: lg) {
        @include set-col(percentage(1/3));
      }
    }
  }

  &__form {
    @include set-row;

    border: none;
    border-radius: 0;
    background-color: $color-bg-circle;

    &__input {
      font-size: rem(16px);
      position: relative;
      display: block;
      width: calc(100% - 50px);
      margin-bottom: 0;
      padding: 0.375rem 0.75rem;
      border: 0;
      border-radius: 0;
      background-color: transparent;
    }

    &__submit {
      width: 50px;
      height: 50px;
      border-width: 0;
      background: darken($color-bg-circle, 12.5%) no-repeat center;
      background-image: svg-load("../images/_inline/search.svg");
      background-size: rem(18px);

      &:hover {
        background-color: darken($color-bg-circle, 6.5%);
      }
    }
  }

  &__select {
    font-size: rem(14px);
    box-sizing: border-box;
    min-width: 60%;
    height: 50px;
    padding-right: 1em;
    padding-left: 1em;
    color: $color-text-light;
    border: none;
    border-radius: 0;
    background-color: $color-bg-circle;
    background-image: linear-gradient(
      45deg,
      transparent 50%,
      $color-text-light 50%
    ),
      linear-gradient(135deg, $color-text-light 50%, transparent 50%);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) 25px, calc(100% - 15px) 25px,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none;
    }
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}
