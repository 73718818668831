[data-modal] {
  @include center-method($position: fixed);

  z-index: $z-search-modal;
  visibility: hidden;
  overflow: auto;
  transition: opacity 0.4s $easeOutCirc, visibility 0.4s;
  opacity: 0;
  background-color: rgba(black, 0.2);
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;

  &:hover {
    cursor: pointer;
  }

  [data-modal-dialog] {
    transition: transform 0.4s $easeOutCirc;
    transform: translateY(10px);
  }

  &.js-is-modal-visible {
    visibility: visible;
    transition-timing-function: opacity $easeInCirc, transform $easeInCirc;
    opacity: 1;

    [data-modal-dialog] {
      transform: translateY(0);
    }
  }
}

[data-modal-dialog] {
  &:hover {
    cursor: initial;
  }
}
