.data-list {
  $dt-width: 10rem;

  @include set-row;

  dt,
  dd {
    font-size: rem(15px);
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding-top: $spacer;
  }

  dt {
    @include mq($from: lg) {
      @include set-col($dt-width);

      padding-bottom: $spacer;
      border-bottom: 1px solid $color-border-light;
    }
  }

  dd {
    padding-bottom: $spacer;
    border-bottom: 1px solid $color-border-light;

    @include mq($from: lg) {
      @include set-col(calc(100% - calc(#{$dt-width} + 1em)));
    }
  }
}

.checked-list {
  padding-left: 0;
  list-style: none;

  & > li {
    display: block;
    padding-bottom: 0.25em;
    padding-left: 2em;
    background: no-repeat left top;
    background-image: svg-load(
      "../images/_inline/inner-checked.svg",
      fill=$color-main
    );
    background-position: 0.25em 0.5em;
    background-size: 1em auto;
  }
}

.keyword-list {
  position: relative;
  padding-top: 30px;
  padding-left: 0;
  list-style: none;
  counter-reset: keyword;

  @include mq($from: lg) {
    padding-right: $spacer;
    padding-left: $spacer;
  }

  &__item {
    @include set-row;

    position: relative;
    margin-bottom: rem(60px);
    padding: $spacer;
    counter-increment: keyword;
    background-color: $color-base;

    @include mq($from: md) {
      padding-right: $spacer * 2;
      padding-bottom: $spacer * 2;
      padding-left: $spacer * 2;
    }

    @include mq($from: lg) {
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
    }

    & p:last-child {
      margin-bottom: 0;
    }
  }

  &__badge {
    position: absolute;
    top: rem(-30px);
    left: rem(-15px);
    overflow: hidden;
    width: rem(70px);
    height: rem(70px);
    padding-top: rem(20px);
    color: $color-base;
    border-radius: 50%;
    background: $color-main-grad;

    @include mq($from: md) {
      top: rem(-30px);
      left: rem(-20px);
      width: rem(80px);
      height: rem(80px);
    }

    &::before,
    &::after {
      font-family: $font-google-lato, sans-serif;
      font-weight: bold;
      line-height: 1.2;
      display: block;
      text-align: center;
    }

    &::before {
      font-size: rem(10px);
      content: "KEYWORD";
      text-transform: uppercase;
    }

    &::after {
      font-size: rem(20px);
      content: counter(keyword, decimal-leading-zero);

      @include mq($from: md) {
        font-size: rem(26px);
      }
    }
  }

  &__heading {
    @extend .h4;

    width: 100%;
    padding-top: rem(30px);
    text-align: center;
    letter-spacing: 0.25em;

    @include mq($from: lg) {
      @include set-col(percentage(1/2));

      margin-bottom: 0;
      padding-top: 0;
      padding-left: rem(60px);
      text-align: left;
    }

    font-feature-settings: normal;

    & span {
      display: inline-block;
    }
  }

  &__body {
    width: 100%;

    @include mq($from: lg) {
      @include set-col(percentage(1/2));

      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
    }
  }
}

.sns-list {
  display: block;
  min-height: 44px;
  padding-top: $spacer;
  padding-bottom: $spacer;
  text-align: center;

  &__link {
    font-size: rem(18px);
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 0.5em;
    margin-left: 0.5em;
    transition: 0.4s ease;
    text-align: center;
    color: $color-text;
    border: 1px solid $color-text;
    border-radius: 50%;

    @include mq($from: lg) {
      font-size: rem(24px);
      width: 62px;
      height: 62px;
      border-width: 2px;
    }

    &::before {
      line-height: 38px;
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-top: -0.5em;
      margin-left: -0.5em;
      content: "";
      transition: 0.4s ease;
      background-color: $color-text;
      -webkit-mask: no-repeat center / contain;
      mask: no-repeat center / contain;

      @include mq($from: lg) {
        line-height: 60px;
      }
    }

    &:hover {
      text-decoration: none;
      color: $color-bg-sns-hover;
      background-color: $color-text;

      &::before {
        background-color: $color-base;
      }
    }

    &--twitter {
      &::before {
        -webkit-mask-image: url("../images/_inline/sns-twitter.svg");
        mask-image: url("../images/_inline/sns-twitter.svg");
      }
    }

    &--x {
      &::before {
        -webkit-mask-image: url("../images/_inline/sns-x.svg");
        mask-image: url("../images/_inline/sns-x.svg");
      }
    }

    &--facebook {
      &::before {
        -webkit-mask-image: url("../images/_inline/sns-face-book.svg");
        mask-image: url("../images/_inline/sns-face-book.svg");
      }
    }

    &--instagram {
      &::before {
        -webkit-mask-image: url("../images/_inline/sns-instagram.svg");
        mask-image: url("../images/_inline/sns-instagram.svg");
      }
    }

    &--youtube {
      &::before {
        -webkit-mask-image: url("../images/_inline/sns-youtube.svg");
        mask-image: url("../images/_inline/sns-youtube.svg");
      }
    }

    &--spotify {
      &::before {
        mask-image: url("../images/_inline/sns-spotify.svg");
      }
    }
  }
}

.step-list {
  position: relative;
  padding-left: 0;
  counter-reset: section;

  &__item {
    $circle-width: 64px;
    $item: &;

    position: relative;
    display: block;
    padding-bottom: $spacer;
    counter-increment: section;

    &::after {
      @include triangle(
        $direction: bottom,
        $color: darken($color-bg-circle, 20%),
        $size: rem(5px)
      );

      position: absolute;
      right: 0;
      bottom: rem(5px);
      left: 0;
      margin: 0.5rem auto auto;

      @include mq($from: md) {
        top: calc(#{$circle-width} + 0.5rem);
        right: auto;
        left: calc(#{$circle-width / 2} - #{rem(5px)});
      }
    }

    &:last-child::after {
      display: none;
    }

    &__heading {
      @include set-row;

      align-items: center;
      justify-content: center;

      @include mq($from: md) {
        justify-content: flex-start;
      }

      &__step {
        position: relative;
        display: block;

        & > span:first-child {
          line-height: 1.2;
          position: relative;
          display: block;
          overflow: hidden;
          width: rem($circle-width);
          height: rem($circle-width);
          padding-top: 0.8rem;
          text-align: center;
          border-radius: 50%;
          background-color: $color-bg-step;

          &::before {
            font-size: rem(10px);
            display: block;
            width: 100%;
            content: "STEP";
          }

          &::after {
            width: 100%;
            content: counter(section, decimal-leading-zero);
          }
        }
      }

      &__label {
        width: 100%;
        padding-top: 0.25em;

        @include mq($from: md) {
          width: calc(100% - #{rem($circle-width)});
          padding-top: 0;
          padding-left: 1rem;
        }
      }
    }

    &__body {
      @include mq($from: md) {
        padding-left: calc(#{rem($circle-width)} + 1rem);
      }
    }
  }
}

/**
* 注意事項リスト
*/
.attentionList {
  padding-left: 0.5em;
  list-style: none;
  counter-reset: number 0;

  li {
    font-size: rem(12px);
    position: relative;
    display: block;
    margin-bottom: 0.25em;
    padding-left: 2.25em;
    content: counter(number);
    counter-increment: number;
    color: lighten($color-text, 30%);

    @include mq($from: lg) {
      font-size: rem(13px);
    }

    &::before {
      position: absolute;
      left: 0;
      content: "※" counter(number);
    }

    &[data-count] {
      &::before {
        content: "※" attr(data-count);
      }
    }
  }

  &--numberLess {
    li {
      padding-left: 1.25em;

      &::before {
        content: "※";
      }
    }
  }

  &--warning {
    li,
    li::before {
      font-weight: bold;
      color: red;
    }
  }
}

.dot-list {
  list-style: none;

  > li {
    position: relative;
    line-height: 1.86;
    margin-bottom: 0.5em;

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: $color-main;
      border-radius: 50%;
      left: -1.25em;
      top: calc(0.75em - 1px);
    }
  }
}
