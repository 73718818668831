.tag-cloud {
  position: relative;
  display: block;

  &__item {
    font-size: rem(12px);
    font-weight: bold;
    display: inline-block;
    margin-right: 0.125em;
    margin-bottom: 0.5em;
    padding: 0.25em 1em;
    transition: 0.4s ease;
    color: $color-text;
    border: 1px solid $color-text;

    @include mq($from: lg) {
      font-size: rem(14px);
    }

    &.is-current,
    &:hover {
      text-decoration: none;
      color: $color-base;
      background-color: $color-text;
    }

    &:active {
      color: $color-text;
    }
  }
}
