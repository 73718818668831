.content-news-wrapper {
  @include set-row;

  position: relative;
  justify-content: center;
  margin-right: -$spacer;
  margin-left: -$spacer;
}

.content-news {
  @include card;
  @include set-col(calc(#{320px} - #{$spacer * 2}));

  position: relative;
  margin: 0 $spacer $spacer * 2;
  padding: $spacer;
  border: 1px solid $color-border-light;

  @include mq($from: sm) {
    @include set-col(calc(#{percentage(1/2)} - #{$spacer * 2}));
  }

  @include mq($from: lg) {
    @include set-col(calc(#{percentage(1/4)} - #{$spacer * 2}));
  }

  & .badge {
    z-index: 10;
    top: -14px;
    left: -14px;
  }

  &__time {
    font-size: rem(12px);
    font-weight: $font-weight-bold;
    font-style: italic;
    color: $color-border;
  }

  &__heading {
    @include truncate;

    font-size: rem(14px);
    font-weight: normal;
  }

  &__tags {
    display: block;
    overflow: hidden;
    padding-top: 0.5em;

    > span {
      display: inline-block;
      padding: 0.125em 0.5em 0.1em;
      color: $color-base;
      background-color: $color-main;
      font-size: rem(12px);
      margin-bottom: 0.25em;
    }
  }
}

.category-link {
  display: block;
  margin-bottom: 1em;
  text-align: center;

  @include mq($from: lg) {
    text-align: right;
  }

  & a {
    font-size: rem(14px);
    display: inline-block;
    padding: 0.125em 0.75em;
    transition: 0.4s ease;
    color: $color-text;
    background-color: $color-bg-circle;

    &.is-current,
    &:hover,
    &:active {
      color: $color-base;
      background-color: $color-main;
    }

    // &.is-current {
    //   opacity: 0.5;
    //   pointer-events: none;

    //   &:hover {
    //     color: $color-text;
    //     background-color: $color-bg-circle;
    //   }
    // }
  }
}
