.card {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding: $spacer;
  background-color: $color-base;

  @include mq($from: md) {
    padding: $spacer-lg;
  }

  &.is-shadow {
    box-shadow: 0 1px 4px rgba($color-text, 0.1), $box-shadow-card;
  }

  // チケット風右上欠け表示
  &.is-ticket {
    overflow: hidden;
    border: 3px solid $color-main;

    &::before {
      @include triangle(
        $direction: right,
        $color: $color-main,
        $size: rem(16px)
      );

      position: absolute;
      top: rem(-16px);
      right: rem(-13px);
      display: block;
      content: "";
      transform: rotate(-45deg);
    }
  }

  &.is-timeline {
    overflow: visible;
    border: 1px solid darken($color-bg-circle, 10%);
    border-radius: 8px;
    padding: 1.5rem $spacer $spacer;

    @include mq($from: md) {
      padding: $spacer-lg;
    }

    @include mq($from: lg) {
      padding: $spacer-xl;
    }

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      pointer-events: none;
      background-color: $color-main;
    }

    &::before {
      top: 0.5rem;
      left: 0.5rem;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
    }

    &::after {
      top: 1rem;
      right: calc(100% - 0.75rem);
      width: 4rem;
      height: 1px;
      transform: rotate(-30deg);
      transform-origin: right center;

      @include mq($until: lg) {
        display: none;
      }
    }
  }

  &.is-box {
    padding: $spacer;
    border: 1px solid lighten($color-text-light, 30%);
    box-shadow: 0 rem(2px) rem(3px) rgba($color-text, 0.05);

    @include mq($from: lg) {
      padding: $spacer-lg;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 4px;
      margin: 0 auto auto;
      content: "";
      pointer-events: none;
      background-color: $color-main;
      background-image: $color-main-grad;
      background-size: 100%;
    }
  }

  &.is-gutter {
    @include mq($from: md) {
      padding-left: $spacer-lg;
      padding-right: $spacer-lg;
    }

    @include mq($from: lg) {
      padding: $spacer-xl;
    }
  }

  // & p:last-child {
  //   margin-bottom: 0;
  // }

  &--row {
    @include set-row;

    justify-content: center;
  }

  &__content {
    width: 100%;

    @include mq($from: md) {
      @include set-col(percentage(8/12));

      padding-right: $spacer-lg;
    }
  }

  &__thumbnail {
    width: 100%;

    @include mq($from: md) {
      @include set-col(percentage(4/12));

      margin-top: 0.5em;
    }

    &__pict {
      @include thumbnail-obj-fit(percentage(3/4));

      margin-bottom: 0;
    }
  }

  &__heading {
    margin-bottom: 0.5em;
    padding-top: 0.5em;
  }

  &--col {
    display: flex;
    flex-direction: column;

    &__bottom {
      margin-top: auto;
    }
  }
}

.card-row {
  @include set-row;

  width: 100%;
  margin-right: auto;
  margin-left: auto;

  & .card {
    @include mq($from: lg) {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  &.is-half {
    & .card {
      @include mq($from: lg) {
        @include set-col($width: calc(#{percentage(1/2)} - 1rem));
      }
    }
  }

  &.is-narrow {
    @include mq($from: lg) {
      max-width: 80%;
    }
  }
}
