.nav-anchor-tab {
  @include set-row;

  position: relative;
  width: 100%;
  padding-top: $spacer;
  padding-bottom: calc(#{$spacer} + 30px);

  &__link {
    @include set-col(percentage(1/3));
    @include set-row;

    font-weight: bold;
    position: relative;
    overflow: visible;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    padding: 1.5em 0.25em;
    text-align: center;
    color: $color-text;
    border: 4px solid $color-bg-circle;

    &:not(:last-child) {
      border-right-width: 0;
    }

    &::after {
      @include triangle(
        $direction: bottom,
        $color: $color-main,
        $size: rem(7px)
      );

      position: absolute;
      top: calc(100% + 10px);
      content: "";
      transition: transform 0.4s ease;
    }

    &:hover::after {
      transform: translateY(5px);
    }

    &__label {
      font-size: rem(11px);
      position: relative;
      display: flex;
      align-items: center;
      min-height: rem(40px);
      background: no-repeat top center;
      background-size: rem(32px) auto;
      padding-top: rem(40px);

      @include mq($from: md) {
        padding-left: calc(#{rem(40px)} + 0.5em);
        font-size: rem(14px);
        padding-top: 0;
        background-position: left center;
      }

      @include mq($from: lg) {
        font-size: rem(16px);
      }

      [href="#constant"] & {
        background-image: url(../images/_inline/donation-keep.svg);
      }

      [href="#single"] & {
        background-image: url(../images/_inline/donation-one.svg);
      }

      [href="#books"] & {
        background-image: url(../images/_inline/donation-books.svg);
      }
    }
  }
}
