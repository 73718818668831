.page-nav {
  @include set-row;

  padding-top: $spacer;
  padding-bottom: $spacer;

  & > a {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto 1em;
    text-align: left;
    font-size: rem(14px);

    @include mq($from: md) {
      @include set-col(percentage(1/2));
    }

    @include mq($from: lg) {
      @include set-col(calc(#{percentage(1/3)} - #{$spacer}));
    }

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: calc(100% - #{$spacer * 2});
      height: 2px;
      margin-right: auto;
      margin-left: auto;
      content: "";
      background-color: $color-bg-circle;
    }

    &::after {
      position: absolute;
      top: 1.25em;
      right: 1em;
      width: 1em;
      height: 1em;
      content: "";
      background: no-repeat center;
      background-image: svg-load("../images/_inline/link-right.svg");
      background-size: contain;
    }

    & > span {
      font-weight: 500;
      position: relative;
      display: inline-block;
      padding: 1em 0.125em;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        content: "";
        background-color: $color-main;
      }
    }
  }
}
