.go-top {
  position: fixed;
  z-index: $z-header - 20;
  right: 2.5%;
  // bottom: calc(60px + 5%);
  bottom: 7%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  transition: background-color 0.4s ease, box-shadow 0.4s ease;
  opacity: 0;
  border: none;
  border-radius: 50%;
  background-color: $color-main;

  @include mq($from: lg) {
    width: 60px;
    height: 60px;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: lighten($color-main, $amount: 5%);
    box-shadow: $box-shadow-card-lg;
  }

  & > svg {
    @include center-method;

    width: 46%;
    height: 46%;
  }

  &__arrow {
    fill: $color-base;
  }
}
