.section-heading {
  position: relative;

  @include mq($from: lg) {
    display: flex;
    align-items: flex-start;
  }

  & > h2,
  & > h3 {
    position: relative;
    padding-bottom: 0.85em;
    letter-spacing: 0.08em;

    @include mq($from: lg) {
      margin-right: 1em;
    }
  }

  & > h2 {
    font-size: rem(30px);

    &::before {
      @include grad-bar();

      margin-left: 0;
    }
  }

  & > p {
    @include mq($from: lg) {
      padding-top: 1em;
    }
  }
}

.center-heading {
  position: relative;
  display: block;
  margin-bottom: 1em;
  padding-bottom: 0.75em;
  text-align: center;

  &::before {
    @include grad-bar();

    margin-right: auto;
    margin-left: auto;
  }

  & a {
    color: $color-text;
  }
}

.page-header {
  @extend .block;

  .center-heading {
    font-size: rem(20px);
  }
}

.page-thumbnail-header {
  position: relative;
  overflow: hidden;
  padding-top: rem(160px);

  @include mq($from: md) {
    padding-top: rem(220px);
  }

  @include mq($from: lg) {
    padding-top: rem(280px);
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - #{$spacer * 2} - 10%);
    height: 100%;

    @each $breakpoint, $width in $container-max-width {
      @include mq($from: $breakpoint) {
        $gutter: 7%;

        width: calc(calc(calc(100% - #{$width}) / 2) + #{$width} - #{$gutter});

        @if $breakpoint == lg {
          $gutter: 7.5rem;
        }
      }
    }

    & > img {
      @include center-method;

      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__heading {
    @extend .h3;

    position: absolute;
    bottom: 1em;
    margin-bottom: 0;

    @include mq($from: lg) {
      bottom: 1.5em;
    }

    & > span {
      position: relative;
      display: inline-block;
      padding: 0.5em 0.15em calc(0.5em + 5px);
      background-color: $color-base;

      @include mq($from: lg) {
        font-size: 120%;
      }

      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        margin: auto auto 0;
        content: "";
        background: $color-main-grad;

        @include mq($from: lg) {
          height: 8px;
        }
      }
    }
  }

  & > .container {
    position: relative;
  }
}

.line-arrow-heading {
  font-style: italic;
  position: relative;
  display: block;
  margin-bottom: 30px;
  text-align: center;

  & > span {
    position: relative;
    display: inline-block;

    &::before,
    &::after {
      position: absolute;
      content: "";
    }

    &::before {
      top: 100%;
      width: 100%;
      height: 4px;
      background-color: $color-main;
    }

    &::after {
      top: 100%;
      right: 0;
      left: 0;
      width: 0;
      height: 0;
      margin: 0 auto;
      border-width: 18px 14px 0 0;
      border-style: solid;
      border-color: $color-main transparent transparent transparent;
    }
  }
}

.border-heading {
  position: relative;
  margin-bottom: 0.25em;
  padding-left: 1em;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    height: calc(100% - 0.5em);
    margin: auto auto auto 0;
    content: "";
    background-color: $color-main;
  }
}

.icon-heading {
  position: relative;
  margin-bottom: 0.25em;
  padding-left: 1.75em;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1.25em;

  &.is-school {
    background-image: svg-load("../images/_inline/school.svg");
  }
}

.svg-icon-heading {
  $icon-width: 54px;

  @include set-row;

  align-items: center;
  width: 100%;

  &__icon {
    @include svgRatio(100%);

    position: relative;
    width: rem($icon-width / 2);
    height: rem($icon-width / 2);

    @include mq($from: md) {
      width: rem($icon-width);
      height: rem($icon-width);
    }

    & > svg {
      fill: $color-main !important;
    }
  }

  &__label {
    @include set-col(calc(100% - #{rem($icon-width)}));

    padding-left: 0.5rem;
  }
}

.checked-heading {
  @include set-row;

  width: 100%;

  &__icon {
    @include set-col(2em);

    position: relative;
    top: 0.125em;
    display: inline-block;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-image: $color-main-grad;

    &::before {
      @include center-method;

      content: "";
      background: no-repeat center;
      background-image: svg-load(
        "../images/_inline/inner-checked.svg",
        fill=$color-base
      );
      background-size: 50% auto;
    }
  }

  &__label {
    @include set-col(calc(100% - 2em));

    display: inline-block;
    margin-top: 0.25em;
    padding-left: 0.5em;
  }
}

.sub-content {
  font-size: rem(12px);
  font-weight: bold;
  display: inline-block;

  @include mq($from: lg) {
    font-size: rem(14px);
  }
}
