.block {
  padding-top: rem(30px);
  padding-bottom: rem(30px);

  &--sm {
    padding-top: rem(15px);
    padding-bottom: rem(15px);
  }

  &--lg {
    padding-top: rem(60px);
    padding-bottom: rem(60px);
  }

  &--top-0 {
    padding-top: 0;
  }

  &--bottom-0 {
    padding-bottom: 0;
  }

  &--gutter-lg {
    @include mq($from: lg) {
      padding-right: $spacer-lg;
      padding-left: $spacer-lg;
    }
  }

  &--gutter-xl {
    @include mq($from: xl) {
      padding-right: $spacer-xl;
      padding-left: $spacer-xl;
    }
  }
}

.nallow-block {
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}

.gutter {
  padding-left: 0.5em;
  padding-right: 0.5em;
  display: inline-block;
}
