.container {
  position: relative;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacer;
  padding-left: $spacer;

  @include container-breakpoint;

  &.is-narrow {
    @include mq($from: xl) {
      max-width: 960px;
    }
  }
}

.row {
  @include make-row();

  &--gutter {
    margin-right: 0 - $spacer;
    margin-left: 0 - $spacer;

    & [class*="col"] {
      padding-right: $spacer;
      padding-left: $spacer;
    }
  }

  &--center {
    justify-content: center;
  }

  &--v-center {
    align-items: center;
  }

  &--center-all {
    align-items: center;
    justify-content: center;
  }

  &--pb30 {
    padding-bottom: $spacer * 2;
  }
}

.col {
  @include make-col(12);

  padding-left: $spacer;
  padding-right: $spacer;

  @for $i from 1 through 12 {
    &--#{$i} {
      @include make-col($i);
    }
  }

  @for $i from 1 through 12 {
    &--md-#{$i} {
      @include mq($from: md) {
        @include make-col($i);
      }
    }
  }

  @for $i from 1 through 12 {
    &--lg-#{$i} {
      @include mq($from: lg) {
        @include make-col($i);
      }
    }
  }

  &--sm-pd0 {
    padding-left: 0;
    padding-right: 0;

    @include mq($from: md) {
      padding-left: $spacer;
      padding-right: $spacer;
    }
  }
}

.offset {
  @for $i from 0 through 12 {
    &--#{$i} {
      @include make-col-offset($i);
    }
  }

  @for $i from 0 through 12 {
    &--md-#{$i} {
      @include mq($from: md) {
        @include make-col-offset($i);
      }
    }
  }

  @for $i from 0 through 12 {
    &--lg-#{$i} {
      @include mq($from: lg) {
        @include make-col-offset($i);
      }
    }
  }
}
