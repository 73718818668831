.introduction {
  line-height: 1.8;

  &__heading {
    line-height: 2;
    text-align: center;
    margin-bottom: 1em;
    font-size: rem(20px);

    @include mq($from: lg) {
      font-size: rem(28px);
    }
  }

  &__body {
    @include mq($from: lg) {
      line-height: 2.5;
    }
  }
}
