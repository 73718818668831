/**
 * classes 詳細ページ 固有クラス
 */

.classes-detail {
  font-size: rem(15px);

  &__date {
    @include set-row;

    font-weight: $font-weight-bold;
    position: relative;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;

    &__time {
      @extend .h3;

      margin-right: 0.25rem;
      margin-bottom: 0;
    }

    &__dow {
      @include dow;

      position: relative;
      margin-top: 5px;
      margin-right: 0.5rem;
    }
  }

  &__thumbnail {
    @include thumbnail-obj-fit(percentage(3/4));

    margin-top: $spacer;

    @include mq($from: lg) {
      display: none;
    }
  }

  &__attention {
    font-size: rem(12px);
    margin-top: 2em;
    padding: $spacer;
    border: 3px solid darken($color-bg-circle, 10%);

    h4 {
      font-size: rem(14px);
    }
  }

  &__info {
    padding-left: 0;
    list-style: none;

    & > li {
      font-size: rem(14px);
      font-weight: bold;
      margin-bottom: 0.25em;
      padding-left: rem(28px);
      background-repeat: no-repeat;
      background-position: left top;
      background-size: rem(22px);

      &.is-place {
        background-image: svg-load("../images/_inline/place.svg");
      }

      &.is-teacher {
        background-image: svg-load("../images/_inline/teacher.svg");
      }
    }
  }

  &__well {
    font-size: rem(14px);
    margin-bottom: $spacer * 2;
  }

  &__require-top {
    align-items: center;

    @include mq($from: lg) {
      @include set-row;
    }

    dt,
    dd {
      margin-bottom: 0.5em;
      padding-right: 1em;
    }

    dt {
      line-height: 1.5;
      padding-right: 0.5em;
      padding-left: rem(28px);
      vertical-align: middle;
      color: $color-main;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: rem(22px);

      &.is-price {
        background-image: svg-load("../images/_inline/price.svg");
      }

      &.is-people {
        background-image: svg-load("../images/_inline/people.svg");
      }

      &.is-checked {
        background-image: svg-load("../images/_inline/checked.svg");
      }
    }
  }

  &__require-bottom {
    font-size: rem(14px);
    padding-top: 1em;

    @include mq($from: lg) {
      @include set-row;
    }

    dt {
      @include mq($from: lg) {
        @include set-col(7rem);
      }
    }

    dd {
      @include mq($from: lg) {
        @include set-col(calc(100% - 7rem));
      }
    }
  }
}

.contents-detail-side-card {
  @include set-row;

  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $color-base;

  @include mq($from: lg) {
    position: relative;
    top: 118px;
    right: auto;
    bottom: auto;
    left: auto;
    box-shadow: $box-shadow-card;
  }

  &__body,
  &__button {
    width: 100%;

    @include mq($until: lg) {
      @include set-col(percentage(1/2));
    }
  }

  &__body {
    padding: rem(5px);

    @include mq($from: lg) {
      text-align: center;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__thumbnail {
    @include thumbnail-obj-fit($percentage: percentage(3/4));

    display: none;

    @include mq($from: lg) {
      display: block;
    }
  }

  &__label {
    font-size: rem(14px);
    line-height: 1.4;
    margin-bottom: 0.25em;

    @include mq($from: lg) {
      font-size: rem(14px);
      margin-bottom: 0.5em;
    }

    & > span {
      display: inline-block;
      border-bottom: 3px solid $color-main;

      @include mq($from: lg) {
        padding-bottom: 0.25em;
      }
    }
  }

  &__period {
    font-size: rem(12px);
    line-height: 1.2;
    display: block;

    @include mq($from: lg) {
      margin-bottom: 0.5em;
    }

    &__arrow {
      &::after {
        @include triangle($direction: right, $color: $color-text, $size: 5px);

        margin-right: 0.5em;
        margin-left: 0.5em;
        content: "";
      }
    }
  }
}
