.sns-share {
  justify-content: flex-end;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  min-height: rem(42px);

  @include set-row;

  & > *:not(:last-child) {
    margin-right: 0.25em;
  }
}

.fb_iframe_widget > span {
  vertical-align: baseline !important;
}
