/**
 * Global
 */

html {
  @include mq($from: lg) {
    scroll-padding-top: rem(88px);
  }

  scroll-padding-top: rem(40px);
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.8;
  font-feature-settings: "palt";

  @supports (font-feature-settings: "palt") {
    letter-spacing: $font-tracking;
  }

  &.js-is-modal {
    overflow: hidden;
  }

  // &.preload {
  //   & * {
  //     transition: none !important;
  //   }
  // }
}

a {
  color: $color-main;

  &:focus,
  &:active,
  &:hover {
    color: darken($color-main, 10%);
  }

  &:link,
  &:visited {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-heading;
  font-weight: $font-weight-bold;
  line-height: 1.6;
  margin-right: -0.145em;
  margin-bottom: 1rem;
  letter-spacing: 0.145em;
}

// generate heading size
@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    font-size: rem(($base-font-size * 0.86) + $heading-scale-ratio * (6 - $i));

    @include mq($from: lg) {
      font-size: rem($base-font-size + $heading-scale-ratio * (6 - $i));
    }
  }
}

.contents-main table {
  width: 100%;
  margin-bottom: $spacer * 2;
  background-color: $color-base;

  th,
  td {
    padding: 1em;
    vertical-align: top;
    border-color: $color-bg-circle !important;
    border-top: 1px solid $color-bg-circle;
    // border-left: 1px solid $color-bg-circle;
  }

  // th {
  //   background-color: $color-bg-circle;
  // }

  // th,
  // td:last-child {
  //   border-right: 1px solid $color-bg-circle;
  // }

  tr:last-child {
    th,
    td {
      border-bottom: 1px solid $color-bg-circle;
    }
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid $color-bg-circle;
    background-color: $color-bg-circle;
  }

  tbody + tbody {
    border-top: 1px solid $color-bg-circle;
  }
}

p {
  word-wrap: break-word;
}

hr {
  margin-top: $spacer;
  margin-bottom: $spacer;
  border: 0;
  border-top: 1px solid rgba($color-border, 0.2);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

.main {
  position: relative;
  display: block;
  margin-top: rem(40px);
  overflow: hidden;

  @include mq($from: lg) {
    margin-top: rem(80px);
  }

  #home & {
    padding-top: 0;
  }
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & .flex-footer {
    margin-top: auto;
  }
}

.ie-alert {
  font-size: 14px;
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto auto 0;
  padding: 5px 15px;
  color: #856404;
  background-color: #fff3cd;
}
