$position_center: 6px;

.menu-toggle {
  position: relative;
  display: inline-block;
  padding: rem(5px) 0;
  border: none;
  background-color: transparent;

  @include mq($from: lg) {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;

    & span {
      background-color: $color-main;
    }
  }

  &__body {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: rem(22px);
    height: rem(14px);
    margin: 0 auto;
    transition: all 0.4s;
  }

  span {
    position: absolute;
    right: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: rem(2px);
    transition: transition 0.4s, opacity 0.4s;
    background-color: $color-main;

    &:nth-of-type(1) {
      top: 0;
      animation: menu-bar01 0.25s forwards;
    }

    &:nth-of-type(2) {
      top: $position_center;
      transition: transition 0.05s 0.05s, opacity 0.05s;
      opacity: 1;
    }

    &:nth-of-type(3) {
      bottom: 0;
      animation: menu-bar02 0.25s forwards;
    }
  }

  .js-is-menu-open & span {
    &:nth-of-type(1) {
      animation: active-menu-bar01 0.25s forwards;
    }

    &:nth-of-type(2) {
      opacity: 0;
    }

    &:nth-of-type(3) {
      width: 100%;
      animation: active-menu-bar03 0.25s forwards;
    }
  }
}

@keyframes menu-bar01 {
  0% {
    transform: translateY($position_center) rotate(45deg);
  }

  50% {
    transform: translateY($position_center) rotate(0);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar02 {
  0% {
    transform: translateY(-$position_center) rotate(-45deg);
  }

  50% {
    transform: translateY(-$position_center) rotate(0);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY($position_center) rotate(0);
  }

  100% {
    transform: translateY($position_center) rotate(45deg);
  }
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(-$position_center) rotate(0);
  }

  100% {
    transform: translateY(-$position_center) rotate(-45deg);
  }
}
