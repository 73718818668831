/**
 * Form
 */

.form {
  $this: &;

  // button,
  input,
  select,
  textarea,
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  select,
  input,
  textarea {
    font-size: 1rem;
    position: relative;
    margin-bottom: 0.5rem;
    padding: 0.75em 1em;
    color: lighten($color-text, 20%);
    border: 0;
    border-radius: rem(5px);
    background-color: $color-bg-circle;
  }

  &.is-long {
    select,
    input,
    textarea {
      display: block;
      width: 100%;
    }
  }

  input {
    p.button & {
      margin-bottom: auto;
      padding: 0 1em;
    }
  }

  select:not([multiple]) {
    padding-right: 3em;
    background-color: $color-bg-circle;
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        $color-text-light 50%
      ),
      linear-gradient(135deg, $color-text-light 50%, transparent 50%);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) 25px, calc(100% - 15px) 25px,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
  }

  select[multiple] {
    padding-right: 0;
    padding-left: 0;
  }

  option {
    padding: 0.25em 1em;

    &:checked {
      background-color: darken($color-bg-circle, 20%);
    }
  }

  input[type="file"] {
    font-size: rem(14px);
    position: relative;
    z-index: 2;
  }

  input[type="text"],
  input[type="password"],
  input[type="file"],
  select,
  textarea {
    transition: 0.2s;
    border: 2px solid transparent;
    outline: none;

    &:focus {
      border-color: $color-main;
      box-shadow: 0 2px 3px rgba($color-text, 0.1), $box-shadow-card;
    }
  }

  .is-checkbox,
  .is-radio {
    input[type="checkbox"],
    input[type="radio"] {
      display: none;

      &:disabled + label {
        opacity: 0.4 !important;
      }
    }

    input[type="checkbox"] {
      & + label {
        background-image: svg-load(
          "../images/_inline/checkbox-unchecked.svg",
          fill=$color-main
        );
      }

      &:checked + label {
        background-image: svg-load(
          "../images/_inline/checkbox-checked.svg",
          fill=$color-main
        );
      }
    }

    input[type="radio"] {
      & + label {
        background-image: svg-load(
          "../images/_inline/radio-unchecked.svg",
          fill=$color-main
        );
      }

      &:checked + label {
        background-image: svg-load(
          "../images/_inline/radio-checked.svg",
          fill=$color-main
        );
      }
    }

    label {
      position: relative;
      display: inline-block;
      padding: rem(3px) rem(3px) rem(3px) rem(20px);
      cursor: pointer;
      background: no-repeat left center;
      background-size: 1em;
    }
  }
}

.form-required {
  font-size: rem(11px);
  display: inline-block;
  margin-right: 0.25em;
  margin-left: 0.25em;
  padding: 0.075em 0.5em 0.05em;
  color: $color-base;
  background-color: $color-error;
}

.error {
  font-size: rem(12px);
  font-weight: bold;
  color: $color-error;

  &::before {
    @include triangle($direction: top, $color: $color-error, $size: 0.35em);

    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
    content: "";
  }
}

.form-row {
  @include set-row;

  align-items: center;

  &.is-double {
    & .form-col {
      @include mq($from: lg) {
        @include set-col(percentage(1/2));
      }
    }
  }

  &.is-triple {
    & .form-col {
      @include mq($from: lg) {
        @include set-col(percentage(1/3));
      }
    }
  }

  &.is-triple-2by3 {
    & .form-col {
      @include mq($from: lg) {
        @include set-col(percentage(2/3));
      }
    }
  }

  &.is-inline {
    & input,
    & select {
      width: auto;
    }
  }
}

.form-col {
  width: 100%;
  padding-right: 0.25em;
  padding-left: 0.25em;

  .is-radio &,
  .is-checkbox & {
    width: auto;
    min-width: 6em;
    padding: 0.5em;

    @include mq($from: lg) {
      min-width: 6em;
    }
  }
}

.form-list {
  $dt-width: 14em;

  @include set-row;

  padding-left: 0;

  dt,
  dd {
    width: 100%;
    padding: 0 0.25em;
  }

  dt {
    font-size: rem(14px);

    @include mq($from: lg) {
      @include set-col($dt-width);

      padding-top: 1em;
      text-align: right;
    }
  }

  dd {
    @include mq($from: lg) {
      @include set-col(calc(100% - #{$dt-width}));

      padding-left: 1em;
    }
  }
}

.credit-card-lead {
  @include set-row;

  align-items: center;
  justify-content: center;
  width: 100%;
  padding: $spacer / 2 0;

  &__col {
    padding: $spacer / 2 $spacer;
  }
}

.donate-form {
  @include set-row;

  align-items: center;
  justify-content: center;

  &__yen {
    display: inline-block;
    padding-right: 0.25em;
    padding-left: 0.25em;
  }

  &__col {
    padding: 0.25em 0.5em;

    & input {
      margin-bottom: 0;
    }
  }
}
