/**
 * コンテンツグリッド
 */

.contents-main,
.contents-side {
  position: relative;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacer;
  padding-left: $spacer;
}

.contents-main {
  max-width: 960px;
}

.contents-row {
  @include container-breakpoint;
  @include set-row;

  position: relative;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  padding-top: 30px;

  .contents-main {
    @include mq($from: lg) {
      @include set-col(percentage(740 / 1060));
    }
  }

  .contents-side {
    @include mq($from: lg) {
      @include set-col(percentage(260 / 1060));
    }

    &__body {
      @include mq($from: lg) {
        position: sticky;
      }
    }
  }
}

.contents-side-list {
  &__heading {
    font-size: rem(14px);
    padding-left: 1em;
    color: $color-main;
    border-left: 3px solid $color-main;
  }

  &__links > a,
  &__select {
    position: relative;

    &::before {
      @include triangle(right, $color-main, 4px);

      position: absolute;
      top: 1em;
      left: 0;
      content: "";
    }
  }

  &__links {
    & > a {
      font-size: rem(12px);
      position: relative;
      display: block;
      padding: 0.5em;
      padding-left: 1em;
      color: $color-text;

      &:hover {
        color: $color-main;
      }
    }
  }

  &__select {
    width: 100%;
    font-size: rem(14px);
    box-sizing: border-box;
    min-width: 60%;
    height: 50px;
    color: $color-text-light;
    padding-left: 1em;
    padding-right: 1em;
    border: 1px solid rgba($color-border, 0.2);
    border-radius: 0;
    background-color: $color-base;
    background-image: linear-gradient(
      45deg,
      transparent 50%,
      $color-text-light 50%
    ),
      linear-gradient(135deg, $color-text-light 50%, transparent 50%);
    background-position: calc(100% - 20px) 25px, calc(100% - 15px) 25px,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none;
    }
  }
}

[data-sticky-item] {
  @include mq($from: lg) {
    top: 110px;
    transition-property: top, bottom;
    transition-duration: 0.8s;
  }
}
