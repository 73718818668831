.contents-club-side-card {
  position: relative;
  right: auto;
  bottom: auto;
  left: auto;
  padding-bottom: $spacer * 2;

  // &[data-sticky-item] {
  //   @include mq($from: lg) {
  //     top: 126px;
  //   }J8jhZs5d
  // }

  &__body {
    & h4 + a {
      display: block;
      max-width: 200px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__thumbnail {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
