.social-section {
  @include set-row;

  background-color: $color-bg-sns-hover;

  &__col {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;

    @include mq($from: lg) {
      min-height: 245px;

      @include set-col(percentage(1/2));
    }

    &--mail-magazine {
      transition: background-color 0.6s ease;
      text-decoration: none;
      color: $color-base;
      background: $color-main-grad;

      &::before {
        content: "";
        transition: opacity 0.4s ease;
        opacity: 0;
        background-color: $color-base;

        @include center-method;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 1em;
        bottom: 0;
        display: block;
        width: 14px;
        height: 14px;
        margin-top: auto;
        margin-bottom: auto;
        content: "";
        transition: transform 0.4s ease;
        transform: rotate(45deg);
        border-top: 2px solid $color-base;
        border-right: 2px solid $color-base;

        @include mq($from: lg) {
          right: 5%;
        }
      }

      &:hover {
        text-decoration: none;
        color: $color-base;
        background-color: rgba($color-main, 0.8);

        &::before {
          opacity: 0.2;
        }

        &::after {
          transform: rotate(45deg) translate(0.25em, -0.25em);
        }
      }

      & > * {
        position: relative;
      }
    }
  }
}
