@charset "UTF-8";
/** Config */
/**
 * Variables
 */
/**
* Mixins
*/
/**
 * External
 */
/*!
 * Bootstrap Reboot v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Hiragino Sans", "Hiragino Kaku Gothic ProN", CustomYuGothicM, Meiryo, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/**
 * Functions
 */
/** Common */
/**
 * Global
 */
html {
  scroll-padding-top: 2.5rem;
}
@media (min-width: 62em) {
  html {
    scroll-padding-top: 5.5rem;
  }
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.8;
  font-feature-settings: "palt";
}
@supports (font-feature-settings: "palt") {
  body {
    letter-spacing: 0.06em;
  }
}
body.js-is-modal {
  overflow: hidden;
}

a {
  color: #00b458;
}
a:focus, a:active, a:hover {
  color: #00813f;
}
a:link, a:visited {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.classes-detail__date__time,
.page-thumbnail-header__heading,
.h4,
.keyword-list__heading,
.h5,
.content-report__heading,
.h6,
.search-modal__heading {
  font-family: "Lato", "Hiragino Kaku Gothic Pro", -apple-system, BlinkMacSystemFont, "Hiragino Sans", "Hiragino Kaku Gothic ProN", CustomYuGothicM, Meiryo, sans-serif;
  font-weight: 700;
  line-height: 1.6;
  margin-right: -0.145em;
  margin-bottom: 1rem;
  letter-spacing: 0.145em;
}

h1,
.h1 {
  font-size: 1.735rem;
}
@media (min-width: 62em) {
  h1,
  .h1 {
    font-size: 1.875rem;
  }
}

h2,
.h2 {
  font-size: 1.56rem;
}
@media (min-width: 62em) {
  h2,
  .h2 {
    font-size: 1.7rem;
  }
}

h3,
.h3,
.classes-detail__date__time,
.page-thumbnail-header__heading {
  font-size: 1.385rem;
}
@media (min-width: 62em) {
  h3,
  .h3,
  .classes-detail__date__time,
  .page-thumbnail-header__heading {
    font-size: 1.525rem;
  }
}

h4,
.h4,
.keyword-list__heading {
  font-size: 1.21rem;
}
@media (min-width: 62em) {
  h4,
  .h4,
  .keyword-list__heading {
    font-size: 1.35rem;
  }
}

h5,
.h5,
.content-report__heading {
  font-size: 1.035rem;
}
@media (min-width: 62em) {
  h5,
  .h5,
  .content-report__heading {
    font-size: 1.175rem;
  }
}

h6,
.h6,
.search-modal__heading {
  font-size: 0.86rem;
}
@media (min-width: 62em) {
  h6,
  .h6,
  .search-modal__heading {
    font-size: 1rem;
  }
}

.contents-main table {
  width: 100%;
  margin-bottom: 2rem;
  background-color: #fff;
}
.contents-main table th,
.contents-main table td {
  padding: 1em;
  vertical-align: top;
  border-color: #f1f1f1 !important;
  border-top: 1px solid #f1f1f1;
}
.contents-main table tr:last-child th,
.contents-main table tr:last-child td {
  border-bottom: 1px solid #f1f1f1;
}
.contents-main table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #f1f1f1;
  background-color: #f1f1f1;
}
.contents-main table tbody + tbody {
  border-top: 1px solid #f1f1f1;
}

p {
  word-wrap: break-word;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(135, 136, 136, 0.2);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

.main {
  position: relative;
  display: block;
  margin-top: 2.5rem;
  overflow: hidden;
}
@media (min-width: 62em) {
  .main {
    margin-top: 5rem;
  }
}
#home .main {
  padding-top: 0;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.flex-wrapper .flex-footer {
  margin-top: auto;
}

.ie-alert {
  font-size: 14px;
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto auto 0;
  padding: 5px 15px;
  color: #856404;
  background-color: #fff3cd;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mb-60 {
  margin-bottom: 3.75rem !important;
}

.pl-0 {
  padding-left: 0;
}

@media (min-width: 48em) {
  .center-box-md {
    max-width: 83.3333333333%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 62em) {
  .center-box-md {
    max-width: 66.6666666667%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 62em) {
  .center-box-lg {
    max-width: 66.6666666667%;
    margin-right: auto;
    margin-left: auto;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive > iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive--1by1::before {
  padding-top: 100%;
}
.embed-responsive--21by9::before {
  padding-top: 42.8571428571%;
}
.embed-responsive--16by9::before {
  padding-top: 56.25%;
}
.embed-responsive--4by3::before {
  padding-top: 75%;
}
.embed-responsive--1by1::before {
  padding-top: 100%;
}
@media (min-width: 62em) {
  .embed-responsive--lg-21by9::before {
    padding-top: 42.8571428571%;
  }
}
@media (min-width: 62em) {
  .embed-responsive--lg-16by9::before {
    padding-top: 56.25%;
  }
}
@media (min-width: 62em) {
  .embed-responsive--lg-4by3::before {
    padding-top: 75%;
  }
}
@media (min-width: 62em) {
  .embed-responsive--lg-1by1::before {
    padding-top: 100%;
  }
}

.lozad {
  z-index: 0;
  opacity: 0;
  will-change: opacity;
}
.lozad[data-loaded=true] {
  transition: opacity 0.4s;
  opacity: 1;
}

.lazyloading {
  transition: opacity 1s;
  opacity: 1;
  background: #e6e6e6 no-repeat center;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.d-none {
  display: none !important;
}

@media (min-width: 48em) {
  .d-md-none {
    display: none !important;
  }
}

@media (min-width: 62em) {
  .d-lg-none {
    display: none !important;
  }
}

.d-block {
  display: block !important;
}

.d-md-block {
  display: none;
}
@media (min-width: 48em) {
  .d-md-block {
    display: block !important;
  }
}

.d-lg-block {
  display: none;
}
@media (min-width: 62em) {
  .d-lg-block {
    display: block !important;
  }
}

.order-1 {
  order: 1;
}

@media (min-width: 48em) {
  .order-md-1 {
    order: 1;
  }
}

.order-2 {
  order: 2;
}

@media (min-width: 48em) {
  .order-md-2 {
    order: 2;
  }
}

.order-3 {
  order: 3;
}

@media (min-width: 48em) {
  .order-md-3 {
    order: 3;
  }
}

.order-4 {
  order: 4;
}

@media (min-width: 48em) {
  .order-md-4 {
    order: 4;
  }
}

.order-5 {
  order: 5;
}

@media (min-width: 48em) {
  .order-md-5 {
    order: 5;
  }
}

.order-6 {
  order: 6;
}

@media (min-width: 48em) {
  .order-md-6 {
    order: 6;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only:active, .sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

a.overlay {
  transition: 0.4s ease;
  opacity: 0;
  background-color: #fff;
}
a.overlay:hover {
  opacity: 0.2;
}

.d-block-md {
  display: none;
}
@media (min-width: 48em) {
  .d-block-md {
    display: block;
  }
}

.d-block-lg {
  display: none;
}
@media (min-width: 62em) {
  .d-block-lg {
    display: block;
  }
}

.hidden-md {
  display: block;
}
@media (min-width: 48em) {
  .hidden-md {
    display: none;
  }
}

.hidden-lg {
  display: block;
}
@media (min-width: 62em) {
  .hidden-lg {
    display: none;
  }
}

.narrow-block {
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}

.thumbnail {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  display: block;
  margin: 0 auto 1em;
}
.thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.thumbnail.is-16by9 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 56.25%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.thumbnail.is-16by9 > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .thumbnail.is-16by9 > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.thumbnail.is-4by3 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 75%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.thumbnail.is-4by3 > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .thumbnail.is-4by3 > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}

.round-thumbnail-wrapper {
  width: 100%;
  margin: 0 auto 1em;
}
.round-thumbnail-wrapper.is-md {
  max-width: 15.3125rem;
}
.round-thumbnail-wrapper.is-sm {
  max-width: 9.0625rem;
}
.round-thumbnail-wrapper figcaption {
  display: block;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}
.round-thumbnail-wrapper figcaption > small, .round-thumbnail-wrapper figcaption > span {
  font-weight: bold;
  display: block;
}

.round-thumbnail {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  z-index: 1;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  background-color: #737373;
  background-size: cover;
  box-shadow: 0 0 0.5rem rgba(51, 51, 51, 0.05) inset;
}
.round-thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .round-thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.round-thumbnail--r-corner {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 75%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  border-radius: 10px;
}
.round-thumbnail--r-corner > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .round-thumbnail--r-corner > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}

.hr-chip {
  position: relative;
  overflow: visible;
  margin-bottom: 1.875rem;
}
.hr-chip::before {
  display: block;
  width: 20%;
  height: 0.35rem;
  margin: 0 auto;
  content: "";
  background-color: #00b458;
}
@media (min-width: 62em) {
  .hr-chip::before {
    width: 6rem;
  }
}

.well {
  display: block;
  padding: 1em;
  color: #333;
  border-radius: 5px;
  background-color: #f1f1f1;
}
.well__lead {
  display: block;
}
.well__lead > strong {
  display: inline-block;
  padding: 0.25em 1em;
  border-radius: 50px;
  background-color: #fff;
}
.well h2,
.well h3,
.well h4,
.well h5 {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

/** parallax */
@supports (--css: variables) {
  .parallax {
    transform: translateY(calc(var(--viewport-y) * 16%));
  }
}

/** Lazy Load bg img */
[data-bg] {
  opacity: 0;
}
[data-bg].is-bg-loaded {
  transition: opacity 0.8s ease;
  opacity: 1;
}

.lead {
  font-size: 1.25rem;
}

.text-size-xs {
  font-size: 0.75rem;
}

.text-size-sm {
  font-size: 0.875rem;
}

.text-size-md {
  font-size: 1rem;
}

.text-size-lg {
  font-size: 1.125rem;
}

.text-size-xl {
  font-size: 1.5rem;
}

.text-danger {
  color: red;
}

.text-center {
  text-align: center;
}

@media (min-width: 48em) {
  .text-center-md {
    text-align: center;
  }
}

@media (min-width: 62em) {
  .text-center-lg {
    text-align: center;
  }
}

.text-right {
  text-align: right;
}

.text-hide {
  font-size: 0;
  color: transparent;
}

.text-block > span {
  display: block;
}

.text-block-md > span {
  display: inline-block;
}
@media (min-width: 48em) {
  .text-block-md > span {
    display: block;
  }
}

.text-block-lg > span {
  display: inline-block;
}
@media (min-width: 62em) {
  .text-block-lg > span {
    display: block;
  }
}

.text-autoline > span {
  display: inline-block;
}

.text-color-main {
  color: #00b458;
}

.line-height-large {
  line-height: 2;
}
@media (min-width: 62em) {
  .line-height-large {
    line-height: 2.2;
  }
}

.poetry {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.poetry__col {
  width: 100%;
}
@media (min-width: 62em) {
  .poetry__col {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}
.poetry__col p:last-child {
  margin-bottom: 0;
}
.poetry__lead {
  font-size: 1.5rem;
  letter-spacing: 0.15em;
  font-feature-settings: normal;
}
@media (min-width: 62em) {
  .poetry__lead {
    font-size: 2.5rem;
    padding-right: 0.5em;
  }
}
.poetry__content {
  line-height: 1.8;
}
@media (min-width: 48em) {
  .poetry__content {
    line-height: 2.5;
  }
}

.blockquote-util {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.blockquote-util__body {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: 0.5rem 2rem;
}
@media (min-width: 48em) {
  .blockquote-util__body {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
.blockquote-util__body::before, .blockquote-util__body::after {
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  pointer-events: none;
  background: no-repeat center;
  background-image: url("../images/_inline/dobule-quort.svg");
  background-size: contain;
}
@media (min-width: 48em) {
  .blockquote-util__body::before, .blockquote-util__body::after {
    width: 2rem;
    height: 2rem;
  }
}
.blockquote-util__body::before {
  top: 0;
  left: 0;
}
.blockquote-util__body::after {
  right: 0;
  bottom: 0;
  transform: rotate(180deg);
}
.blockquote-util__body span {
  display: block;
  text-align: center;
}
@media (min-width: 48em) {
  .blockquote-util__body span {
    line-height: 2;
  }
}

/** Layouts */
/**
 * Header
 */
.header {
  position: fixed;
  z-index: 1000;
  right: 0;
  left: 0;
  overflow: visible;
  height: 0;
  transition: 0.4s;
}
@media (min-width: 62em) {
  .header {
    display: flex;
    width: 100%;
    height: auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07), 0 3px 16px rgba(0, 0, 0, 0.03);
  }
}
.js-is-menu-open .header {
  height: 100%;
  transition-duration: 0.1s;
}
.header::before {
  content: "";
  transition: 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: scaleY(0);
  transform-origin: center bottom;
  opacity: 0;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 62em) {
  .header::before {
    transform: scaleY(1);
    opacity: 1;
  }
}
.js-is-menu-open .header::before {
  transform: scaleY(1);
  opacity: 1;
}
.header__body {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  pointer-events: initial;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07), 0 3px 16px rgba(0, 0, 0, 0.03);
}
@media (min-width: 62em) {
  .header__body {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
  }
}
.header__body__right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.header__sp-user {
  font-size: 0.625rem;
  margin-right: 1.5em;
}
.header__sp-user > span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10em;
}
@media (min-width: 48em) {
  .header__sp-user > span {
    max-width: 20em;
  }
}
.is-user-login .header__sp-user {
  display: block;
}
@media (min-width: 62em) {
  .is-user-login .header__sp-user {
    display: none !important;
  }
}

.brand {
  position: relative;
  display: flex;
}
.brand__icon, .brand__title {
  position: relative;
  display: block;
}
.brand__icon {
  display: none;
  width: 54px;
}
.brand__icon::before {
  display: block;
  padding-top: 85.1851851852%;
  content: "";
}
.brand__icon > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 62em) {
  .brand__icon {
    display: block;
  }
}
.brand__title {
  width: 8.25rem;
}
.brand__title::before {
  display: block;
  padding-top: 11.1471445061%;
  content: "";
}
.brand__title > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 62em) {
  .brand__title {
    width: 14.0625rem;
    margin-left: 1rem;
  }
}

.gnav__body__my-page__user, .header__sp-user {
  display: none;
}
.gnav__body__my-page__user > span, .header__sp-user > span {
  color: #333;
  font-weight: 700;
  font-family: "Hiragino Kaku Gothic Pro";
}
.gnav__body__my-page__user > span::before, .header__sp-user > span::before {
  position: relative;
  content: "";
  margin-right: 0.5em;
  background: no-repeat center;
  background-size: contain;
  background-image: svg-load("../images/_inline/user.svg", fill=#a1a1a1);
  display: inline-block;
  width: 1em;
  height: 1em;
  top: 0.05em;
}

.gnav {
  position: absolute;
  top: 0;
  visibility: hidden;
  overflow: auto;
  align-items: start;
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-top: 60px;
  transition: 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translateY(5%);
  opacity: 0;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 62em) {
  .gnav {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
    position: relative;
    z-index: 100;
    max-width: 950px;
    margin-right: 0;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
  }
}
.js-is-menu-open .gnav {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}
.gnav a {
  font-family: "Lato", "Hiragino Kaku Gothic Pro", -apple-system, BlinkMacSystemFont, "Hiragino Sans", "Hiragino Kaku Gothic ProN", CustomYuGothicM, Meiryo, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1em;
  text-align: center;
  color: #333;
}
@media (min-width: 62em) {
  .gnav a {
    font-size: 0.875rem;
  }
}
.gnav a:hover {
  text-decoration: none;
}
.gnav__body {
  display: block;
  width: 100%;
}
@media (min-width: 62em) {
  .gnav__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
.gnav__body__main, .gnav__body__sub, .gnav__body__my-page {
  display: block;
  width: 100%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}
@media (min-width: 62em) {
  .gnav__body__main, .gnav__body__sub, .gnav__body__my-page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: initial;
  }
}
.gnav__body__sub, .gnav__body__my-page {
  margin-bottom: 0;
}
@media (min-width: 62em) {
  .gnav__body__sub, .gnav__body__my-page {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
.gnav__body__sub button::before, .gnav__body__sub a::before, .gnav__body__my-page button::before, .gnav__body__my-page a::before {
  line-height: 1;
  position: relative;
  top: 0.05em;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
  content: "";
  color: #00b458;
  background: no-repeat center;
  background-size: contain;
}
@media (min-width: 62em) {
  .gnav__body__sub > li a, .gnav__body__my-page > li a {
    font-size: 0.75rem;
  }
}
.gnav__body__sub > li a:hover, .gnav__body__my-page > li a:hover {
  opacity: 0.7;
}
.gnav__body__main {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 62em) {
  .gnav__body__main {
    order: 3;
    width: auto;
  }
  .is-user-login .gnav__body__main {
    width: calc(100% - 8.75rem);
  }
}
.gnav__body__main > a {
  position: relative;
  display: block;
}
@media (min-width: 62em) {
  .gnav__body__main > a {
    padding-top: 0.5em;
    padding-bottom: 0.9375rem;
  }
}
.gnav__body__main > a::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2em;
  height: 5px;
  content: "";
  background-image: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  transition: transform 0.4s ease;
  transform: scaleX(0);
}
@media (min-width: 62em) {
  .gnav__body__main > a::after {
    text-align: center;
  }
}
.gnav__body__main > a:hover::after {
  transform: scaleX(1);
}
.gnav__body__sub {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 62em) {
  .gnav__body__sub {
    order: 2;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  .is-user-login .gnav__body__sub {
    width: auto;
  }
}
.gnav__body__sub > button, .gnav__body__sub > a {
  display: inline-flex;
  align-items: center;
  color: #00b458;
}
@media (min-width: 62em) {
  .gnav__body__sub > button, .gnav__body__sub > a {
    font-size: 0.75rem;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
.gnav__body__sub > button::before, .gnav__body__sub > a::before {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 0.375em solid transparent;
  border-left-color: #00b458;
  margin-right: -0.1875em;
  margin-right: 0.25em;
}
.gnav__body__sub > button {
  font-weight: 700;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  appearance: none;
}
.gnav__body__login {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}
@media (min-width: 62em) {
  .gnav__body__login {
    order: 4;
    padding-top: 3px;
    padding-bottom: 0;
  }
}
@media (min-width: 75em) {
  .gnav__body__login {
    padding-right: 0.5rem;
    padding-left: 1rem;
  }
}
.gnav__body__login::before {
  position: absolute;
  top: 0;
  display: block;
  width: calc(100% + 2rem);
  height: 1px;
  margin-right: -1rem;
  margin-left: -1rem;
  content: "";
  background-color: #e4e3e3;
}
@media (min-width: 62em) {
  .gnav__body__login::before {
    display: none;
  }
}
.gnav__body__login .login-link {
  font-size: 0.875rem;
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
  outline: none;
}
.js-is-modal .gnav__body__login .login-link {
  opacity: 0.4;
}
.gnav__body__my-page {
  position: relative;
}
@media (max-width: 61.99em) {
  .gnav__body__my-page {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin-right: -1rem;
    margin-left: -1rem;
  }
}
@media (min-width: 62em) {
  .gnav__body__my-page {
    order: 1;
    width: auto;
  }
}
.gnav__body__my-page::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1em;
  margin-top: -0.4em;
  content: "";
  background-color: #e4e3e3;
}
@media (max-width: 61.99em) {
  .gnav__body__my-page li {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
    border-top: 1px solid #e4e3e3;
    border-bottom: 1px solid #e4e3e3;
  }
  .gnav__body__my-page li:last-child {
    border-left: 1px solid #e4e3e3;
  }
  .gnav__body__my-page li a {
    display: block;
    padding: 1em;
  }
}
.gnav__body__my-page a::before {
  background-image: svg-load("../images/_inline/arrow-circle.svg", fill=#00b458);
}
.gnav__body__my-page__user > span {
  font-size: 0.75rem;
  padding: 1em;
}
@media (min-width: 62em) {
  .is-user-login .gnav__body__my-page__user {
    display: block;
  }
}

/**
 * Footer
 */
@media (min-width: 62em) {
  .footer {
    background-color: #f1f1f1;
  }
}
@media (max-width: 61.99em) {
  .is-sticky-enable .footer {
    padding-bottom: 80px;
  }
}
.footer a {
  color: #333;
}
.footer a:hover {
  text-decoration: none;
  opacity: 0.7;
}
.footer__gnav {
  display: block;
}
@media (min-width: 62em) {
  .footer__gnav {
    display: none;
  }
}
.footer__gnav > a {
  font-family: "Lato", "Hiragino Kaku Gothic Pro", -apple-system, BlinkMacSystemFont, "Hiragino Sans", "Hiragino Kaku Gothic ProN", CustomYuGothicM, Meiryo, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  display: block;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #fff;
  background-color: #f1f1f1;
}
.footer__container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem 1.5rem 0.5rem;
}
.footer__container__top, .footer__container__bottom {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 62em) {
  .footer__container__top, .footer__container__bottom {
    justify-content: space-between;
  }
}
.footer__container__top a, .footer__container__bottom a {
  position: relative;
}
.footer__container__top a::before, .footer__container__bottom a::before {
  margin-right: 0.5em;
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 2px solid transparent;
  border-left-color: #00b458;
  margin-right: -1px;
}
.footer__container__bottom {
  padding-top: 1em;
}
.footer__container__bottom > small {
  font-size: 0.625rem;
  display: block;
  width: 100%;
  margin-bottom: 0.5em;
  color: #666666;
}
@media (min-width: 62em) {
  .footer__container__bottom > small {
    width: auto;
  }
}
.footer__en-link {
  display: block;
  width: 100%;
}
@media (min-width: 62em) {
  .footer__en-link {
    width: auto;
  }
}
.footer__en-link > a {
  font-size: 0.75rem;
  font-weight: 700;
  display: block;
  padding: 0.25em 1em;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.2);
}
.footer__nav {
  display: block;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .footer__nav {
    margin-bottom: 0;
  }
}
.footer__nav > a {
  font-size: 0.75rem;
  display: inline-block;
  padding: 0.5em 1em;
  margin-bottom: 0.25em;
}
@media (min-width: 62em) {
  .footer__nav > a {
    padding: 0.25em 0.5em;
  }
}
.footer__nav > a > strong {
  color: #00b458;
}

.post-header__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
}
.post-header__info__category, .post-header__info__tag {
  display: flex;
  flex-wrap: wrap;
}
.post-header__info__category > a, .post-header__info__tag > a {
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
}
@media (min-width: 62em) {
  .post-header__info__category > a, .post-header__info__tag > a {
    font-size: 0.875rem;
  }
}
.post-header__info__category > a {
  border-bottom: 2px solid #00b458;
}
.post-header__info__tag > a {
  padding: 0.05em 0.5em;
  color: #fff;
  background-color: #00b458;
}
.post-header__info__datetime {
  font-family: "Lato", "Hiragino Kaku Gothic Pro", -apple-system, BlinkMacSystemFont, "Hiragino Sans", "Hiragino Kaku Gothic ProN", CustomYuGothicM, Meiryo, sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
}
@media (min-width: 62em) {
  .post-header__info__datetime {
    font-size: 1rem;
  }
}
.post-header__thumbnail {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 56.25%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  margin-top: 1rem;
}
.post-header__thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .post-header__thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}

.post-pager {
  position: relative;
}
.post-pager::before {
  content: "";
  display: block;
  padding-top: 4rem;
}
.post-pager > a {
  top: 2.5%;
  font-size: 0.75rem;
  font-weight: bold;
  position: absolute;
  display: block;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transition: 0.4s ease;
  text-align: center;
}
@media (min-width: 62em) {
  .post-pager > a {
    font-size: 0.875rem;
  }
}
.post-pager > a:hover {
  opacity: 0.5;
}
.post-pager .link_before, .post-pager .link_next, .post-pager__prev, .post-pager__next {
  flex: 0 0 calc(34% - 5px);
  width: 100%;
  max-width: calc(34% - 5px);
  background-color: #f1f1f1;
}
.post-pager .link_before, .post-pager__prev {
  padding-left: 3em;
  left: 0;
  margin-left: 0;
  margin-right: auto;
}
.post-pager .link_before::before, .post-pager__prev::before {
  position: absolute;
  top: 50%;
  width: 0.375rem;
  height: 0.375rem;
  content: "";
  border-top: 2px solid #878888;
  transform: translate(0, -50%) rotate(-45deg);
  border-left: 2px solid #878888;
  left: 1em;
  content: "";
}
.post-pager .link_next, .post-pager__next {
  right: 0;
  margin-left: auto;
  margin-right: 0;
}
.post-pager .link_next::before, .post-pager__next::before {
  position: absolute;
  top: 50%;
  width: 0.375rem;
  height: 0.375rem;
  content: "";
  border-top: 2px solid #878888;
  transform: translate(0, -50%) rotate(45deg);
  border-right: 2px solid #878888;
  right: 1em;
  content: "";
}
.post-pager__index {
  flex: 0 0 30%;
  width: 100%;
  max-width: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0;
  padding-left: 0;
  color: #fff;
  background-color: #00b458;
}
.post-pager__index:hover {
  color: #fff;
}
.post-pager__index::after {
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  content: "";
  background: no-repeat center;
  background-image: svg-load("../images/_inline/squares.svg", fill=#fff);
  background-size: contain;
}
@media (min-width: 48em) {
  .post-pager__index::after {
    margin-left: 0.25em;
  }
}
.post-pager__index span {
  display: none;
}
@media (min-width: 48em) {
  .post-pager__index span {
    display: inline-block;
  }
}

/**
 * コンテンツグリッド
 */
.contents-main,
.contents-side {
  position: relative;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.contents-main {
  max-width: 960px;
}

.contents-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  padding-top: 30px;
}
@media (min-width: 36em) {
  .contents-row {
    max-width: 540px;
  }
}
@media (min-width: 48em) {
  .contents-row {
    max-width: 720px;
  }
}
@media (min-width: 62em) {
  .contents-row {
    max-width: 960px;
  }
}
@media (min-width: 75em) {
  .contents-row {
    max-width: 1140px;
  }
}
@media (min-width: 62em) {
  .contents-row .contents-main {
    flex: 0 0 69.8113207547%;
    width: 100%;
    max-width: 69.8113207547%;
  }
}
@media (min-width: 62em) {
  .contents-row .contents-side {
    flex: 0 0 24.5283018868%;
    width: 100%;
    max-width: 24.5283018868%;
  }
}
@media (min-width: 62em) {
  .contents-row .contents-side__body {
    position: sticky;
  }
}

.contents-side-list__heading {
  font-size: 0.875rem;
  padding-left: 1em;
  color: #00b458;
  border-left: 3px solid #00b458;
}
.contents-side-list__links > a, .contents-side-list__select {
  position: relative;
}
.contents-side-list__links > a::before, .contents-side-list__select::before {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 2px solid transparent;
  border-left-color: #00b458;
  margin-right: -1px;
  position: absolute;
  top: 1em;
  left: 0;
  content: "";
}
.contents-side-list__links > a {
  font-size: 0.75rem;
  position: relative;
  display: block;
  padding: 0.5em;
  padding-left: 1em;
  color: #333;
}
.contents-side-list__links > a:hover {
  color: #00b458;
}
.contents-side-list__select {
  width: 100%;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-width: 60%;
  height: 50px;
  color: #8c8c8c;
  padding-left: 1em;
  padding-right: 1em;
  border: 1px solid rgba(135, 136, 136, 0.2);
  border-radius: 0;
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, #8c8c8c 50%), linear-gradient(135deg, #8c8c8c 50%, transparent 50%);
  background-position: calc(100% - 20px) 25px, calc(100% - 15px) 25px, calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.contents-side-list__select:focus {
  outline: none;
}

@media (min-width: 62em) {
  [data-sticky-item] {
    top: 110px;
    transition-property: top, bottom;
    transition-duration: 0.8s;
  }
}

/** Components */
.address-list {
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.address-list dt,
.address-list dd {
  display: block;
  width: 100%;
}
@media (min-width: 62em) {
  .address-list dt {
    flex: 0 0 6rem;
    width: 100%;
    max-width: 6rem;
  }
}
@media (min-width: 62em) {
  .address-list dd {
    flex: 0 0 calc(100% - 6.5rem);
    width: 100%;
    max-width: calc(100% - 6.5rem);
  }
}

.address-attention {
  color: #878888;
}

.contact-info {
  font-size: 0.875rem;
}
.contact-info__heading {
  font-size: 0.875rem;
}
.contact-info__list {
  padding-left: 0;
  list-style: none;
}
.contact-info__list > li {
  margin-bottom: 0.25em;
  padding-left: 1.5em;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1rem;
}
.contact-info__list > li.is-phone {
  background-image: svg-load("../images/_inline/phone.svg");
}
.contact-info__list > li.is-smartphone {
  background-image: svg-load("../images/_inline/smartphone.svg");
}
.contact-info__list > li.is-mail {
  background-image: svg-load("../images/_inline/mail.svg");
}

.badge {
  font-size: 0.6875rem;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
  background-image: svg-load("../images/_inline/badge.svg", fill=#fc6a23);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 62em) {
  .badge {
    font-size: 0.875rem;
    width: 60px;
    height: 60px;
  }
}

.label-chip {
  font-size: 0.75rem;
  background-color: #00b458;
  color: #fff;
  padding: 0.05em 0.5em;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.25em;
  margin-left: 0.25em;
}

.block, .page-header {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.block--sm {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.block--lg {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.block--top-0 {
  padding-top: 0;
}
.block--bottom-0 {
  padding-bottom: 0;
}
@media (min-width: 62em) {
  .block--gutter-lg {
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
}
@media (min-width: 75em) {
  .block--gutter-xl {
    padding-right: 5%;
    padding-left: 5%;
  }
}

.nallow-block {
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}

.gutter {
  padding-left: 0.5em;
  padding-right: 0.5em;
  display: inline-block;
}

.breadcrumbs {
  font-size: 0.625rem;
  display: block;
  padding: 1rem 0;
  border-top: 1px solid #f1f1f1;
}
@media (min-width: 48em) {
  .breadcrumbs {
    font-size: 0.75rem;
  }
}
@media (min-width: 75em) {
  .breadcrumbs {
    font-size: 0.75rem;
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
}
.breadcrumbs a, .breadcrumbs span:not(.sr-only) {
  position: relative;
  display: inline-block;
  padding: 0.25em 1em;
}
.breadcrumbs a:not(:last-child)::after, .breadcrumbs span:not(.sr-only):not(:last-child)::after {
  position: absolute;
  top: 50%;
  width: 0.375rem;
  height: 0.375rem;
  content: "";
  border-top: 2px solid #878888;
  transform: translate(0, -50%) rotate(45deg);
  border-right: 2px solid #878888;
  right: -0.25em;
  content: "";
}
.breadcrumbs__home::before {
  position: relative;
  display: inline-block;
  content: "";
  background: no-repeat center;
  background-size: contain;
  background-image: svg-load("../images/_inline/home.svg", fill=#00b458);
  width: 1em;
  height: 1em;
  line-height: 1;
  top: 0.05em;
  color: #00b458;
}
.breadcrumbs__home:hover {
  opacity: 0.8;
}

/**
 * Buttons
 */
.button {
  font-weight: 500;
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 10em;
  padding: 0.25em 1.5em;
  transition: 0.4s ease;
  text-align: center;
  border-radius: 4rem;
}
@media (min-width: 62em) {
  .button {
    min-width: auto;
  }
}
@media (min-width: 75em) {
  .button {
    padding-right: 2em;
    padding-left: 2em;
  }
}
.button > span {
  pointer-events: none;
}

.button {
  overflow: hidden;
  box-sizing: border-box;
  color: #fff;
  border: 0 solid transparent;
  border-radius: 80px;
  background: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
}
.button:focus {
  color: #fff;
}
.button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.6s ease;
  transform: scaleY(0);
  transform-origin: center top;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.25);
  will-change: transform, opacity;
}
.button:hover {
  text-decoration: none;
  color: #fff;
}
.button:hover::before {
  transform: scaleY(1);
  transform-origin: center bottom;
  opacity: 0;
}
.button.is-warning {
  background: #fc6a23;
}
.button.is-warning::after {
  position: relative;
  display: inline-block;
  width: 0.86em;
  height: 0.86em;
  margin-left: 0.5em;
  content: "";
  background-image: svg-load("../images/_inline/external.svg", fill=#fff);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.button.is-lg {
  padding: 0.8em 1.75em;
  max-width: 100%;
}
@media (min-width: 62em) {
  .button.is-lg {
    min-width: 20em;
    padding: 1em 2em;
  }
}
.button.is-adaptive {
  min-width: 50%;
}
@media (min-width: 62em) {
  .button.is-adaptive {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    min-width: 32.5rem;
  }
}
.button.is-adaptive-sm {
  min-width: 40%;
}
@media (min-width: 62em) {
  .button.is-adaptive-sm {
    min-width: 23.75rem;
  }
}
.button.is-inline {
  min-width: auto;
}
.button.is-long {
  min-width: 50%;
}
.button.is-sm {
  font-size: 80%;
}
.button.is-block {
  display: block;
}
.button.is-outline {
  padding: calc(0.25em - 2px) 1.5em;
  color: #00b458;
  border: 2px solid #00b458;
  background-color: transparent;
  background-image: none;
}
.button.is-outline:hover {
  color: #fff;
  background-color: #00b458;
}
.button.is-no-round {
  border-radius: 0;
}

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button-wrapper.is-column .button {
  display: block;
  min-width: 60%;
  margin: 0.5em;
}
@media (min-width: 62em) {
  .button-wrapper.is-column .button {
    min-width: 30%;
  }
}
.button-wrapper.is-column-1-3 .button {
  display: block;
  min-width: 60%;
  margin: 0.5em;
}
@media (min-width: 62em) {
  .button-wrapper.is-column-1-3 .button {
    flex: 0 0 calc(33.3333333333% - 1rem);
    width: 100%;
    max-width: calc(33.3333333333% - 1rem);
    min-width: auto;
  }
}

.link {
  display: inline-block;
  padding: 0.25em 0.5em;
}

.link-arrow {
  position: relative;
  display: inline-block;
  padding: 0.25em 1em 0.25em 2em;
  color: #333;
}
.link-arrow:hover {
  text-decoration: underline;
}
.link-arrow::before {
  position: absolute;
  top: 0.85em;
  left: 0.75em;
  display: block;
  width: 0.5em;
  height: 0.5em;
  content: "";
  transform: rotate(45deg);
  border-top: 2px solid #00b458;
  border-right: 2px solid #00b458;
}
.link-arrow.is-block {
  display: block;
}
.link-arrow.text-color-main {
  font-weight: bold;
  color: #00b458;
}

.card {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
}
@media (min-width: 48em) {
  .card {
    padding: 2.5%;
  }
}
.card.is-shadow {
  box-shadow: 0 1px 4px rgba(51, 51, 51, 0.1), 0 3px 6px rgba(51, 51, 51, 0.08), 0 6px 32px rgba(51, 51, 51, 0.04);
}
.card.is-ticket {
  overflow: hidden;
  border: 3px solid #00b458;
}
.card.is-ticket::before {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 0.5rem solid transparent;
  border-left-color: #00b458;
  margin-right: -0.25rem;
  position: absolute;
  top: -1rem;
  right: -0.8125rem;
  display: block;
  content: "";
  transform: rotate(-45deg);
}
.card.is-timeline {
  overflow: visible;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 1.5rem 1rem 1rem;
}
@media (min-width: 48em) {
  .card.is-timeline {
    padding: 2.5%;
  }
}
@media (min-width: 62em) {
  .card.is-timeline {
    padding: 5%;
  }
}
.card.is-timeline::before, .card.is-timeline::after {
  position: absolute;
  display: block;
  content: "";
  pointer-events: none;
  background-color: #00b458;
}
.card.is-timeline::before {
  top: 0.5rem;
  left: 0.5rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.card.is-timeline::after {
  top: 1rem;
  right: calc(100% - 0.75rem);
  width: 4rem;
  height: 1px;
  transform: rotate(-30deg);
  transform-origin: right center;
}
@media (max-width: 61.99em) {
  .card.is-timeline::after {
    display: none;
  }
}
.card.is-box {
  padding: 1rem;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0.125rem 0.1875rem rgba(51, 51, 51, 0.05);
}
@media (min-width: 62em) {
  .card.is-box {
    padding: 2.5%;
  }
}
.card.is-box::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 4px;
  margin: 0 auto auto;
  content: "";
  pointer-events: none;
  background-color: #00b458;
  background-image: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
  background-size: 100%;
}
@media (min-width: 48em) {
  .card.is-gutter {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}
@media (min-width: 62em) {
  .card.is-gutter {
    padding: 5%;
  }
}
.card--row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card__content {
  width: 100%;
}
@media (min-width: 48em) {
  .card__content {
    flex: 0 0 66.6666666667%;
    width: 100%;
    max-width: 66.6666666667%;
    padding-right: 2.5%;
  }
}
.card__thumbnail {
  width: 100%;
}
@media (min-width: 48em) {
  .card__thumbnail {
    flex: 0 0 33.3333333333%;
    width: 100%;
    max-width: 33.3333333333%;
    margin-top: 0.5em;
  }
}
.card__thumbnail__pict {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 75%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  margin-bottom: 0;
}
.card__thumbnail__pict > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .card__thumbnail__pict > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.card__heading {
  margin-bottom: 0.5em;
  padding-top: 0.5em;
}
.card--col {
  display: flex;
  flex-direction: column;
}
.card--col__bottom {
  margin-top: auto;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 62em) {
  .card-row .card {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
@media (min-width: 62em) {
  .card-row.is-half .card {
    flex: 0 0 calc(50% - 1rem);
    width: 100%;
    max-width: calc(50% - 1rem);
  }
}
@media (min-width: 62em) {
  .card-row.is-narrow {
    max-width: 80%;
  }
}

.data-list {
  display: flex;
  flex-wrap: wrap;
}
.data-list dt,
.data-list dd {
  font-size: 0.9375rem;
  display: block;
  width: 100%;
  margin-bottom: 0;
  padding-top: 1rem;
}
@media (min-width: 62em) {
  .data-list dt {
    flex: 0 0 10rem;
    width: 100%;
    max-width: 10rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e4e3e3;
  }
}
.data-list dd {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e4e3e3;
}
@media (min-width: 62em) {
  .data-list dd {
    flex: 0 0 calc(100% - (10rem + 1em));
    width: 100%;
    max-width: calc(100% - (10rem + 1em));
  }
}

.checked-list {
  padding-left: 0;
  list-style: none;
}
.checked-list > li {
  display: block;
  padding-bottom: 0.25em;
  padding-left: 2em;
  background: no-repeat left top;
  background-image: svg-load("../images/_inline/inner-checked.svg", fill=#00b458);
  background-position: 0.25em 0.5em;
  background-size: 1em auto;
}

.keyword-list {
  position: relative;
  padding-top: 30px;
  padding-left: 0;
  list-style: none;
  counter-reset: keyword;
}
@media (min-width: 62em) {
  .keyword-list {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.keyword-list__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 3.75rem;
  padding: 1rem;
  counter-increment: keyword;
  background-color: #fff;
}
@media (min-width: 48em) {
  .keyword-list__item {
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }
}
@media (min-width: 62em) {
  .keyword-list__item {
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.keyword-list__item p:last-child {
  margin-bottom: 0;
}
.keyword-list__badge {
  position: absolute;
  top: -1.875rem;
  left: -0.9375rem;
  overflow: hidden;
  width: 4.375rem;
  height: 4.375rem;
  padding-top: 1.25rem;
  color: #fff;
  border-radius: 50%;
  background: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
}
@media (min-width: 48em) {
  .keyword-list__badge {
    top: -1.875rem;
    left: -1.25rem;
    width: 5rem;
    height: 5rem;
  }
}
.keyword-list__badge::before, .keyword-list__badge::after {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  line-height: 1.2;
  display: block;
  text-align: center;
}
.keyword-list__badge::before {
  font-size: 0.625rem;
  content: "KEYWORD";
  text-transform: uppercase;
}
.keyword-list__badge::after {
  font-size: 1.25rem;
  content: counter(keyword, decimal-leading-zero);
}
@media (min-width: 48em) {
  .keyword-list__badge::after {
    font-size: 1.625rem;
  }
}
.keyword-list__heading {
  width: 100%;
  padding-top: 1.875rem;
  text-align: center;
  letter-spacing: 0.25em;
  font-feature-settings: normal;
}
@media (min-width: 62em) {
  .keyword-list__heading {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
    margin-bottom: 0;
    padding-top: 0;
    padding-left: 3.75rem;
    text-align: left;
  }
}
.keyword-list__heading span {
  display: inline-block;
}
.keyword-list__body {
  width: 100%;
}
@media (min-width: 62em) {
  .keyword-list__body {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.sns-list {
  display: block;
  min-height: 44px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}
.sns-list__link {
  font-size: 1.125rem;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 0.5em;
  margin-left: 0.5em;
  transition: 0.4s ease;
  text-align: center;
  color: #333;
  border: 1px solid #333;
  border-radius: 50%;
}
@media (min-width: 62em) {
  .sns-list__link {
    font-size: 1.5rem;
    width: 62px;
    height: 62px;
    border-width: 2px;
  }
}
.sns-list__link::before {
  line-height: 38px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  content: "";
  transition: 0.4s ease;
  background-color: #333;
  -webkit-mask: no-repeat center/contain;
  mask: no-repeat center/contain;
}
@media (min-width: 62em) {
  .sns-list__link::before {
    line-height: 60px;
  }
}
.sns-list__link:hover {
  text-decoration: none;
  color: #ebfff5;
  background-color: #333;
}
.sns-list__link:hover::before {
  background-color: #fff;
}
.sns-list__link--twitter::before {
  -webkit-mask-image: url("../images/_inline/sns-twitter.svg");
  mask-image: url("../images/_inline/sns-twitter.svg");
}
.sns-list__link--x::before {
  -webkit-mask-image: url("../images/_inline/sns-x.svg");
  mask-image: url("../images/_inline/sns-x.svg");
}
.sns-list__link--facebook::before {
  -webkit-mask-image: url("../images/_inline/sns-face-book.svg");
  mask-image: url("../images/_inline/sns-face-book.svg");
}
.sns-list__link--instagram::before {
  -webkit-mask-image: url("../images/_inline/sns-instagram.svg");
  mask-image: url("../images/_inline/sns-instagram.svg");
}
.sns-list__link--youtube::before {
  -webkit-mask-image: url("../images/_inline/sns-youtube.svg");
  mask-image: url("../images/_inline/sns-youtube.svg");
}
.sns-list__link--spotify::before {
  mask-image: url("../images/_inline/sns-spotify.svg");
}

.step-list {
  position: relative;
  padding-left: 0;
  counter-reset: section;
}
.step-list__item {
  position: relative;
  display: block;
  padding-bottom: 1rem;
  counter-increment: section;
}
.step-list__item::after {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 0.15625rem solid transparent;
  border-top-color: #bebebe;
  margin-bottom: -0.078125rem;
  position: absolute;
  right: 0;
  bottom: 0.3125rem;
  left: 0;
  margin: 0.5rem auto auto;
}
@media (min-width: 48em) {
  .step-list__item::after {
    top: calc(64px + 0.5rem);
    right: auto;
    left: calc(32px - 0.3125rem);
  }
}
.step-list__item:last-child::after {
  display: none;
}
.step-list__item__heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (min-width: 48em) {
  .step-list__item__heading {
    justify-content: flex-start;
  }
}
.step-list__item__heading__step {
  position: relative;
  display: block;
}
.step-list__item__heading__step > span:first-child {
  line-height: 1.2;
  position: relative;
  display: block;
  overflow: hidden;
  width: 4rem;
  height: 4rem;
  padding-top: 0.8rem;
  text-align: center;
  border-radius: 50%;
  background-color: #f8ef5b;
}
.step-list__item__heading__step > span:first-child::before {
  font-size: 0.625rem;
  display: block;
  width: 100%;
  content: "STEP";
}
.step-list__item__heading__step > span:first-child::after {
  width: 100%;
  content: counter(section, decimal-leading-zero);
}
.step-list__item__heading__label {
  width: 100%;
  padding-top: 0.25em;
}
@media (min-width: 48em) {
  .step-list__item__heading__label {
    width: calc(100% - 4rem);
    padding-top: 0;
    padding-left: 1rem;
  }
}
@media (min-width: 48em) {
  .step-list__item__body {
    padding-left: calc(4rem + 1rem);
  }
}

/**
* 注意事項リスト
*/
.attentionList {
  padding-left: 0.5em;
  list-style: none;
  counter-reset: number 0;
}
.attentionList li {
  font-size: 0.75rem;
  position: relative;
  display: block;
  margin-bottom: 0.25em;
  padding-left: 2.25em;
  content: counter(number);
  counter-increment: number;
  color: gray;
}
@media (min-width: 62em) {
  .attentionList li {
    font-size: 0.8125rem;
  }
}
.attentionList li::before {
  position: absolute;
  left: 0;
  content: "※" counter(number);
}
.attentionList li[data-count]::before {
  content: "※" attr(data-count);
}
.attentionList--numberLess li {
  padding-left: 1.25em;
}
.attentionList--numberLess li::before {
  content: "※";
}
.attentionList--warning li,
.attentionList--warning li::before {
  font-weight: bold;
  color: red;
}

.dot-list {
  list-style: none;
}
.dot-list > li {
  position: relative;
  line-height: 1.86;
  margin-bottom: 0.5em;
}
.dot-list > li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #00b458;
  border-radius: 50%;
  left: -1.25em;
  top: calc(0.75em - 1px);
}

.circle-slider .glide__slides {
  align-items: stretch;
}
.circle-slider .glide__slide {
  padding-bottom: 30px;
  display: flex;
  height: auto;
  flex-direction: column;
}

.content-circle {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-bottom: 0.5rem;
  text-decoration: none;
  color: #333;
  background-color: #fff;
  text-decoration: none;
  color: #333;
}
.content-circle::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 0;
  content: "";
  transition: box-shadow 0.4s ease;
  pointer-events: none;
}
.content-circle:hover {
  text-decoration: none;
  color: #333;
}
.content-circle:hover h2, .content-circle:hover h3, .content-circle:hover h4, .content-circle:hover p {
  opacity: 0.7;
}
.content-circle:hover::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1), 0 8px 42px rgba(51, 51, 51, 0.08);
}
.content-circle:hover {
  text-decoration: none;
  color: #333;
}
.content-circle h3 {
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: 0.5em 1rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  -webkit-line-clamp: 3;
}
.content-circle p {
  padding: 0 1rem;
  line-height: 1.2;
  flex-grow: 1;
}
.content-circle p > small {
  color: #878888;
}
.content-circle__thumbnail {
  margin-bottom: 0.5em;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 68.0379746835%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.content-circle__thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .content-circle__thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}

/**
 * classes コンテンツカードコンポーネント
 */
.content-classes-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
@media (min-width: 48em) {
  .content-classes-wrapper {
    justify-content: space-between;
  }
}

/**
 * カード （小:トップページなど）
 */
.content-classes {
  text-decoration: none;
  color: #333;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  margin: 0 0.5rem 2rem;
}
.content-classes::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 0;
  content: "";
  transition: 0.4s ease;
  pointer-events: none;
}
.content-classes:hover {
  text-decoration: none;
  color: #333;
}
.content-classes:hover h2, .content-classes:hover h3, .content-classes:hover h4, .content-classes:hover p {
  opacity: 0.7;
}
.content-classes:hover::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1), 0 8px 42px rgba(51, 51, 51, 0.08);
}
@media (min-width: 36em) {
  .content-classes {
    flex: 0 0 calc(50% - 1rem);
    width: 100%;
    max-width: calc(50% - 1rem);
  }
}
@media (min-width: 62em) {
  .content-classes {
    flex: 0 0 calc(33.3333333333% - 1rem);
    width: 100%;
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media (min-width: 75em) {
  .content-classes {
    flex: 0 0 320px;
    width: 100%;
    max-width: 320px;
  }
}
.content-classes__thumbnail {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 50%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.content-classes__thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .content-classes__thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.content-classes__body {
  padding: 0 1rem 0.5rem;
}
.content-classes__time {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  font-weight: 700;
  align-items: center;
  margin-bottom: 0.5em;
}
.content-classes__time__dow {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 22.4px;
  position: relative;
  top: -2.8px;
  display: inline-block;
  width: 22.4px;
  height: 22.4px;
  margin-left: 0.5em;
  text-align: center;
  border-radius: 50%;
  background-color: #b7fd84;
  top: auto;
}
.content-classes__time__schedule {
  font-size: 0.875rem;
  margin-left: auto;
}
.content-classes__heading {
  font-size: 0.875rem;
  font-weight: normal;
}
.content-classes__status {
  font-weight: 700;
  display: block;
  margin-top: auto;
  padding: 0.5em;
  text-align: center;
  background-color: #f1f1f1;
}
.content-classes__status.is-status-active {
  color: #fff;
  background-color: #00b458;
}
.content-classes__status.is-status-fully {
  color: #b3b3b3;
}
.content-classes__status.is-status-few-left {
  color: #fc6a23;
}
.content-classes__status.is-status-report {
  color: #b3b3b3;
}
.content-classes__status.is-status-end {
  color: #878888;
}

/**
 * カード （ワイド: classes一覧ページなど）
 */
.content-classes-wide {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}
.content-classes-wide__link {
  display: block;
  color: #333;
}
.content-classes-wide__link:hover {
  text-decoration: none;
  color: #333;
}
.content-classes-wide__link:hover figure {
  opacity: 0.7;
}
.content-classes-wide__date {
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  position: relative;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
}
@media (min-width: 48em) {
  .content-classes-wide__date::after {
    display: block;
    flex: 1;
    height: 1px;
    margin-left: 1em;
    content: "";
    background-color: #d1d1d1;
  }
}
.content-classes-wide__date__time {
  font-size: 1.5rem;
  margin-right: 0.25rem;
  margin-bottom: 0;
}
.content-classes-wide__date__dow {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 22.4px;
  position: relative;
  top: -2.8px;
  display: inline-block;
  width: 22.4px;
  height: 22.4px;
  margin-left: 0.5em;
  text-align: center;
  border-radius: 50%;
  background-color: #b7fd84;
  position: relative;
  margin-top: 5px;
  margin-right: 0.5rem;
}
.content-classes-wide__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.content-classes-wide__thumbnail {
  position: relative;
  width: 100%;
  padding-left: 25px;
}
@media (min-width: 48em) {
  .content-classes-wide__thumbnail {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}
@media (min-width: 62em) {
  .content-classes-wide__thumbnail {
    flex: 0 0 33.3333333333%;
    width: 100%;
    max-width: 33.3333333333%;
  }
}
.content-classes-wide__thumbnail__label {
  font-family: "Hiragino Kaku Gothic Pro";
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 26px;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 26px;
  min-height: 140px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  -ms-writing-mode: tb-rl;
  background-color: #f1f1f1;
  writing-mode: vertical-rl;
}
.is-status-active .content-classes-wide__thumbnail__label {
  color: #fff;
  background-color: #00b458;
}
.is-status-fully .content-classes-wide__thumbnail__label {
  color: #b3b3b3;
}
.is-status-few-left .content-classes-wide__thumbnail__label {
  color: #fc6a23;
}
.is-status-report .content-classes-wide__thumbnail__label {
  color: #fc6a23;
}
.is-status-report .content-classes-wide__thumbnail__label::before {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  content: "";
  transform: rotate(45deg);
  background-color: #fc6a23;
}
.is-status-end .content-classes-wide__thumbnail__label {
  color: #878888;
}
.content-classes-wide__thumbnail__pict {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 60.1047956139%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  transition: opacity 0.4s;
}
.content-classes-wide__thumbnail__pict > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .content-classes-wide__thumbnail__pict > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.content-classes-wide__content {
  font-size: 0.75rem;
}
@media (min-width: 48em) {
  .content-classes-wide__content {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
    padding-left: 1rem;
  }
}
@media (min-width: 62em) {
  .content-classes-wide__content {
    flex: 0 0 66.6666666667%;
    width: 100%;
    max-width: 66.6666666667%;
    padding-left: 2rem;
  }
}
.content-classes-wide__content__tags {
  margin-bottom: 0.5em;
}
.content-classes-wide__content__tags > span {
  display: inline-block;
  padding-right: 0.25em;
  padding-left: 0.25em;
  border: 1px solid #333;
}
.content-classes-wide__content__location {
  font-weight: bold;
  padding-left: 1.5em;
  color: #8c8c8c;
  background: no-repeat center left;
  background-size: 0.89em;
  background-image: svg-load("../images/_inline/place-sm.svg", fill=#8c8c8c);
}

.content-club-wrapper {
  padding-top: 0;
  padding-bottom: 80px;
}

.content-club {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(135, 136, 136, 0.2);
}
@media (min-width: 62em) {
  .content-club {
    justify-content: space-between;
  }
}
.content-club__thumbnail {
  width: 100%;
  max-width: 200px;
  padding: 0 1rem;
  text-align: center;
}
@media (min-width: 62em) {
  .content-club__thumbnail {
    flex: 0 0 15rem;
    width: 100%;
    max-width: 15rem;
    padding-left: 0;
  }
}
.content-club__thumbnail .button {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}
.content-club__body {
  width: 100%;
}
@media (min-width: 62em) {
  .content-club__body {
    flex: 0 0 calc(100% - 15rem);
    width: 100%;
    max-width: calc(100% - 15rem);
    padding-top: 2.5%;
  }
}
.content-club__body__row {
  font-size: 0.875rem;
}
@media (min-width: 62em) {
  .content-club__body__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 2.5%;
  }
}
.content-club__body__heading {
  order: 1;
  width: 100%;
}
@media (min-width: 62em) {
  .content-club__body__heading {
    width: auto;
  }
}
.content-club__body__excerpt {
  width: 100%;
}
@media (min-width: 62em) {
  .content-club__body__excerpt {
    order: 3;
    padding-bottom: 1rem;
  }
}
.content-club__body__link {
  position: relative;
  display: block;
  margin-bottom: 1em;
  padding: 0.5em;
  text-align: right;
}
@media (min-width: 62em) {
  .content-club__body__link {
    order: 2;
    margin-bottom: 0.25em;
  }
}
.content-club__body__link::before {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 2.5px solid transparent;
  border-left-color: #00b458;
  margin-right: -1.25px;
  display: inline-block;
  margin-right: 0.25em;
}

.content-news-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  margin-right: -1rem;
  margin-left: -1rem;
}

.content-news {
  text-decoration: none;
  color: #333;
  flex: 0 0 calc(320px - 2rem);
  width: 100%;
  max-width: calc(320px - 2rem);
  position: relative;
  margin: 0 1rem 2rem;
  padding: 1rem;
  border: 1px solid #e4e3e3;
}
.content-news::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 0;
  content: "";
  transition: box-shadow 0.4s ease;
  pointer-events: none;
}
.content-news:hover {
  text-decoration: none;
  color: #333;
}
.content-news:hover h2, .content-news:hover h3, .content-news:hover h4, .content-news:hover p {
  opacity: 0.7;
}
.content-news:hover::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1), 0 8px 42px rgba(51, 51, 51, 0.08);
}
@media (min-width: 36em) {
  .content-news {
    flex: 0 0 calc(50% - 2rem);
    width: 100%;
    max-width: calc(50% - 2rem);
  }
}
@media (min-width: 62em) {
  .content-news {
    flex: 0 0 calc(25% - 2rem);
    width: 100%;
    max-width: calc(25% - 2rem);
  }
}
.content-news .badge {
  z-index: 10;
  top: -14px;
  left: -14px;
}
.content-news__time {
  font-size: 0.75rem;
  font-weight: 700;
  font-style: italic;
  color: #878888;
}
.content-news__heading {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  -webkit-line-clamp: 3;
  font-size: 0.875rem;
  font-weight: normal;
}
.content-news__tags {
  display: block;
  overflow: hidden;
  padding-top: 0.5em;
}
.content-news__tags > span {
  display: inline-block;
  padding: 0.125em 0.5em 0.1em;
  color: #fff;
  background-color: #00b458;
  font-size: 0.75rem;
  margin-bottom: 0.25em;
}

.category-link {
  display: block;
  margin-bottom: 1em;
  text-align: center;
}
@media (min-width: 62em) {
  .category-link {
    text-align: right;
  }
}
.category-link a {
  font-size: 0.875rem;
  display: inline-block;
  padding: 0.125em 0.75em;
  transition: 0.4s ease;
  color: #333;
  background-color: #f1f1f1;
}
.category-link a.is-current, .category-link a:hover, .category-link a:active {
  color: #fff;
  background-color: #00b458;
}

.content-report-wrapper {
  display: block;
}

.content-report {
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #e4e3e3;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  color: #333;
}
.content-report::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 0;
  content: "";
  transition: box-shadow 0.4s ease;
  pointer-events: none;
}
.content-report:hover {
  text-decoration: none;
  color: #333;
}
.content-report:hover h2, .content-report:hover h3, .content-report:hover h4, .content-report:hover p {
  opacity: 0.7;
}
.content-report:hover::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1), 0 8px 42px rgba(51, 51, 51, 0.08);
}
@media (min-width: 62em) {
  .content-report {
    max-width: 80%;
    padding: 2rem;
  }
  .content-report:nth-of-type(2) {
    margin-left: 10%;
  }
  .content-report:nth-of-type(3) {
    margin-left: 20%;
  }
}
.content-report::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: 0 auto;
  border-width: 15px 15px 0 0;
  border-style: solid;
  border-color: #00b458 transparent transparent transparent;
}
.content-report__left {
  flex: 0 0 7.65625rem;
  width: 100%;
  max-width: 7.65625rem;
}
@media (min-width: 62em) {
  .content-report__left {
    flex: 0 0 11.5rem;
    width: 100%;
    max-width: 11.5rem;
  }
}
.content-report__thumbnail {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 59.7826086957%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.content-report__thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .content-report__thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.content-report__body {
  position: relative;
  padding-left: 1rem;
  flex: 0 0 calc(100% - 7.65625rem);
  width: 100%;
  max-width: calc(100% - 7.65625rem);
}
@media (min-width: 62em) {
  .content-report__body {
    flex: 0 0 calc(100% - 11.5rem);
    width: 100%;
    max-width: calc(100% - 11.5rem);
  }
}
.content-report__heading {
  margin-bottom: 0.25em;
}
.content-report__expert {
  font-size: 0.875rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  -webkit-line-clamp: 2;
}

/**
 * Form
 */
.form input,
.form select,
.form textarea,
.form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form select,
.form input,
.form textarea {
  font-size: 1rem;
  position: relative;
  margin-bottom: 0.5rem;
  padding: 0.75em 1em;
  color: #666666;
  border: 0;
  border-radius: 0.3125rem;
  background-color: #f1f1f1;
}
.form.is-long select,
.form.is-long input,
.form.is-long textarea {
  display: block;
  width: 100%;
}
p.button .form input {
  margin-bottom: auto;
  padding: 0 1em;
}
.form select:not([multiple]) {
  padding-right: 3em;
  background-color: #f1f1f1;
  background-image: linear-gradient(45deg, transparent 50%, #8c8c8c 50%), linear-gradient(135deg, #8c8c8c 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 25px, calc(100% - 15px) 25px, calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
}
.form select[multiple] {
  padding-right: 0;
  padding-left: 0;
}
.form option {
  padding: 0.25em 1em;
}
.form option:checked {
  background-color: #bebebe;
}
.form input[type=file] {
  font-size: 0.875rem;
  position: relative;
  z-index: 2;
}
.form input[type=text],
.form input[type=password],
.form input[type=file],
.form select,
.form textarea {
  transition: 0.2s;
  border: 2px solid transparent;
  outline: none;
}
.form input[type=text]:focus,
.form input[type=password]:focus,
.form input[type=file]:focus,
.form select:focus,
.form textarea:focus {
  border-color: #00b458;
  box-shadow: 0 2px 3px rgba(51, 51, 51, 0.1), 0 3px 6px rgba(51, 51, 51, 0.08), 0 6px 32px rgba(51, 51, 51, 0.04);
}
.form .is-checkbox input[type=checkbox],
.form .is-checkbox input[type=radio],
.form .is-radio input[type=checkbox],
.form .is-radio input[type=radio] {
  display: none;
}
.form .is-checkbox input[type=checkbox]:disabled + label,
.form .is-checkbox input[type=radio]:disabled + label,
.form .is-radio input[type=checkbox]:disabled + label,
.form .is-radio input[type=radio]:disabled + label {
  opacity: 0.4 !important;
}
.form .is-checkbox input[type=checkbox] + label,
.form .is-radio input[type=checkbox] + label {
  background-image: svg-load("../images/_inline/checkbox-unchecked.svg", fill=#00b458);
}
.form .is-checkbox input[type=checkbox]:checked + label,
.form .is-radio input[type=checkbox]:checked + label {
  background-image: svg-load("../images/_inline/checkbox-checked.svg", fill=#00b458);
}
.form .is-checkbox input[type=radio] + label,
.form .is-radio input[type=radio] + label {
  background-image: svg-load("../images/_inline/radio-unchecked.svg", fill=#00b458);
}
.form .is-checkbox input[type=radio]:checked + label,
.form .is-radio input[type=radio]:checked + label {
  background-image: svg-load("../images/_inline/radio-checked.svg", fill=#00b458);
}
.form .is-checkbox label,
.form .is-radio label {
  position: relative;
  display: inline-block;
  padding: 0.1875rem 0.1875rem 0.1875rem 1.25rem;
  cursor: pointer;
  background: no-repeat left center;
  background-size: 1em;
}

.form-required {
  font-size: 0.6875rem;
  display: inline-block;
  margin-right: 0.25em;
  margin-left: 0.25em;
  padding: 0.075em 0.5em 0.05em;
  color: #fff;
  background-color: #ee102a;
}

.error {
  font-size: 0.75rem;
  font-weight: bold;
  color: #ee102a;
}
.error::before {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 0.175em solid transparent;
  border-bottom-color: #ee102a;
  margin-top: -0.0875em;
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 0.25em;
  content: "";
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 62em) {
  .form-row.is-double .form-col {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}
@media (min-width: 62em) {
  .form-row.is-triple .form-col {
    flex: 0 0 33.3333333333%;
    width: 100%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 62em) {
  .form-row.is-triple-2by3 .form-col {
    flex: 0 0 66.6666666667%;
    width: 100%;
    max-width: 66.6666666667%;
  }
}
.form-row.is-inline input, .form-row.is-inline select {
  width: auto;
}

.form-col {
  width: 100%;
  padding-right: 0.25em;
  padding-left: 0.25em;
}
.is-radio .form-col, .is-checkbox .form-col {
  width: auto;
  min-width: 6em;
  padding: 0.5em;
}
@media (min-width: 62em) {
  .is-radio .form-col, .is-checkbox .form-col {
    min-width: 6em;
  }
}

.form-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}
.form-list dt,
.form-list dd {
  width: 100%;
  padding: 0 0.25em;
}
.form-list dt {
  font-size: 0.875rem;
}
@media (min-width: 62em) {
  .form-list dt {
    flex: 0 0 14em;
    width: 100%;
    max-width: 14em;
    padding-top: 1em;
    text-align: right;
  }
}
@media (min-width: 62em) {
  .form-list dd {
    flex: 0 0 calc(100% - 14em);
    width: 100%;
    max-width: calc(100% - 14em);
    padding-left: 1em;
  }
}

.credit-card-lead {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
}
.credit-card-lead__col {
  padding: 0.5rem 1rem;
}

.donate-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.donate-form__yen {
  display: inline-block;
  padding-right: 0.25em;
  padding-left: 0.25em;
}
.donate-form__col {
  padding: 0.25em 0.5em;
}
.donate-form__col input {
  margin-bottom: 0;
}

.go-top {
  position: fixed;
  z-index: 980;
  right: 2.5%;
  bottom: 7%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  transition: background-color 0.4s ease, box-shadow 0.4s ease;
  opacity: 0;
  border: none;
  border-radius: 50%;
  background-color: #00b458;
}
@media (min-width: 62em) {
  .go-top {
    width: 60px;
    height: 60px;
  }
}
.go-top:active, .go-top:focus {
  outline: none;
}
.go-top:hover {
  background-color: #00ce64;
  box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1), 0 8px 42px rgba(51, 51, 51, 0.08);
}
.go-top > svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 46%;
  height: 46%;
}
.go-top__arrow {
  fill: #fff;
}

.container, .breadcrumbs__container {
  position: relative;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
}
@media (min-width: 36em) {
  .container, .breadcrumbs__container {
    max-width: 540px;
  }
}
@media (min-width: 48em) {
  .container, .breadcrumbs__container {
    max-width: 720px;
  }
}
@media (min-width: 62em) {
  .container, .breadcrumbs__container {
    max-width: 960px;
  }
}
@media (min-width: 75em) {
  .container, .breadcrumbs__container {
    max-width: 1140px;
  }
}
@media (min-width: 75em) {
  .container.is-narrow, .is-narrow.breadcrumbs__container {
    max-width: 960px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.row--gutter {
  margin-right: -1rem;
  margin-left: -1rem;
}
.row--gutter [class*=col] {
  padding-right: 1rem;
  padding-left: 1rem;
}
.row--center {
  justify-content: center;
}
.row--v-center {
  align-items: center;
}
.row--center-all {
  align-items: center;
  justify-content: center;
}
.row--pb30 {
  padding-bottom: 2rem;
}

.col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}
.col--1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.col--2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.col--3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col--4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.col--5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.col--6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col--7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.col--8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.col--9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col--10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.col--11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.col--12 {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 48em) {
  .col--md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
}
@media (min-width: 48em) {
  .col--md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
@media (min-width: 48em) {
  .col--md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 48em) {
  .col--md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
}
@media (min-width: 48em) {
  .col--md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
}
@media (min-width: 48em) {
  .col--md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 48em) {
  .col--md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
}
@media (min-width: 48em) {
  .col--md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
@media (min-width: 48em) {
  .col--md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 48em) {
  .col--md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
}
@media (min-width: 48em) {
  .col--md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
}
@media (min-width: 48em) {
  .col--md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 62em) {
  .col--lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
}
@media (min-width: 62em) {
  .col--lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
@media (min-width: 62em) {
  .col--lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 62em) {
  .col--lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
}
@media (min-width: 62em) {
  .col--lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
}
@media (min-width: 62em) {
  .col--lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 62em) {
  .col--lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
}
@media (min-width: 62em) {
  .col--lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
@media (min-width: 62em) {
  .col--lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 62em) {
  .col--lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
}
@media (min-width: 62em) {
  .col--lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
}
@media (min-width: 62em) {
  .col--lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col--sm-pd0 {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 48em) {
  .col--sm-pd0 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.offset--0 {
  margin-left: 0;
}
.offset--1 {
  margin-left: 8.33333333%;
}
.offset--2 {
  margin-left: 16.66666667%;
}
.offset--3 {
  margin-left: 25%;
}
.offset--4 {
  margin-left: 33.33333333%;
}
.offset--5 {
  margin-left: 41.66666667%;
}
.offset--6 {
  margin-left: 50%;
}
.offset--7 {
  margin-left: 58.33333333%;
}
.offset--8 {
  margin-left: 66.66666667%;
}
.offset--9 {
  margin-left: 75%;
}
.offset--10 {
  margin-left: 83.33333333%;
}
.offset--11 {
  margin-left: 91.66666667%;
}
.offset--12 {
  margin-left: 100%;
}
@media (min-width: 48em) {
  .offset--md-0 {
    margin-left: 0;
  }
}
@media (min-width: 48em) {
  .offset--md-1 {
    margin-left: 8.33333333%;
  }
}
@media (min-width: 48em) {
  .offset--md-2 {
    margin-left: 16.66666667%;
  }
}
@media (min-width: 48em) {
  .offset--md-3 {
    margin-left: 25%;
  }
}
@media (min-width: 48em) {
  .offset--md-4 {
    margin-left: 33.33333333%;
  }
}
@media (min-width: 48em) {
  .offset--md-5 {
    margin-left: 41.66666667%;
  }
}
@media (min-width: 48em) {
  .offset--md-6 {
    margin-left: 50%;
  }
}
@media (min-width: 48em) {
  .offset--md-7 {
    margin-left: 58.33333333%;
  }
}
@media (min-width: 48em) {
  .offset--md-8 {
    margin-left: 66.66666667%;
  }
}
@media (min-width: 48em) {
  .offset--md-9 {
    margin-left: 75%;
  }
}
@media (min-width: 48em) {
  .offset--md-10 {
    margin-left: 83.33333333%;
  }
}
@media (min-width: 48em) {
  .offset--md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 48em) {
  .offset--md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 62em) {
  .offset--lg-0 {
    margin-left: 0;
  }
}
@media (min-width: 62em) {
  .offset--lg-1 {
    margin-left: 8.33333333%;
  }
}
@media (min-width: 62em) {
  .offset--lg-2 {
    margin-left: 16.66666667%;
  }
}
@media (min-width: 62em) {
  .offset--lg-3 {
    margin-left: 25%;
  }
}
@media (min-width: 62em) {
  .offset--lg-4 {
    margin-left: 33.33333333%;
  }
}
@media (min-width: 62em) {
  .offset--lg-5 {
    margin-left: 41.66666667%;
  }
}
@media (min-width: 62em) {
  .offset--lg-6 {
    margin-left: 50%;
  }
}
@media (min-width: 62em) {
  .offset--lg-7 {
    margin-left: 58.33333333%;
  }
}
@media (min-width: 62em) {
  .offset--lg-8 {
    margin-left: 66.66666667%;
  }
}
@media (min-width: 62em) {
  .offset--lg-9 {
    margin-left: 75%;
  }
}
@media (min-width: 62em) {
  .offset--lg-10 {
    margin-left: 83.33333333%;
  }
}
@media (min-width: 62em) {
  .offset--lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 62em) {
  .offset--lg-12 {
    margin-left: 100%;
  }
}

.section-heading {
  position: relative;
}
@media (min-width: 62em) {
  .section-heading {
    display: flex;
    align-items: flex-start;
  }
}
.section-heading > h2, .section-heading > h3 {
  position: relative;
  padding-bottom: 0.85em;
  letter-spacing: 0.08em;
}
@media (min-width: 62em) {
  .section-heading > h2, .section-heading > h3 {
    margin-right: 1em;
  }
}
.section-heading > h2 {
  font-size: 1.875rem;
}
.section-heading > h2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1.25em;
  height: 5px;
  content: "";
  background-image: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
  left: 0;
  margin-left: 0;
}
@media (min-width: 62em) {
  .section-heading > p {
    padding-top: 1em;
  }
}

.center-heading {
  position: relative;
  display: block;
  margin-bottom: 1em;
  padding-bottom: 0.75em;
  text-align: center;
}
.center-heading::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1.25em;
  height: 5px;
  content: "";
  background-image: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
  left: 0;
  margin-right: auto;
  margin-left: auto;
}
.center-heading a {
  color: #333;
}

.page-header .center-heading {
  font-size: 1.25rem;
}

.page-thumbnail-header {
  position: relative;
  overflow: hidden;
  padding-top: 10rem;
}
@media (min-width: 48em) {
  .page-thumbnail-header {
    padding-top: 13.75rem;
  }
}
@media (min-width: 62em) {
  .page-thumbnail-header {
    padding-top: 17.5rem;
  }
}
.page-thumbnail-header__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 2rem - 10%);
  height: 100%;
}
@media (min-width: 36em) {
  .page-thumbnail-header__bg {
    width: calc((100% - 540px) / 2 + 540px - 7%);
  }
}
@media (min-width: 48em) {
  .page-thumbnail-header__bg {
    width: calc((100% - 720px) / 2 + 720px - 7%);
  }
}
@media (min-width: 62em) {
  .page-thumbnail-header__bg {
    width: calc((100% - 960px) / 2 + 960px - 7%);
  }
}
@media (min-width: 75em) {
  .page-thumbnail-header__bg {
    width: calc((100% - 1140px) / 2 + 1140px - 7%);
  }
}
.page-thumbnail-header__bg > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.page-thumbnail-header__heading {
  position: absolute;
  bottom: 1em;
  margin-bottom: 0;
}
@media (min-width: 62em) {
  .page-thumbnail-header__heading {
    bottom: 1.5em;
  }
}
.page-thumbnail-header__heading > span {
  position: relative;
  display: inline-block;
  padding: 0.5em 0.15em calc(0.5em + 5px);
  background-color: #fff;
}
@media (min-width: 62em) {
  .page-thumbnail-header__heading > span {
    font-size: 120%;
  }
}
.page-thumbnail-header__heading > span::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  margin: auto auto 0;
  content: "";
  background: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
}
@media (min-width: 62em) {
  .page-thumbnail-header__heading > span::before {
    height: 8px;
  }
}
.page-thumbnail-header > .container, .page-thumbnail-header > .breadcrumbs__container {
  position: relative;
}

.line-arrow-heading {
  font-style: italic;
  position: relative;
  display: block;
  margin-bottom: 30px;
  text-align: center;
}
.line-arrow-heading > span {
  position: relative;
  display: inline-block;
}
.line-arrow-heading > span::before, .line-arrow-heading > span::after {
  position: absolute;
  content: "";
}
.line-arrow-heading > span::before {
  top: 100%;
  width: 100%;
  height: 4px;
  background-color: #00b458;
}
.line-arrow-heading > span::after {
  top: 100%;
  right: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: 0 auto;
  border-width: 18px 14px 0 0;
  border-style: solid;
  border-color: #00b458 transparent transparent transparent;
}

.border-heading {
  position: relative;
  margin-bottom: 0.25em;
  padding-left: 1em;
}
.border-heading::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  height: calc(100% - 0.5em);
  margin: auto auto auto 0;
  content: "";
  background-color: #00b458;
}

.icon-heading {
  position: relative;
  margin-bottom: 0.25em;
  padding-left: 1.75em;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1.25em;
}
.icon-heading.is-school {
  background-image: svg-load("../images/_inline/school.svg");
}

.svg-icon-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.svg-icon-heading__icon {
  position: relative;
  width: 1.6875rem;
  height: 1.6875rem;
}
.svg-icon-heading__icon::before {
  display: block;
  padding-top: 100%;
  content: "";
}
.svg-icon-heading__icon > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 48em) {
  .svg-icon-heading__icon {
    width: 3.375rem;
    height: 3.375rem;
  }
}
.svg-icon-heading__icon > svg {
  fill: #00b458 !important;
}
.svg-icon-heading__label {
  flex: 0 0 calc(100% - 3.375rem);
  width: 100%;
  max-width: calc(100% - 3.375rem);
  padding-left: 0.5rem;
}

.checked-heading {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.checked-heading__icon {
  flex: 0 0 2em;
  width: 100%;
  max-width: 2em;
  position: relative;
  top: 0.125em;
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-image: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
}
.checked-heading__icon::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  background: no-repeat center;
  background-image: svg-load("../images/_inline/inner-checked.svg", fill=#fff);
  background-size: 50% auto;
}
.checked-heading__label {
  flex: 0 0 calc(100% - 2em);
  width: 100%;
  max-width: calc(100% - 2em);
  display: inline-block;
  margin-top: 0.25em;
  padding-left: 0.5em;
}

.sub-content {
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
}
@media (min-width: 62em) {
  .sub-content {
    font-size: 0.875rem;
  }
}

.introduction {
  line-height: 1.8;
}
.introduction__heading {
  line-height: 2;
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.25rem;
}
@media (min-width: 62em) {
  .introduction__heading {
    font-size: 1.75rem;
  }
}
@media (min-width: 62em) {
  .introduction__body {
    line-height: 2.5;
  }
}

.login-modal {
  padding-bottom: 1rem;
}
.login-modal__dialog {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 1rem;
  right: 0;
  bottom: auto;
  left: 0;
  width: calc(100% - 2rem);
  max-width: 570px;
  max-height: calc(100% - 2rem);
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1), 0 8px 42px rgba(51, 51, 51, 0.08);
}
@media (min-width: 62em) {
  .login-modal__dialog {
    top: 70px;
    right: 1rem;
    left: auto;
    transform: translate(0);
  }
}
.login-modal__col {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 2rem 1rem;
}
@media (min-width: 62em) {
  .login-modal__col:first-child {
    flex: 0 0 42.8571428571%;
    width: 100%;
    max-width: 42.8571428571%;
  }
}
.login-modal__col:nth-of-type(2) {
  background-color: #f1f1f1;
}
@media (min-width: 62em) {
  .login-modal__col:nth-of-type(2) {
    flex: 0 0 57.1428571429%;
    width: 100%;
    max-width: 57.1428571429%;
  }
}
.login-modal__col__bottom {
  margin-top: auto;
}
.login-modal__col__bottom .button {
  display: block;
  width: 10em;
  margin: 0 auto;
}
.login-modal__close {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
}
@media (min-width: 62em) {
  .login-modal__close {
    display: none;
  }
}
.login-modal__close::before, .login-modal__close::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 2px;
  height: 20px;
  margin-top: -10px;
  margin-left: -1px;
  content: "";
  background-color: #fff;
}
.login-modal__close::before {
  transform: rotate(45deg);
}
.login-modal__close::after {
  transform: rotate(-45deg);
}
.login-modal__close:active {
  opacity: 0.8;
}

.login-form dl {
  margin-bottom: 0.25em;
}
.login-form__input[type] {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 4px;
  background-color: #fff;
}
.login-form__submit {
  margin-top: 2px;
}

.media {
  justify-content: center;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 62em) {
  .media {
    justify-content: space-between;
  }
}
.media.is-sm {
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .media__thumbnail {
    flex: 0 0 10.3125rem;
    width: 100%;
    max-width: 10.3125rem;
  }
}
.media.is-sm .media__thumbnail {
  flex: 0 0 5rem;
  width: 100%;
  max-width: 5rem;
}
.media__thumbnail__pict {
  display: block;
  margin: auto;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.media__thumbnail__pict > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .media__thumbnail__pict > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.media__thumbnail__pict.is-16by9 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 56.25%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.media__thumbnail__pict.is-16by9 > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .media__thumbnail__pict.is-16by9 > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.media__thumbnail__pict.is-4by3 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 75%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
}
.media__thumbnail__pict.is-4by3 > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .media__thumbnail__pict.is-4by3 > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
.media__content {
  width: 100%;
  font-size: 0.875rem;
}
@media (min-width: 62em) {
  .media__content {
    flex: 0 0 calc(100% - 1.5rem - 10.3125rem);
    width: 100%;
    max-width: calc(100% - 1.5rem - 10.3125rem);
  }
}
.media.is-sm .media__content {
  padding-left: 1em;
  flex: 0 0 calc(100% - 1rem - 5rem);
  width: 100%;
  max-width: calc(100% - 1rem - 5rem);
}

.menu-toggle {
  position: relative;
  display: inline-block;
  padding: 0.3125rem 0;
  border: none;
  background-color: transparent;
}
@media (min-width: 62em) {
  .menu-toggle {
    display: none;
  }
}
.menu-toggle:hover, .menu-toggle:active, .menu-toggle:focus {
  outline: none;
}
.menu-toggle:hover {
  cursor: pointer;
}
.menu-toggle:hover span {
  background-color: #00b458;
}
.menu-toggle__body {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 1.375rem;
  height: 0.875rem;
  margin: 0 auto;
  transition: all 0.4s;
}
.menu-toggle span {
  position: absolute;
  right: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 0.125rem;
  transition: transition 0.4s, opacity 0.4s;
  background-color: #00b458;
}
.menu-toggle span:nth-of-type(1) {
  top: 0;
  animation: menu-bar01 0.25s forwards;
}
.menu-toggle span:nth-of-type(2) {
  top: 6px;
  transition: transition 0.05s 0.05s, opacity 0.05s;
  opacity: 1;
}
.menu-toggle span:nth-of-type(3) {
  bottom: 0;
  animation: menu-bar02 0.25s forwards;
}
.js-is-menu-open .menu-toggle span:nth-of-type(1) {
  animation: active-menu-bar01 0.25s forwards;
}
.js-is-menu-open .menu-toggle span:nth-of-type(2) {
  opacity: 0;
}
.js-is-menu-open .menu-toggle span:nth-of-type(3) {
  width: 100%;
  animation: active-menu-bar03 0.25s forwards;
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(6px) rotate(45deg);
  }
  50% {
    transform: translateY(6px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar02 {
  0% {
    transform: translateY(-6px) rotate(-45deg);
  }
  50% {
    transform: translateY(-6px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(6px) rotate(0);
  }
  100% {
    transform: translateY(6px) rotate(45deg);
  }
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-6px) rotate(0);
  }
  100% {
    transform: translateY(-6px) rotate(-45deg);
  }
}
[data-modal] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1100;
  visibility: hidden;
  overflow: auto;
  transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1), visibility 0.4s;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;
}
[data-modal]:hover {
  cursor: pointer;
}
[data-modal] [data-modal-dialog] {
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateY(10px);
}
[data-modal].js-is-modal-visible {
  visibility: visible;
  transition-timing-function: opacity cubic-bezier(0.6, 0.04, 0.98, 0.335), transform cubic-bezier(0.6, 0.04, 0.98, 0.335);
  opacity: 1;
}
[data-modal].js-is-modal-visible [data-modal-dialog] {
  transform: translateY(0);
}

[data-modal-dialog]:hover {
  cursor: initial;
}

.nav-anchor-tab {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: calc(1rem + 30px);
}
.nav-anchor-tab__link {
  flex: 0 0 33.3333333333%;
  width: 100%;
  max-width: 33.3333333333%;
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  position: relative;
  overflow: visible;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  padding: 1.5em 0.25em;
  text-align: center;
  color: #333;
  border: 4px solid #f1f1f1;
}
.nav-anchor-tab__link:not(:last-child) {
  border-right-width: 0;
}
.nav-anchor-tab__link::after {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 0.21875rem solid transparent;
  border-top-color: #00b458;
  margin-bottom: -0.109375rem;
  position: absolute;
  top: calc(100% + 10px);
  content: "";
  transition: transform 0.4s ease;
}
.nav-anchor-tab__link:hover::after {
  transform: translateY(5px);
}
.nav-anchor-tab__link__label {
  font-size: 0.6875rem;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  background: no-repeat top center;
  background-size: 2rem auto;
  padding-top: 2.5rem;
}
@media (min-width: 48em) {
  .nav-anchor-tab__link__label {
    padding-left: calc(2.5rem + 0.5em);
    font-size: 0.875rem;
    padding-top: 0;
    background-position: left center;
  }
}
@media (min-width: 62em) {
  .nav-anchor-tab__link__label {
    font-size: 1rem;
  }
}
[href="#constant"] .nav-anchor-tab__link__label {
  background-image: url(../images/_inline/donation-keep.svg);
}
[href="#single"] .nav-anchor-tab__link__label {
  background-image: url(../images/_inline/donation-one.svg);
}
[href="#books"] .nav-anchor-tab__link__label {
  background-image: url(../images/_inline/donation-books.svg);
}

.page-nav {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.page-nav > a {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto 1em;
  text-align: left;
  font-size: 0.875rem;
}
@media (min-width: 48em) {
  .page-nav > a {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}
@media (min-width: 62em) {
  .page-nav > a {
    flex: 0 0 calc(33.3333333333% - 1rem);
    width: 100%;
    max-width: calc(33.3333333333% - 1rem);
  }
}
.page-nav > a::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: calc(100% - 2rem);
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  content: "";
  background-color: #f1f1f1;
}
.page-nav > a::after {
  position: absolute;
  top: 1.25em;
  right: 1em;
  width: 1em;
  height: 1em;
  content: "";
  background: no-repeat center;
  background-image: svg-load("../images/_inline/link-right.svg");
  background-size: contain;
}
.page-nav > a > span {
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding: 1em 0.125em;
}
.page-nav > a > span::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #00b458;
}

.pagination {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  list-style: none;
}
.pagination li {
  display: block;
  height: 100%;
  margin-right: 1px;
  margin-bottom: 0;
  margin-left: 1px;
}
.pagination li > a, .pagination li > span {
  font-weight: bold;
  line-height: 1;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0.2em;
  border: 2px solid #00b458;
  color: #00b458;
}
.pagination li .dots {
  border-color: transparent;
}
.pagination li .prev, .pagination li .next {
  position: relative;
  border-color: transparent;
}
.pagination li .prev::before, .pagination li .next::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pagination li .prev.is-disable, .pagination li .next.is-disable {
  opacity: 0.5;
}
.pagination li .prev::before {
  left: calc(50% - 3px);
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 6px solid transparent;
  border-right-color: #00b458;
  margin-left: -3px;
}
.pagination li .next::before {
  left: calc(50% + 3px);
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 6px solid transparent;
  border-left-color: #00b458;
  margin-right: -3px;
}
.pagination li > a {
  transition: all 0.2s ease;
}
.pagination li > a:hover {
  text-decoration: none;
  color: #333;
  border-color: #333;
}
.pagination li > a > i {
  position: relative;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
}
.pagination li > a > i::before {
  display: block;
  padding-top: 100%;
  content: "";
}
.pagination li > a > i > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 47.99em) {
  .pagination li.is-rwd {
    display: none;
  }
}
.pagination li > span.current {
  pointer-events: none;
  border-color: #878888;
  color: #878888;
}

.price-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 0;
  list-style: none;
}
@media (min-width: 62em) {
  .price-box {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
}
@media (min-width: 62em) {
  .price-box.is-col-3 .price-box__item {
    flex: 0 0 calc(33.3333333333% - 1rem);
    width: 100%;
    max-width: calc(33.3333333333% - 1rem);
  }
}
.price-box__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto 1rem;
  padding: 1rem 0.25em;
  border: 2px dashed #00b458;
}
.price-box__item__label {
  font-size: 0.875rem;
  display: inline-block;
  margin-right: 1em;
  padding: 0.125em 1em;
  color: #fff;
  background-color: #00b458;
}
.price-box__item__price {
  font-size: 1.75rem;
  font-weight: bold;
}
.price-box__item__price small {
  font-size: 0.875rem;
  margin-left: 0.5em;
}

.price-cardRow {
  margin-bottom: 1.5rem;
}
@media (min-width: 62em) {
  .price-cardRow {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 62em) {
  .price-cardRow > .price-card {
    flex: 0 0 calc(25% - 0.3333em);
    width: 100%;
    max-width: calc(25% - 0.3333em);
  }
}
.price-cardRow > .price-card.is-corporation {
  margin-top: 2rem;
}
@media (min-width: 62em) {
  .price-cardRow > .price-card.is-corporation {
    margin-top: 0;
    margin-left: auto;
  }
}

.price-card {
  display: flex;
  flex-direction: column;
  border: 3px solid #00b458;
  border-radius: 4px;
}
.price-card__heading {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5.5rem;
  color: #fff;
  background-color: #00b458;
}
.price-card__heading__text {
  width: 100%;
  text-align: center;
}
.price-card__body {
  padding: 1rem;
  padding-top: 0.5rem;
}
.price-card__price {
  font-weight: 700;
  text-align: center;
}
.price-card__price__num {
  font-size: 1.75rem;
  color: #00b458;
}
.price-card__price small {
  font-weight: 700;
}
.price-card__list > li {
  font-size: 0.875rem;
}
.price-card__footer {
  margin-top: auto;
  padding-bottom: 1.5rem;
}
.price-card__button {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.6;
  min-width: 80%;
  max-width: calc(100% - 1rem);
  height: 4em;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 62em) {
  .price-card__button {
    min-width: auto !important;
  }
}
@supports (object-fit: cover) {
  .price-card__button {
    height: auto;
    min-height: 4em;
  }
}

.progress-bar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 50px;
  height: 5px;
  animation: progress 2s linear infinite;
  vertical-align: middle;
  border: 1px solid #878888;
  border-radius: 5px;
  background-image: linear-gradient(70deg, #878888 3px, transparent 0), linear-gradient(250deg, #878888 3px, transparent 0);
  background-repeat: repeat-x;
  background-size: 10px 100%;
  opacity: 0.4;
}

@keyframes progress {
  0% {
    background-position: 0, 0;
  }
  100% {
    background-position: 100%, 100%;
  }
}
/**
  * スクロールアニメーションフラグ
  * ex: https://scroll-out.github.io/guide.html#perform-a-fade-in-with-css
  */
.js-so-fade-bottom[data-scroll] {
  transition: opacity 1s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.4s;
}
.js-so-fade-bottom[data-scroll=in] {
  opacity: 1;
  transform: translateY(0);
}
.js-so-fade-bottom[data-scroll=out] {
  opacity: 0;
  transform: translateY(10%);
}

.search-box {
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}
@media (min-width: 36em) {
  .search-box {
    max-width: 540px;
  }
}
@media (min-width: 48em) {
  .search-box {
    max-width: 720px;
  }
}
@media (min-width: 62em) {
  .search-box {
    max-width: 960px;
  }
}
@media (min-width: 75em) {
  .search-box {
    max-width: 1140px;
  }
}
@media (min-width: 48em) {
  .search-box {
    margin-top: 30px;
  }
}
.search-box__trigger {
  font-family: "Hiragino Kaku Gothic Pro";
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  display: block;
  width: 100%;
  padding: 1em 3em;
  border: 1px solid #e4e3e3;
  background-color: #fff;
  background-image: svg-load("../images/_inline/search.svg"), svg-load("../images/_inline/plus.svg");
  background-repeat: no-repeat;
  background-position: 1em center, calc(100% - 1em) center;
  background-size: 1rem, 0.875rem;
  box-shadow: 0 3px 6px rgba(51, 51, 51, 0.08), 0 6px 32px rgba(51, 51, 51, 0.04);
}
@media (min-width: 48em) {
  .search-box__trigger {
    display: block;
    width: auto;
    margin-right: 0;
    margin-left: auto;
    box-shadow: none;
  }
}
.search-box__trigger:hover {
  opacity: 0.7;
}

.search-modal__close {
  font-family: "Hiragino Kaku Gothic Pro";
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 1rem;
  padding-right: 2.5em;
  text-align: left;
  border: none;
  border-bottom: 1px solid #e4e3e3;
  background: #fff no-repeat;
  background-image: svg-load("../images/_inline/minus.svg");
  background-position: calc(100% - 1em) center;
  background-size: 1em;
}
.search-modal__close:hover, .search-modal__close:active {
  opacity: 0.7;
  color: #8c8c8c;
}
.search-modal__close:focus {
  outline: none;
}
.search-modal__dialog {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  width: calc(100% - 2rem);
  max-width: 71.25rem;
  margin: 2rem auto;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(51, 51, 51, 0.08), 0 6px 32px rgba(51, 51, 51, 0.04);
}
.search-modal__body {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}
.search-modal__heading {
  position: relative;
}
.search-modal__heading::before {
  top: 5px;
  display: inline-block;
  width: 3px;
  height: 1em;
  margin-right: 0.5em;
  content: "";
  background-color: #00b458;
}
.search-modal__item {
  width: 100%;
  padding-bottom: 1rem;
}
@media (min-width: 62em) {
  .search-modal__item--word {
    flex: 0 0 66.6666666667%;
    width: 100%;
    max-width: 66.6666666667%;
    padding-right: 2rem;
  }
}
@media (min-width: 62em) {
  .search-modal__item--year {
    flex: 0 0 33.3333333333%;
    width: 100%;
    max-width: 33.3333333333%;
  }
}
.search-modal__form {
  display: flex;
  flex-wrap: wrap;
  border: none;
  border-radius: 0;
  background-color: #f1f1f1;
}
.search-modal__form__input {
  font-size: 1rem;
  position: relative;
  display: block;
  width: calc(100% - 50px);
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
.search-modal__form__submit {
  width: 50px;
  height: 50px;
  border-width: 0;
  background: #d1d1d1 no-repeat center;
  background-image: svg-load("../images/_inline/search.svg");
  background-size: 1.125rem;
}
.search-modal__form__submit:hover {
  background-color: #e0e0e0;
}
.search-modal__select {
  font-size: 0.875rem;
  box-sizing: border-box;
  min-width: 60%;
  height: 50px;
  padding-right: 1em;
  padding-left: 1em;
  color: #8c8c8c;
  border: none;
  border-radius: 0;
  background-color: #f1f1f1;
  background-image: linear-gradient(45deg, transparent 50%, #8c8c8c 50%), linear-gradient(135deg, #8c8c8c 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 25px, calc(100% - 15px) 25px, calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.search-modal__select:focus {
  outline: none;
}
.search-modal select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.section {
  position: relative;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}
.section > .container, .section > .breadcrumbs__container {
  position: relative;
}
.section--sm {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--lg {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.section--first {
  padding-top: 0.9375rem;
}
.section--top-0 {
  padding-top: 0;
}
.section--bottom-0 {
  padding-bottom: 0;
}
.section--bottom-gutter {
  margin-bottom: 30px;
}
.section--bottom-gutter-lg {
  margin-bottom: 50px;
}

.bg-color-main {
  background-color: #00b458;
}

.bg-color-bg-circle {
  background-color: #f1f1f1;
}

.bg-color-bg-circle-half {
  background-color: transparent;
  background-image: linear-gradient(0deg, transparent 50%, #f1f1f1 50%);
}

.bg-picture {
  overflow: hidden;
  color: #fff;
  background-color: #333;
}
.bg-picture__figure {
  width: 100%;
  height: 126%;
  opacity: 0.4;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.bg-picture__figure > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .bg-picture__figure > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}

.sns-share {
  justify-content: flex-end;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  min-height: 2.625rem;
  display: flex;
  flex-wrap: wrap;
}
.sns-share > *:not(:last-child) {
  margin-right: 0.25em;
}

.fb_iframe_widget > span {
  vertical-align: baseline !important;
}

.social-section {
  display: flex;
  flex-wrap: wrap;
  background-color: #ebfff5;
}
.social-section__col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
@media (min-width: 62em) {
  .social-section__col {
    min-height: 245px;
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}
.social-section__col--mail-magazine {
  transition: background-color 0.6s ease;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
}
.social-section__col--mail-magazine::before {
  content: "";
  transition: opacity 0.4s ease;
  opacity: 0;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.social-section__col--mail-magazine::after {
  position: absolute;
  top: 0;
  right: 1em;
  bottom: 0;
  display: block;
  width: 14px;
  height: 14px;
  margin-top: auto;
  margin-bottom: auto;
  content: "";
  transition: transform 0.4s ease;
  transform: rotate(45deg);
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
@media (min-width: 62em) {
  .social-section__col--mail-magazine::after {
    right: 5%;
  }
}
.social-section__col--mail-magazine:hover {
  text-decoration: none;
  color: #fff;
  background-color: rgba(0, 180, 88, 0.8);
}
.social-section__col--mail-magazine:hover::before {
  opacity: 0.2;
}
.social-section__col--mail-magazine:hover::after {
  transform: rotate(45deg) translate(0.25em, -0.25em);
}
.social-section__col--mail-magazine > * {
  position: relative;
}

.tag-cloud {
  position: relative;
  display: block;
}
.tag-cloud__item {
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
  margin-right: 0.125em;
  margin-bottom: 0.5em;
  padding: 0.25em 1em;
  transition: 0.4s ease;
  color: #333;
  border: 1px solid #333;
}
@media (min-width: 62em) {
  .tag-cloud__item {
    font-size: 0.875rem;
  }
}
.tag-cloud__item.is-current, .tag-cloud__item:hover {
  text-decoration: none;
  color: #fff;
  background-color: #333;
}
.tag-cloud__item:active {
  color: #333;
}

.timeline {
  position: relative;
  padding-bottom: 50px;
}
.timeline::before, .timeline::after {
  position: absolute;
  right: 0;
  left: 0;
  width: 4px;
  margin-right: auto;
  margin-left: auto;
  content: "";
}
@media (min-width: 62em) {
  .timeline::before, .timeline::after {
    right: auto;
    left: 58px;
  }
}
.timeline::before {
  z-index: 0;
  display: block;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #00b458;
}
.timeline::after {
  z-index: 1;
  bottom: 0;
  height: 24px;
  background-image: linear-gradient(to bottom, #fff, #fff 4px, transparent 4px, transparent 4px);
  background-repeat: repeat-y;
  background-position: left top;
  background-size: 4px 8px;
}
.timeline__group {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  justify-content: center;
}
@media (min-width: 62em) {
  .timeline__group {
    justify-content: flex-start;
  }
}
.timeline__year {
  flex: 0 0 120px;
  width: 100%;
  max-width: 120px;
  width: 100%;
}
.timeline__year__label {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.375rem;
  font-weight: bold;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  letter-spacing: 0.05em;
  color: #00b458;
  border: 4px solid #00b458;
  border-radius: 50%;
  background: #fff;
}
@media (min-width: 62em) {
  .timeline__year__label {
    position: sticky;
    top: 90px;
  }
}
.timeline__year__label span {
  display: inline-block;
}
@supports (-webkit-text-fill-color: transparent) {
  .timeline__year__label span {
    background: linear-gradient(to right, #00b458 0%, rgb(2, 212, 104) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.timeline__year__label small {
  font-size: 0.875rem;
  font-weight: bold;
}
.timeline__container {
  width: 100%;
}
@media (min-width: 62em) {
  .timeline__container {
    width: calc(100% - 120px);
    padding-top: 60px;
    padding-left: calc(4rem + 3%);
  }
}

/** Routes */
/**
 * classes 詳細ページ 固有クラス
 */
.classes-detail {
  font-size: 0.9375rem;
}
.classes-detail__date {
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  position: relative;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
}
.classes-detail__date__time {
  margin-right: 0.25rem;
  margin-bottom: 0;
}
.classes-detail__date__dow {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 22.4px;
  position: relative;
  top: -2.8px;
  display: inline-block;
  width: 22.4px;
  height: 22.4px;
  margin-left: 0.5em;
  text-align: center;
  border-radius: 50%;
  background-color: #b7fd84;
  position: relative;
  margin-top: 5px;
  margin-right: 0.5rem;
}
.classes-detail__thumbnail {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 75%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  margin-top: 1rem;
}
.classes-detail__thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .classes-detail__thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
@media (min-width: 62em) {
  .classes-detail__thumbnail {
    display: none;
  }
}
.classes-detail__attention {
  font-size: 0.75rem;
  margin-top: 2em;
  padding: 1rem;
  border: 3px solid #d8d8d8;
}
.classes-detail__attention h4 {
  font-size: 0.875rem;
}
.classes-detail__info {
  padding-left: 0;
  list-style: none;
}
.classes-detail__info > li {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.25em;
  padding-left: 1.75rem;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 1.375rem;
}
.classes-detail__info > li.is-place {
  background-image: svg-load("../images/_inline/place.svg");
}
.classes-detail__info > li.is-teacher {
  background-image: svg-load("../images/_inline/teacher.svg");
}
.classes-detail__well {
  font-size: 0.875rem;
  margin-bottom: 2rem;
}
.classes-detail__require-top {
  align-items: center;
}
@media (min-width: 62em) {
  .classes-detail__require-top {
    display: flex;
    flex-wrap: wrap;
  }
}
.classes-detail__require-top dt,
.classes-detail__require-top dd {
  margin-bottom: 0.5em;
  padding-right: 1em;
}
.classes-detail__require-top dt {
  line-height: 1.5;
  padding-right: 0.5em;
  padding-left: 1.75rem;
  vertical-align: middle;
  color: #00b458;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1.375rem;
}
.classes-detail__require-top dt.is-price {
  background-image: svg-load("../images/_inline/price.svg");
}
.classes-detail__require-top dt.is-people {
  background-image: svg-load("../images/_inline/people.svg");
}
.classes-detail__require-top dt.is-checked {
  background-image: svg-load("../images/_inline/checked.svg");
}
.classes-detail__require-bottom {
  font-size: 0.875rem;
  padding-top: 1em;
}
@media (min-width: 62em) {
  .classes-detail__require-bottom {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 62em) {
  .classes-detail__require-bottom dt {
    flex: 0 0 7rem;
    width: 100%;
    max-width: 7rem;
  }
}
@media (min-width: 62em) {
  .classes-detail__require-bottom dd {
    flex: 0 0 calc(100% - 7rem);
    width: 100%;
    max-width: calc(100% - 7rem);
  }
}

.contents-detail-side-card {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 62em) {
  .contents-detail-side-card {
    position: relative;
    top: 118px;
    right: auto;
    bottom: auto;
    left: auto;
    box-shadow: 0 3px 6px rgba(51, 51, 51, 0.08), 0 6px 32px rgba(51, 51, 51, 0.04);
  }
}
.contents-detail-side-card__body, .contents-detail-side-card__button {
  width: 100%;
}
@media (max-width: 61.99em) {
  .contents-detail-side-card__body, .contents-detail-side-card__button {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}
.contents-detail-side-card__body {
  padding: 0.3125rem;
}
@media (min-width: 62em) {
  .contents-detail-side-card__body {
    text-align: center;
  }
}
.contents-detail-side-card__button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contents-detail-side-card__thumbnail {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 75%;
  transform: translate3d(0, 0, 0);
  background-color: #f1f1f1;
  display: none;
}
.contents-detail-side-card__thumbnail > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
}
@supports (object-fit: cover) {
  .contents-detail-side-card__thumbnail > img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
}
@media (min-width: 62em) {
  .contents-detail-side-card__thumbnail {
    display: block;
  }
}
.contents-detail-side-card__label {
  font-size: 0.875rem;
  line-height: 1.4;
  margin-bottom: 0.25em;
}
@media (min-width: 62em) {
  .contents-detail-side-card__label {
    font-size: 0.875rem;
    margin-bottom: 0.5em;
  }
}
.contents-detail-side-card__label > span {
  display: inline-block;
  border-bottom: 3px solid #00b458;
}
@media (min-width: 62em) {
  .contents-detail-side-card__label > span {
    padding-bottom: 0.25em;
  }
}
.contents-detail-side-card__period {
  font-size: 0.75rem;
  line-height: 1.2;
  display: block;
}
@media (min-width: 62em) {
  .contents-detail-side-card__period {
    margin-bottom: 0.5em;
  }
}
.contents-detail-side-card__period__arrow::after {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border: 2.5px solid transparent;
  border-left-color: #333;
  margin-right: -1.25px;
  margin-right: 0.5em;
  margin-left: 0.5em;
  content: "";
}

.contents-club-side-card {
  position: relative;
  right: auto;
  bottom: auto;
  left: auto;
  padding-bottom: 2rem;
}
.contents-club-side-card__body h4 + a {
  display: block;
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
}
.contents-club-side-card__thumbnail {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.co-support-intro__heading {
  margin-bottom: 1.5em;
}
.co-support-intro__section {
  position: relative;
}
.co-support-intro__section.is-bottom-img {
  padding-bottom: 25rem;
}
@media (min-width: 48em) {
  .co-support-intro__section.is-bottom-img {
    padding-bottom: 0;
  }
}
.co-support-intro__section p {
  line-height: 2.2;
  width: 100%;
  margin-bottom: 1.5em;
}
.co-support-intro__section__pict {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  max-width: 15.75rem;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 48em) {
  .co-support-intro__section__pict {
    position: static;
    bottom: auto;
    float: right;
  }
}

@media (min-width: 62em) {
  .price-cardRow.is-supporterPrice {
    padding-top: 3.125rem;
  }
}
.price-cardRow.is-supporterPrice > .price-card {
  position: relative;
}
@media (min-width: 62em) {
  .price-cardRow.is-supporterPrice > .price-card:not(:first-child):not(:last-child) {
    border-left: 0;
  }
  .price-cardRow.is-supporterPrice > .price-card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
  .price-cardRow.is-supporterPrice > .price-card:nth-child(3) {
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .price-cardRow.is-supporterPrice > .price-card:nth-child(2) > .price-card__heading, .price-cardRow.is-supporterPrice > .price-card:nth-child(3) > .price-card__heading {
    position: relative;
  }
  .price-cardRow.is-supporterPrice > .price-card:nth-child(2) > .price-card__heading::before, .price-cardRow.is-supporterPrice > .price-card:nth-child(3) > .price-card__heading::before {
    position: absolute;
    top: -3px;
    bottom: 0;
    left: -3px;
    height: calc(100% + 3px);
    content: "";
    border-left: 3px #fff solid;
  }
}
.price-cardRow.is-supporterPrice > .price-card:first-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.price-cardRow.is-supporterPrice > .price-card:nth-child(2) {
  border-radius: 0;
}
.price-cardRow.is-supporterPrice > .price-card:nth-child(3) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.co-support-popup-01,
.co-support-popup-02 {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 62em) {
  .co-support-popup-01,
  .co-support-popup-02 {
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);
    left: 0;
  }
}

.organization-message {
  position: relative;
  overflow: visible;
}
@media (min-width: 62em) {
  .organization-message {
    padding-top: 3.75rem;
  }
}
.organization-message__thumbnail {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 12.1875rem;
  margin: 0 auto;
  padding-bottom: 1rem;
}
@media (min-width: 62em) {
  .organization-message__thumbnail {
    position: absolute;
    top: 0;
    right: auto;
  }
}
.organization-message__thumbnail > img {
  box-shadow: 20px 10px 80px rgba(51, 51, 51, 0.1), 0 5px 15px rgba(51, 51, 51, 0.05);
}
.organization-message__thumbnail__caption {
  display: block;
  padding-top: 0.5em;
  text-align: center;
}
@media (min-width: 62em) {
  .organization-message__thumbnail__caption {
    text-align: left;
  }
}
.organization-message__thumbnail__caption > * {
  font-weight: bold;
  display: block;
}
.organization-message__content {
  margin-top: -150px;
  padding: 200px 1rem 1rem;
  box-shadow: 0 0 3px rgba(51, 51, 51, 0.05), 0 4px 8px rgba(51, 51, 51, 0.1), 0 8px 42px rgba(51, 51, 51, 0.08);
}
@media (min-width: 62em) {
  .organization-message__content {
    width: calc(100% - 10rem);
    margin-top: 0;
    margin-left: 10rem;
    padding: 5% 12%;
  }
}
.organization-message__content p {
  line-height: 2;
}

.organization-map {
  max-width: 41.875rem;
}

.organization-school-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
}
.organization-school-list__item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1rem;
}
@media (min-width: 62em) {
  .organization-school-list__item {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}
.organization-school-list__item a {
  display: inline-block;
  overflow: hidden;
  padding: 0.25em 0;
  white-space: nowrap;
  text-decoration: underline;
  text-overflow: ellipsis;
}
.organization-school-list__icon {
  position: relative;
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .organization-school-list__icon {
    flex: 0 0 7rem;
    width: 100%;
    max-width: 7rem;
  }
}
.organization-school-list__icon img {
  border: 1px solid #e4e3e3;
}
.organization-school-list__content {
  width: 100%;
}
@media (min-width: 48em) {
  .organization-school-list__content {
    flex: 0 0 calc(100% - 7rem);
    width: 100%;
    max-width: calc(100% - 7rem);
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 48em) {
  .banner-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .banner-row > p {
    margin-right: 1em;
    margin-left: 1em;
  }
}

.banner {
  max-width: 12.5rem;
  height: auto;
}
.banner.is-border {
  border: 1px solid #e4e3e3;
}

.banner-jwave {
  max-width: 8.125rem;
  height: auto;
}

/** slider */
.hero {
  position: relative;
  padding-bottom: 3.75rem;
}
.hero__slide {
  position: relative;
  background-color: #f1f1f1;
  aspect-ratio: 1/1;
  min-height: 22.5rem;
}
@media (min-width: 36em) {
  .hero__slide {
    aspect-ratio: 1536/960;
  }
}
@media (min-width: 75em) {
  .hero__slide {
    min-height: initial;
    height: 545px;
    aspect-ratio: 2000/545;
  }
}
.hero__slide picture {
  position: relative;
  z-index: 10;
}
.hero__slide img {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s ease;
  opacity: 0;
}
@supports (object-fit: cover) {
  .hero__slide img {
    position: static;
    object-fit: cover;
    height: 100%;
    object-position: center;
  }
  @media (min-width: 36em) {
    .hero__slide img {
      object-fit: contain;
    }
  }
  @media (min-width: 75em) {
    .hero__slide img {
      object-fit: cover;
    }
  }
  @media (min-width:2000px) {
    .hero__slide img {
      object-fit: contain;
    }
  }
}
.hero__slide img.js-is-image-loaded {
  opacity: 1;
}
.hero__slide .progress-bar {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.hero__bullets {
  bottom: -10px;
}
.hero__bullet {
  width: 20px;
  height: 5px;
  border-radius: 0;
  background-color: #e4e3e3;
  box-shadow: none;
}
.hero__bullet:hover, .hero__bullet:focus {
  border: none;
  background-color: #e4e3e3;
}
.hero__bullet:hover {
  opacity: 0.5;
}
.hero__bullet[class*="--active"] {
  background-color: #00b458;
}
.hero__typo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10% 5% 5%;
  pointer-events: none;
}
@media (max-width: 61.99em) {
  .hero__typo {
    font-size: 1.5rem;
  }
}
@media (min-width: 62em) {
  .hero__typo {
    right: 0;
    max-width: 83.5%;
    margin: 0 auto;
    padding-top: 4.5rem;
    padding-bottom: 1.875rem;
  }
}
.hero__typo__line {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-top: -0.1em;
  padding: 0.125em 0.5em;
  letter-spacing: 0.2em;
}
.hero__typo__line::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: scaleX(0);
  transform-origin: center right;
  background-color: #fff;
}
.js-is-label-active .hero__typo__line::before {
  transform: scaleX(1);
  transform-origin: center left;
}
.hero__typo__line > span {
  position: relative;
  display: inline-block;
  transform: translateX(-10px);
  opacity: 0;
}
.js-glide-active .hero__typo__line > span {
  transition: 1s ease;
}
.js-glide-active.js-is-label-active .hero__typo__line > span {
  transition-delay: 0.25s;
  transform: translateX(0);
  transform-origin: center left;
  opacity: 1;
}
.hero__typo__line:nth-of-type(2)::before {
  transition-delay: 0.2s;
}
.js-is-label-active .hero__typo__line:nth-of-type(2) > span {
  transition-delay: 0.6s;
}
.hero__typo__line:nth-of-type(3)::before {
  transition-delay: 0.3s;
}
.js-is-label-active .hero__typo__line:nth-of-type(3) > span {
  transition-delay: 0.7s;
}
.hero__typo__line:nth-of-type(4)::before {
  transition-delay: 0.4s;
}
.js-is-label-active .hero__typo__line:nth-of-type(4) > span {
  transition-delay: 0.8s;
}
.hero__typo__line:nth-of-type(5)::before {
  transition-delay: 0.5s;
}
.js-is-label-active .hero__typo__line:nth-of-type(5) > span {
  transition-delay: 0.9s;
}
.hero__typo__line:nth-of-type(6)::before {
  transition-delay: 0.6s;
}
.js-is-label-active .hero__typo__line:nth-of-type(6) > span {
  transition-delay: 1s;
}
.hero__typo__line:nth-of-type(7)::before {
  transition-delay: 0.7s;
}
.js-is-label-active .hero__typo__line:nth-of-type(7) > span {
  transition-delay: 1.1s;
}
.hero__typo__line:nth-of-type(8)::before {
  transition-delay: 0.8s;
}
.js-is-label-active .hero__typo__line:nth-of-type(8) > span {
  transition-delay: 1.2s;
}
.hero__typo__line:nth-of-type(9)::before {
  transition-delay: 0.9s;
}
.js-is-label-active .hero__typo__line:nth-of-type(9) > span {
  transition-delay: 1.3s;
}