/**
 * Header
 */

.header {
  position: fixed;
  z-index: $z-header;
  right: 0;
  left: 0;
  overflow: visible;
  height: 0;
  transition: 0.4s;

  @include mq($from: lg) {
    display: flex;
    width: 100%;
    height: auto;
    box-shadow: $box-shadow-header;
  }

  .js-is-menu-open & {
    height: 100%;
    transition-duration: 0.1s;
  }

  &::before {
    content: "";
    transition: 0.6s $easeInOutCirc;
    transform: scaleY(0);
    transform-origin: center bottom;
    opacity: 0;
    background-color: $color-base;

    @include full-fit;

    @include mq($from: lg) {
      transform: scaleY(1);
      opacity: 1;
    }

    .js-is-menu-open & {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  &__body {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $spacer / 2 $spacer;
    pointer-events: initial;
    background-color: $color-base;
    box-shadow: $box-shadow-header;

    @include mq($from: lg) {
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: none;
    }

    &__right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &__sp-user {
    font-size: rem(10px);
    margin-right: 1.5em;

    @extend %user-login;

    & > span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 10em;

      @include mq($from: md) {
        max-width: 20em;
      }
    }

    .is-user-login & {
      display: block;

      @include mq($from: lg) {
        display: none !important;
      }
    }
  }
}

.brand {
  position: relative;
  display: flex;

  &__icon,
  &__title {
    position: relative;
    display: block;
  }

  &__icon {
    display: none;
    width: 54px;

    @include svgRatio(percentage(46/54));

    @include mq($from: lg) {
      display: block;
    }
  }

  &__title {
    width: rem(132px);

    @include svgRatio(percentage(20.28/181.93));

    @include mq($from: lg) {
      width: rem(225px);
      margin-left: 1rem;
    }
  }
}

%user-login {
  display: none;

  & > span {
    color: $color-text;
    font-weight: $font-weight-bold;
    font-family: $font-hiragino-pro-w6;

    &::before {
      position: relative;
      content: "";
      margin-right: 0.5em;
      background: no-repeat center;
      background-size: contain;
      background-image: svg-load(
        "../images/_inline/user.svg",
        fill=lighten($color-border, 10%)
      );
      display: inline-block;
      width: 1em;
      height: 1em;
      top: 0.05em;
    }
  }
}
