@mixin gnav-is-visible {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.gnav {
  position: absolute;
  top: 0;
  visibility: hidden;
  overflow: auto;
  align-items: start;
  width: 100%;
  height: 100%;
  padding: $spacer;
  padding-top: 60px;
  transition: 0.6s $easeInOutCirc;
  transform: translateY(5%);
  opacity: 0;
  background-color: $color-base;
  -webkit-overflow-scrolling: touch;

  @include mq($from: lg) {
    @include gnav-is-visible;

    position: relative;
    z-index: 100;
    max-width: calc(1200px - 250px);
    margin-right: 0;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
  }

  .js-is-menu-open & {
    @include gnav-is-visible;
  }

  a {
    font-family: $font-heading;
    font-size: rem(18px);
    font-weight: $font-weight-bold;
    padding: 1em;
    text-align: center;
    color: $color-text;

    @include mq($from: lg) {
      font-size: rem(14px);
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__body {
    display: block;
    width: 100%;

    @include mq($from: lg) {
      display: flex;
      // align-items: flex-end;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &__main,
    &__sub,
    &__my-page {
      display: block;
      width: 100%;
      padding-left: 0;
      list-style: none;
      text-align: center;

      @include mq($from: lg) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: initial;
      }
    }

    &__sub,
    &__my-page {
      margin-bottom: 0;

      @include mq($from: lg) {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }

      & button,
      & a {
        &::before {
          line-height: 1;
          position: relative;
          top: 0.05em;
          display: inline-block;
          width: 1em;
          height: 1em;
          margin-right: 0.25em;
          content: "";
          color: $color-main;
          background: no-repeat center;
          background-size: contain;
        }
      }

      & > li a {
        @include mq($from: lg) {
          font-size: rem(12px);
        }

        &:hover {
          opacity: $hover-opacity;
        }
      }
    }

    &__main {
      width: 100%;
      margin-bottom: 0;

      @include mq($from: lg) {
        order: 3;
        width: auto;

        .is-user-login & {
          // width: 100%;
          width: calc(100% - #{rem(140px)});
        }
      }

      & > a {
        position: relative;
        display: block;

        @include mq($from: lg) {
          padding-top: 0.5em;
          padding-bottom: rem(15px);
        }

        &::after {
          @include grad-bar($position: center, $width: 2em);

          transition: transform 0.4s ease;
          transform: scaleX(0);

          @include mq($from: lg) {
            text-align: center;
          }
        }

        &:hover {
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }

    &__sub {
      position: relative;
      width: 100%;
      margin-bottom: 0;
      padding-top: $spacer;
      padding-bottom: $spacer;

      @include mq($from: lg) {
        order: 2;
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        .is-user-login & {
          width: auto;
        }
      }

      & > button,
      & > a {
        display: inline-flex;
        align-items: center;
        color: $color-main;

        @include mq($from: lg) {
          font-size: rem(12px);
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }

        &::before {
          @include triangle(right, $color-main, 0.75em);

          margin-right: 0.25em;
        }
      }

      & > button {
        font-weight: $font-weight-bold;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
        appearance: none;
      }
    }

    &__login {
      position: relative;
      padding-top: $spacer;
      padding-bottom: $spacer;
      text-align: center;

      @include mq($from: lg) {
        order: 4;
        padding-top: 3px;
        padding-bottom: 0;
      }

      @include mq($from: xl) {
        padding-right: $spacer / 2;
        padding-left: $spacer;
      }

      &::before {
        position: absolute;
        top: 0;
        display: block;
        width: calc(100% + #{$spacer * 2});
        height: 1px;
        margin-right: -$spacer;
        margin-left: -$spacer;
        content: "";
        background-color: $color-border-light;

        @include mq($from: lg) {
          display: none;
        }
      }

      & .login-link {
        font-size: rem(14px);
        max-width: 200px;
        margin-right: auto;
        margin-left: auto;
        outline: none;

        .js-is-modal & {
          opacity: 0.4;
        }
      }
    }

    &__my-page {
      position: relative;

      @include mq($until: lg) {
        @include set-row;

        width: calc(100% + #{$spacer * 2});
        margin-right: -$spacer;
        margin-left: -$spacer;
      }

      @include mq($from: lg) {
        order: 1;
        width: auto;
      }

      &::before {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 1em;
        margin-top: -0.4em;
        content: "";
        background-color: $color-border-light;
      }

      & li {
        @include mq($until: lg) {
          @include set-col(percentage(1/2));

          border-top: 1px solid $color-border-light;
          border-bottom: 1px solid $color-border-light;

          &:last-child {
            border-left: 1px solid $color-border-light;
          }

          & a {
            display: block;
            padding: 1em;
          }
        }
      }

      & a {
        &::before {
          background-image: svg-load(
            "../images/_inline/arrow-circle.svg",
            fill=$color-main
          );
        }
      }

      &__user {
        @extend %user-login;

        & > span {
          font-size: rem(12px);
          padding: 1em;
        }

        .is-user-login & {
          @include mq($from: lg) {
            display: block;
          }
        }
      }
    }
  }
}
